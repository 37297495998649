/* ==================================================================================================================
 * exportMissingGeolocation
 *
 *    Create a table with all the stores with missing geolocation.
 *    Creates a csv file to be imported in the CMS.
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ================================================================================================================== */

(function () { /* global _, DEBUG, google, isGoogleMapsAvailable, MarkerClusterer, zgGetObjectPropertyValue */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;


	// CLASS DEFINITION
	// ================

	var SetMapMarker = function ( storeLocator ) {
		this.storeLocator = storeLocator;

		this.options      = _.extend( {}, SetMapMarker.DEFAULTS, window.ZG_CONFIG, storeLocator.options || {} );

		this.validMarkers = null;

		// markerClusters
		this.clusters = {};
	};


	SetMapMarker.DEFAULTS = {
		// image to use as the default marker Icon (path)
		markerIcon: null,

		// Do not display markers for invalid (filtered) stores
		hideFilteredMarkers: true,

		// Collapse (cluster) the markers together into groups.
		// A single marker will be created for the group, with a number specifying how many marker are grouped inside
		useMarkerClusterer: true,

		// Image to use for the markerClusterers.
		// You have to set the image url and it's width and height. It will be ignored otherwise.
		// The number will appear in the center of the image, so is better to use a square/round image (do not use
		// images with a "pointer")
		// Make sure you set the correct values for the height and with. The image acts as a repeated background so if
		// you set a smaller size it will crop the image, and a bigger one will make it repeat the image  :(
		markerClustererImg:       null, // image url
		markerClustererImgHeight: null, // image height
		markerClustererImgWidth:  null, // image width

		markerClusterGroupBy: null,
		markerClustererGroup: {},

		// If the zoom level is over this the cluster will explode into individual markers.
		// The map will try to zoom over this level before rendering an infoWindow
		markerClustererMaxZoom: 10
	};


	/**
	 *
	 * @param {string} clusterId
	 */
	SetMapMarker.prototype.clearCluster = function ( clusterId ) {
		if ( this.clusters[clusterId] ) {
			this.clusters[clusterId].clearMarkers();
		}
	};


	/**
	 *
	 * @param {string} clusterId
	 */
	SetMapMarker.prototype.createCluster = function ( clusterId ) {
		var clusterOptions, height, img, style, width;

		if ( this.storeLocator.map && this.isMarkerClustererEnabled() && !this.clusters[clusterId] ) {
			img = (
					this.options.markerClustererGroup &&
					this.options.markerClustererGroup[clusterId] &&
					this.options.markerClustererGroup[clusterId].img
				) || this.options.markerClustererImg;

			height = (
					this.options.markerClustererGroup &&
					this.options.markerClustererGroup[clusterId] &&
					this.options.markerClustererGroup[clusterId].imgHeight
				) || this.options.markerClustererImgHeight;

			width = (
					this.options.markerClustererGroup &&
					this.options.markerClustererGroup[clusterId] &&
					this.options.markerClustererGroup[clusterId].imgWidth
				) || this.options.markerClustererImgWidth;

			if ( img && height && width ) {
				style = {
					url:    img,    // Image to use as background for the cluster marker
					height: height, // image height
					width:  width   // image width
				};
			}

			clusterOptions = {
				gridSize:           50,
				maxZoom:            this.options.markerClustererMaxZoom,
				zoomOnClick:        true,
				averageCenter:      true,
				minimumClusterSize: 3
			};

			if ( style ) {
				clusterOptions.styles = [style];
			}

			this.clusters[clusterId] = new MarkerClusterer( this.storeLocator.map, [], clusterOptions );

			console.log( 'CREATED CLUSTER: ', clusterId );
		}
	};


	/**
	 *
	 * @param {!string} storeId
	 */
	SetMapMarker.prototype.createNew = function ( storeId ) {
		var markerData, that;

		if ( this.storeLocator.map && isGoogleMapsAvailable() ) {

			markerData = this.generateData( storeId );
			that       = this;

			if ( markerData ) {
				// create marker
				this.storeLocator.mapMarkers[storeId] = new google.maps.Marker( markerData );

				// Open infoWindow on click
				google.maps.event.addListener( this.storeLocator.mapMarkers[storeId], 'click', function () {
					that.storeLocator.openInfoWindow( this.storeId );
				} );

				// add the marker to the map
				if ( this.isValid( storeId ) ) {
					this.show( storeId );
				}
			}

		} else if ( DEBUG ) {

			console.error( "StoreLocator.setMapMarker.createNew - FAILED" );

		}
	};


	/**
	 *
	 * @param {string} storeId
	 *
	 * @returns {
	 *		{
	 *			position: google.maps.LatLng,
	 *			zoomOnClick: boolean,
	 *			flat: boolean,
	 *			title: string,
	 *			storeId: (string|number),
	 *			icon: ?string=
	 *		}
	 *		|null
	 *	}
	 */
	SetMapMarker.prototype.generateData = function ( storeId ) {
		var icon, markerData, position, store;

		markerData = null;

		store = (this.storeLocator.options.stores || {})[storeId];

		if ( store && !isNaN( store.fields.Latitude ) ) {
			// get marker position
			position = new google.maps.LatLng( +store.fields.Latitude, +store.fields.Longitude );

			icon = store.fields.Marker || this.options.markerIcon || null;

			// create marker options
			markerData = {
				position:    position,
				zoomOnClick: true,
				flat:        true,
				title:       ( "" + store.fields.City + " - " + store.fields.Name ),
				storeId:     storeId
			};

			if ( icon ) {
				markerData.icon = icon;
			}
		}

		return markerData;
	};


	/**
	 *
	 * @param {!string} storeId
	 *
	 * @returns {string}
	 */
	SetMapMarker.prototype.getClusterIdFromMarkerId = function ( storeId ) {
		var clusterId = 'default', temp;

		if ( this.options.markerClusterGroupBy ) {
			temp = zgGetObjectPropertyValue( this.storeLocator.options.stores[storeId], this.options.markerClusterGroupBy );

			if ( !_.isUndefined(temp) && !_.isNull(temp) ) {
				clusterId = temp;
			}
		}

		return clusterId;
	};


	/**
	 *
	 * @param {string} storeId
	 */
	SetMapMarker.prototype.hide = function ( storeId ) {
		var clusterId;

		if ( this.storeLocator.mapMarkers[storeId] ) {

			if ( this.isMarkerClustererEnabled() ) {
				clusterId = this.getClusterIdFromMarkerId( storeId );

				if ( this.clusters.hasOwnProperty( clusterId ) ) {
					this.clusters[clusterId].removeMarker( this.storeLocator.mapMarkers[storeId] );
				}
			}

			this.storeLocator.mapMarkers[storeId].setMap( null );
		} else if ( DEBUG ) {
			console.log( 'StoreLocator.setMapMarker.hide - FAILED - unknown marker ' + storeId );
		}
	};

	SetMapMarker.prototype.isMarkerClustererEnabled = function () {
		return this.options.useMarkerClusterer && window.MarkerClusterer;
	};


	/**
	 *
	 * @param {string} storeId
	 *
	 * @returns {boolean}
	 */
	SetMapMarker.prototype.isValid = function ( storeId ) {
		var valid = true;

		if (
			this.options.hideFilteredMarkers &&
			_.isArray( this.validMarkers ) &&
			!_.contains( this.validMarkers, storeId )
		) {
			valid = false;
		}

		return valid;
	};


	/**
	 *
	 * @param {string=} clusterId
	 */
	SetMapMarker.prototype.redraw = function ( clusterId ) {
		var id;

		if ( this.isMarkerClustererEnabled() ) {
			if ( clusterId && this.clusters[clusterId] ) {
				this.clusters[clusterId].redraw();
			} else {
				for ( id in this.clusters ) {
					if ( this.clusters.hasOwnProperty( id ) ) {
						this.clusters[id].redraw();
					}
				}
			}
		}
	};


	/**
	 *
	 * @param {Array=} validMarkers
	 */
	SetMapMarker.prototype.setValidMarkers = function ( validMarkers ) {
		this.validMarkers = validMarkers;

		this.update();
	};


	/**
	 * Add the marker to the map either directly or using markerClusterer if enabled
	 *
	 * @param {!string} storeId
	 */
	SetMapMarker.prototype.show = function ( storeId ) {
		var clusterId;

		if ( this.storeLocator.mapMarkers[storeId] ) {
			if ( this.isMarkerClustererEnabled() ) {

				// use marker clusters
				clusterId = this.getClusterIdFromMarkerId( storeId );

				// create the markerClusterer if it does not exist
				this.createCluster( clusterId );

				// add marker to the clusterer
				this.clusters[clusterId].addMarker( this.storeLocator.mapMarkers[storeId] );

			} else {

				// add marker directly to the map
				this.storeLocator.mapMarkers[storeId].setMap( this.storeLocator.map );

			}
		} else if ( DEBUG ) {
			console.log( 'StoreLocator.setMapMarker.show - FAILED - unknown marker ' + storeId );
		}
	};



	SetMapMarker.prototype.update = function () {
		var storeId;

		for ( storeId in this.storeLocator.mapMarkers ) {
			if ( this.storeLocator.mapMarkers.hasOwnProperty( storeId ) ) {
				if ( this.isValid( storeId ) ) {
					this.show( storeId );
				} else {
					this.hide( storeId );
				}
			}
		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorSetMapMarker = SetMapMarker;

}.call( this ));
