/* =====================================================================================================================
 * processProductImages
 * =====================================================================================================================
 *
 *   Processes the images object for each product so we have something usable for the gallery and zoom scripts.
 *
 *   This code should ONLY be executed in the product page (and maybe for the quick-buy).
 *
 *   I know this is crazy code, but until the backend code is refactored (and it won't be refactored) it's the only way
 *   to get an usable object  :(
 *
 *   Just ignore this code and think happy thoughts  ;)
 *
 * =====================================================================================================================
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 * ================================================================================================================== */


(function () {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	var keys = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth"];


	/**
	 *
	 * @param {Object} images
	 * @returns {Array}
	 */
	function processProductImages ( images ) {
		var found,
			i,
			processed = [];

		for ( var image in images ) {
			if ( images.hasOwnProperty( image ) ) {
				found = false;

				for ( i = 0; i < keys.length && !found; i++ ) {
					if ( image.indexOf( keys[i] ) === 0 ) {
						__generate(
							processed,
							(i + 1),
							image.replace( keys[i], "" ),
							images[image]
						);

						found = true;
					}
				}

				if ( !found ) {
					__generate(
						processed,
						0,
						image,
						images[image]
					);
				}
			}
		}

		return processed;
	}


	/**
	 *
	 * @param {Array}  array
	 * @param {number} index
	 * @param {string} key
	 * @param {string} val
	 * @private
	 */
	function __generate ( array, index, key, val ) {
		if ( !array[index] ) {
			array[index] = {};
		}

		array[index][key] = val;
	}


	// -----------------------------------------------------------------------------------------------------------------


	root.zgProcessProductImages = processProductImages;

}.call( this ));
