/* ============================================================
 * parseString
 *
 *   Clean-up whitespace and trim
 *   Optionally transform the string to lower case
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function () {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	// http://www.w3.org/TR/css3-selectors/#whitespace
	var whitespace = '[\\x20\\t\\r\\n\\f]';

	var rWhitespace = new RegExp( whitespace + '+', 'g' ),
		rLeftTrim = new RegExp( '^' + whitespace + '+' ),
		rRightTrim = new RegExp( whitespace + '+$' );

	/**
	 *
	 * @param {string}   string
	 * @param {boolean=} toLowerCase
	 * @param {string=}  whitespaceCharacter
	 *
	 * @returns {*|string}
	 */
	function parseString ( string, toLowerCase, whitespaceCharacter ) {
		var parsed = string || '';

		// make sure we are working with a string
		parsed = ( '' + parsed );

		// Trim
		parsed = parsed.replace( rLeftTrim, '' );
		parsed = parsed.replace( rRightTrim, '' );

		// Whitespace characters to single space (or other character)
		parsed = parsed.replace( rWhitespace, ( whitespaceCharacter || ' ' ) );

		// change it to lowercase
		if ( toLowerCase ) {
			parsed = parsed.toLowerCase();
		}

		return parsed;
	}

	root.zgParseString = parseString;

}.call( this ));
