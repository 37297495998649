(function ( $ ) {

	"use strict";

	var toggle         = '[data-zg-role="zg_select_ship"]';
	var container      = '[data-zg-role="shipping-cost-container"]';
	var singleListShip = '[data-zg-role="zg_radio_ship"]';

	var ZG_SelectShip = function ( element, config ) {
		var self  = this;

		this.init = function ( element, config ) {
			self.$element = $( element );

			self.$container = $( container );

			self.$eventBus         = $( document );
			self.config            = $.extend( {}, config, self.$element.data( 'config' ) || {} );
			self.$zgsingleListShip = self.$element.find( singleListShip );

			self.$zgsingleListShip.on( 'click', function ( e ) {
				e.preventDefault();
				var $listSelection = $( this );
				var $selection     = $( this ).find( 'input' );
				self.zgSelectShipping( $listSelection, $selection );
			} );

			if ( !self.config.selected ) {
				self.$zgsingleListShip.first().trigger( 'click' );
			}
		};

		this.zgSelectShipping = function ( $listSelection, $selection ) {
			var shippingId = $selection.data( 'zg-courier-id' );
			var url        = window.makeUrl( {
				module:  'eshop',
				manager: 'confirm',
				action:  'applyShippingMethodToCart'
			} );
			$.ajax( {
				type:       'post',
				url:        url,
				dataType:   'json',
				data:       { 'shipping_select': shippingId },
				success:    function ( response ) {

					if ( response.shipping_cost === "0.00" ) {
						self.$container.addClass( 'hidden' );
					} else {
						self.$container.removeClass( 'hidden' );
					}

					$selection.prop( 'checked', true );
					self.$zgsingleListShip.removeClass( 'active' );
					$listSelection.addClass( 'active' );
					$( document ).find( '[data-zg-role="ship_cost"]' ).html( response.shipping_cost );
					$( document ).find( '[data-zg-role="discountAmount"]' ).html( response.discountAmount );
					$( document ).find( '[data-zg-role="total_sum"]' ).html( response.total_sum );
				},
				beforeSend: function () {
				},
				complete:   function () {
				},
				error:      function () {
					$( document ).find( '[data-zg-role="payments"]' ).addClass( 'hidden' );
					$( document ).trigger( 'zg-notification', [{
						eventType: '',
						message:   window.JS_TRANSLATIONS.shipping_not_selectable || 'Shipping not selectable'
					}] );
				}
			} );
		};

		this.init( element, config );
	};

	$.fn.zg_selectShip = function ( config ) {
		return this.each( function () {
			var $this = $( this );
			var data  = $this.data( 'zgSelectShip' );

			if ( !data ) {
				$this.data( 'zgSelectShip', new ZG_SelectShip( this, config ) );
			}
		} );
	};

	$( function () {
		$( toggle ).zg_selectShip( window.ZG_CONFIG );
	} );

})( jQuery );
