/* ============================================================
 * zg-login.js
 *
 * @author: Joaquin Casas <jcasas@zerogrey.com>
 * ============================================================
 *
 * We check if is the first time the user logs in the eshop.
 *
 * If so we show the privacy form and ask about newsletter subscription
 *
 * ============================================================ */

(function ( $ ) {
	"use strict";

	var toggle               = '[data-zg-role="zg-login"]',
		username_toggle      = 'input[name=frmUsername]',
		password_toggle      = 'input[name=frmPassword]',

		privacy_modal_toggle = '[data-zg-role="zg-privacy-modal"]',
		privacy_form_toggle  = '[data-zg-role="zg-privacy-form"]';

	/**
	 * Main function
	 *
	 * @param {Element}  element
	 */
	var ZG_Login = function ( element ) {
		var self = this;

		/**
		 * Initialise the module
		 *
		 * @param {Element} element
		 */
		this.init = function ( element ) {
			var $element   = $( element );
			self.$element  = $element;
			self.$eventBus = $( document );

			self.$login_username = $( username_toggle, $element );
			self.$login_password = $( password_toggle, $element );

			self.$modal      = $( privacy_modal_toggle ).first();
			self.$modal_form = $( privacy_form_toggle, self.$modal );

			self.$modal_form.on( 'submit', function () {
				// by default don't do anything on submit
				return false;
			} );

			self.$element.on( 'submit', function () {
				self.checkLogin();
				return false;
			} );
		};

		/**
		 * check if is the first time the user logs into the eshop.
		 */
		this.checkLogin = function () {
			var url, request;

			if ( !self.$element.data( 'zgValidator' ) || self.$element.data( 'zgValidator' ).validate() ) {
				url     = window.makeUrl( { module: 'eshop', manager: 'eshop', action: 'checkUserPassword' } );
				request = {
					"login_username": self.$login_username.val(),
					"login_password": self.$login_password.val()
				};

				$.ajax( {
					type:     'post',
					url:      url,
					dataType: 'json',
					data:     request,
					success:  function ( response ) {
						if ( response && response.status === "success" ) {
							// is the first time the user logs in the eshop. We show the privacy modal
							self.showPrivacyModal();
						} else {
							// the user has been here before. We continue.
							self.$element.off( "submit" ).submit();
						}
					},
					error:    function ( response ) {
						// something is broken!!!
						self.$eventBus.trigger( 'zg-error', [{
							eventType: 'zg-login - checkLogin',
							message:   (response.message || window.JS_TRANSLATIONS.genericErrorMsg)
						}] );
					}
				} );
			}
		};

		/**
		 * Show the modal and add its handlers
		 */
		this.showPrivacyModal = function () {
			// when the modal form is sent the current login form will be updated and sent
			self.$modal_form.off( 'submit' ).on( 'submit', function ( e ) {
				e.preventDefault();

				self.agreePrivacy();
			} );

			// we show the modal
			self.$modal.modal( 'show' );

			// if the modal is hidden we unlink the current form from the modal
			self.$modal.on( 'hidden.bs.modal', function () {
				self.$modal_form.off( 'submit' ).on( "submit", function () {
					return false;
				} );
			} );
		};

		/**
		 * The modal form is validated and the original form is submited including the new fields
		 */
		this.agreePrivacy = function () {
			if ( !self.$modal_form.data( 'zgValidator' ) || self.$modal_form.data( 'zgValidator' ).validate() ) {
				// we add the modal form elements to the original form
				self.extendOriginalForm();

				// we send the original login form
				self.$element.off( "submit" ).submit();
			}
		};

		/**
		 * Checks if the fields of the modal are already in the original form.
		 * If so updates their value otherwise it adds them to it.
		 */
		this.extendOriginalForm = function () {
			$( "input", self.$modal_form ).each( function () {
				var $this     = $( this ),
					$original = $( '[name="' + $this.attr( "name" ) + '"]', self.$element );

				if ( $original.length ) {
					// An element with the same name is there. Replace it
					$original.replaceWith( $this.clone() );
				} else {
					// Nothing is there. Add it.
					self.$element.append( $this.clone().hide() );
				}
			} );
		};

		this.init( element );
	};


	$.fn.zg_login = function () {
		return this.each( function () {
			var $this = $( this ),
				data  = $this.data( 'zgLogin' );

			if ( !data ) {
				$this.data( 'zgLogin', new ZG_Login( this ) );
			}
		} );
	};


	$( toggle ).zg_login( window.ZG_CONFIG );

})( jQuery );
