/* ============================================================
 * zg-adyen.js
 *
 * @author: Pietro Vignola <pvignola[at]kooomo.com>
 * @author: David Pocina   <dpocina[at]kooomo.com>
 *
 * ============================================================ */

/* global _, DEBUG, JS_TRANSLATIONS, adyen, handlebarsTemplates */

(function ( $ ) {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	var selector = '[data-zg-role="zg-adyen"]';

	var DEFAULTS = {
		'adyenTemplate':    'adyen-card-cart',
		'adyenShowPayment': false,

		'adyenActionPay':    '[data-zg-action="pay-adyen"]',
		'adyenActionRemove': '[data-zg-action="remove-adyen"]',
		'adyenActionReload': '[data-zg-action="reload-adyen"]',

		'adyenCvcInput': 'input[name="cvc"]',

		'adyenRecurringForm':       '[data-zg-role="adyen-recurring-payment-form"]',
		'adyenRecurringFormCvc':    '[data-zg-role="adyen-recurring-cvc"]',
		'adyenRecurringFormCardId': '[data-zg-role="adyen-recurring-card-id"]'
	};

	var LISTCARDS = {
		diners:         'Diners Club',
		discover:       'Discover',
		amex:           'American Express',
		mc:             'MasterCard',
		visa:           'Visa',
		directEbanking: 'Sofortüberweisung',
		bcmc:           'Bancontact/Mister Cash',
		maestro:        'Maestro',
		ideal:          'Ideal',
		dotpay:         'Dotpay',
		ebanking_FI:    'Finnish E-Banking',
		giropay:        'GiroPay',
		trustly:        'Trustly'
	};


	// ADYEN CLASS DEFINITION
	// ========================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!object}     options
	 *
	 * @constructor
	 */
	var Adyen = function ( element, options ) {
		this.$element = $( element );
		this.options  = options;

		this.$recurringForm       = $( this.options.adyenRecurringForm );
		this.$recurringFormCvc    = $( this.options.adyenRecurringFormCvc );
		this.$recurringFormCardId = $( this.options.adyenRecurringFormCardId );

		this.__setEventHandlers();
	};


	/**
	 * Format the response from adyen so we can use it in handlebars
	 *
	 * @param {Object} data
	 * @returns {{showPayment: boolean, cards: (Array|null)}}
	 */
	Adyen.prototype.formatResponse = function ( data ) {
		var cards;

		if ( data && data.response && data.response.recurringDetailsResult_details_0_recurringDetailReference ) {
			cards = [];

			_.each( data.response, function ( value, key ) {
				var splitKey = key.split( '_' ),
					index, itemKey;

				if ( splitKey[1] === 'details' ) { // make sure is a card
					index   = splitKey[2]; // card index
					itemKey = splitKey.slice( 3 ).join( '_' ); // remove recurringDetailsResult_details_[index]_ and keep the rest as the actual key

					// initialize the card object
					if ( !cards[index] ) {
						cards[index] = {};
					}

					// store the information
					if ( itemKey === 'variant' ) {
						// User friendly name  :)
						cards[index][itemKey] = LISTCARDS[value];
					} else {
						cards[index][itemKey] = value;
					}
				}
			} );
		}

		return {
			showPayment: this.options.adyenShowPayment,
			cards:       cards
		};
	};


	/**
	 *
	 * @param cardId
	 */
	Adyen.prototype.payWithCard = function ( cardId ) {
		var cvcValue;

		if ( cardId ) {
			cvcValue = this.$element.find( this.options.adyenCvcInput + '[data-id="' + cardId + '"]' ).val();

			if ( cvcValue ) {
				// update the form this the cardId and CVC and submit it
				this.$recurringFormCvc.val( cvcValue );
				this.$recurringFormCardId.val( cardId );
				this.$recurringForm.submit();

				$('body' ).addClass('loading');
			} else {
				// the CVC is not present. Show error msg
				$( document ).trigger( 'zg-error', [{
					eventType: 'zg.adyen',
					message:   JS_TRANSLATIONS['adyen.error.requiredCVC']
				}] );
			}
		} else if ( DEBUG ) {
			console.warn( 'Adyen.payWithCard - missing card id' );
		}
	};


	/**
	 * Send the data from the request to handlebars
	 *
	 * @param {Object} [data]
	 */
	Adyen.prototype.renderData = function ( data ) {
		this.$element
			.html( handlebarsTemplates.render( this.options.adyenTemplate, data ) )
			.removeClass( 'loading' );
	};


	/**
	 * Remove a card from the system
	 *
	 * @param cardId
	 */
	Adyen.prototype.removeCard = function ( cardId ) {
		if ( cardId ) {
			$.ajax( {
				type:     'post',
				url:      window.makeUrl( { module: 'eshop', manager: 'eshop', action: 'disableAdyenCreditCard' } ),
				dataType: 'json',
				data:     { 'recurring_ref': cardId },
				success:  _.bind( function ( response ) {
					if ( response && response.success === 'true' ) {
						$( document ).trigger( 'zg-notification', [{
							eventType: 'zg.adyen',
							message:   JS_TRANSLATIONS['success.remove.getAdyenCreditCardDetails']
						}] );

						this.requestData();
					} else {
						$( document ).trigger( 'zg-error', [{
							eventType: 'zg.adyen',
							message:   JS_TRANSLATIONS['error.remove.getAdyenCreditCardDetails']
						}] );
					}
				}, this ),

				error: _.bind( function () {
					$( document ).trigger( 'zg-error', [{
						eventType: 'zg.adyen',
						message:   JS_TRANSLATIONS['error.remove.getAdyenCreditCardDetails']
					}] );
				}, this )
			} );
		} else if ( DEBUG ) {
			console.warn( 'Adyen.removeCard - missing card id' );
		}
	};


	/**
	 * Request the stored cards from Adyen
	 *
	 */
	Adyen.prototype.requestData = function () {
		this.$element.addClass( 'loading' );

		$.ajax( {
			type:     'post',
			url:      window.makeUrl( { module: 'eshop', manager: 'eshop', action: 'getAdyenCreditCardDetails' } ),
			dataType: 'json',
			success:  _.bind( function ( response ) {
				// send the response to formatResponse and use its result for renderData
				this.renderData( this.formatResponse( response ) );
			}, this ),

			error: _.bind( function () {
				// render the 'is empty' msg
				this.renderData();

				$( document ).trigger( 'zg-error', [{
					eventType: 'zg-adyen',
					message:   JS_TRANSLATIONS['error.getAdyenCreditCardDetails']
				}] );
			}, this )
		} );
	};

	/**
	 * Event Handlers
	 *
	 * @private
	 */
	Adyen.prototype.__setEventHandlers = function () {
		var that = this;

		this.$element.on( 'click.zg.adyen', this.options.adyenActionPay, function ( e ) {
			e.preventDefault();

			that.payWithCard( $( this ).data( 'id' ) );
		} );

		this.$element.on( 'click.zg.adyen', this.options.adyenActionRemove, function ( e ) {
			e.preventDefault();

			that.removeCard( $( this ).data( 'id' ) );
		} );

		this.$element.on( 'click.zg.adyen', this.options.adyenActionReload, function ( e ) {
			e.preventDefault();

			that.requestData();
		} );
	};


	// ADYEN PLUGIN DEFINITION
	// =======================

	function Plugin () {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.adyen' );
			var options = $.extend( {}, DEFAULTS, root.ZG_CONFIG || {}, $this.data() );

			if ( !data ) {
				$this.data( 'zg.adyen', (data = new Adyen( this, options )) );
			}

			data.requestData();
		} );
	}

	$.fn.zgAdyen             = Plugin;
	$.fn.zgAdyen.Constructor = Adyen;

	//
	// ================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );
}).call( this, jQuery );


(function ( $ ) {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	// start adyen encrypt
	$( function () {
		var form = document.getElementById( 'adyen-encrypted-form' );

		if ( form ) {
			if ( root.adyen && adyen.createEncryptedForm ) {
				// Adyen encryption options
				// https://github.com/Adyen/CSE-JS/blob/master/Options.md
				var options = {
					// callback for the payment form submit
					'onsubmit': function submitCallback () {
						$('body').addClass('loading');
					}
				};

				/* Put your WS users' CSE key here */
				adyen.createEncryptedForm( form, options );
			} else {
				console.error( 'Adyen encrypt library is missing' );
			}
		}
	} );

}).call( this, jQuery );