/* ============================================================
 *
 *
 *
 * ============================================================ */

(function ( $ ) { /* global DEBUG */
	"use strict";

	// VARIABLES
	// =========

	var url = {
				cart:      window.makeUrl({module: 'eshop', action: 'getCartInformation'}),
				wishlist:  window.makeUrl({module: 'eshop', action: 'getWishlistInformation'})
			},

		last = {
				cart:      null,
				wishlist:  null
			};


	// FUNCTIONS
	// =========

	/**
	 *
	 */
	function getProductListInfo ( e, type, showCart ) {
		type = type || "cart";

		$.ajax({
			type : 'post',
			url : url[ type ],
			dataType : 'json',

			success: function( response ) {
				if ( DEBUG ) { console.log( "getProductListInfo", type, response ); }

				if (response && response.status === "success") {
					last[ type ] = response;

					if ( response.total_products.total_amount ) {
						$('[data-zg-role="' + type + '-total"]').text( response.total_products.total_amount );
					}

					if ( response.total_products.total_no_shipcost ) {
						$('[data-zg-role="' + type + '-total-noshipcost"]').text( response.total_products.total_no_shipcost );
					}

					if ( response.total_products.total_items >= 0 ) {
						$('[data-zg-role="' + type + '-items-count"]').text( response.total_products.total_items );
					}

					$(document).trigger("zg.getProductListInfo.success", [type, response, showCart]);
				}
			}
		});
	}

	/*
	 *
	 */
	function getLastList ( type ) {
		type = type || "cart";

		return last[ type ];
	}

	window.getLastList = getLastList;
	window.getLastCart = function () { return getLastList( 'cart' ); };
	window.getLastWishlist = function () { return getLastList( 'wishlist' ); };


	// EVENT HANDLERS
	// ==============

	// request info
	$(document).on('zg.getProductList.request-info', function ( e, type ) {
		getProductListInfo( e, type );
	});

	// Update the cart/wishlist when a product is added to it.
	// The dropdown list is displayed
	$(document).on('zg.product.addedToCart', function ( e ) {
		getProductListInfo( e, 'cart', true);
	});
	$(document).on('zg.product.addedToWishlist', function ( e ) {
		getProductListInfo( e, 'wishlist', true);
	});

	// request cart and wishlist info on page load
	$(function () {
		getProductListInfo( null, 'cart' );
		getProductListInfo( null, 'wishlist' );
	});
}( jQuery ));




/* ============================================================
 * zg-Cart.js
 *
 *
 * ============================================================ */

(function ( $ ) { /* global handlebarsTemplates */
	"use strict";


	// GETPRODUCTLIST CLASS DEFINITION
	// ========================

	var GetList = function (element, type, options) {
		this.$element   = $(element);
		this.options    = options;
		this.type = type;

		this.$container  = $( this.options.elementContainer , this.$element );

		this.setEventHandlers();
		this.init();
	};


	GetList.DEFAULTS = {
		autoShow:      true,   // open the dropdown list when it is updated.
		autoShowTimer: 5000,   // how much time (ms) to keep it open.

		hideIfEmpty:   false,  // hide the list when it is empty.

		elementContainer:  '[data-zg-role="list-items-container"]',
		elementItemRemove: '[data-zg-role="list-item-remove"]',

		template: 'products-list-header',

		// urlEmptyList: window.makeUrl({module: 'eshop', action: 'emptyCart'}),
		urlItemRemove: {
			cart: window.makeUrl({module: 'eshop', action: 'delProductFromCart'}),
			wishlist: window.makeUrl({module: 'eshop', action: 'deleteWishlistItem'})
		}
	};


	/**
	 * Empty the list
	 *
	 */
	// GetList.prototype.emptyList = function () {
	// 	var that = this;

	// 	$.getJSON( that.config.urlEmptyList[ that.type ] , null, function ( response ) {
	// 		if (response && response.status === "success") {
	// 			$(document).trigger("zg.getProductList.request-info" [ that.type ]);
	// 		} else {
	// 			// something is broken!!!
	// 			$(document).trigger('zg-error', [{
	// 				eventType: 'empty-list',
	// 				message: ( response.message || window.JS_TRANSLATIONS.genericErrorMsg )
	// 			}]);
	// 		}
	// 	});
	// };


	/**
	 *
	 */
	GetList.prototype.init = function () {
		var data = window.getLastList( this.type );

		if ( data ) {
			this.updateList( data );
		}
	};


	/**
	 * Remove a product from the list
	 *
	 */
	GetList.prototype.removeItem = function ( product_id, sku, custom_id, quantity ) {
		var that = this,
			complex_id = (product_id + (sku ? "." + sku : "" ) ),
			request = {
				items: [ complex_id ],
				product_id: product_id,
				sku: sku,
				quantity: quantity || 1
			};

		if ( custom_id ) {
			request.custom_id = custom_id;
		}

		$.ajax({
			type : 'post',
			url : that.options.urlItemRemove[ that.type ],
			dataType : 'json',
			data : request,

			beforeSend: function () {
				that.$container.addClass('loading');
			},

			success: function( response ) {
				if (response && response.status !== "success") {
					// something is broken!!!
					$(document).trigger('zg-error', [{
						eventType: 'remove-list-item',
						message: (response.message || window.JS_TRANSLATIONS.genericErrorMsg)
					}]);
				}
			},

			error: function( response ) {
				// something is broken!!!
				$(document).trigger('zg-error', [{
					eventType: 'remove-list-item',
					message: (response.message || window.JS_TRANSLATIONS.genericErrorMsg)
				}]);
			},

			complete: function(){
				$(document).trigger('zg.getProductList.request-info', [ that.type ]);
			}
		});
	};


	/**
	 *
	 *
	 */
	GetList.prototype.setEventHandlers = function () {
		var that = this;

		//
		$(document).on('zg.getProductListInfo.success', function ( e, type, data, showCart ) {
			if ( that.type === type ) {
				that.updateList( data, showCart );
			}
		});

		//
		this.$container.on('click.zg.GetList', this.options.elementItemRemove, function ( e ) {
			var $this = $( this );

			e.preventDefault();

			that.removeItem( $this.data( 'id' ), $this.data( 'sku' ), $this.data( 'custom' ), $this.data( 'quantity' ) );
		});
	};


	/**
	 * Render the products for the list
	 * Use the Pagination Plugin if available
	 */
	GetList.prototype.renderList = function ( data ) {
		if ( this.$container.is( '[data-zg-role="pagination"]' ) ) {
			this.$container.zg_pagination( null, data[ "products_in_" + this.type ] || [] );
		} else {
			this.$container
					.addClass( 'loading' )
					.empty()
					.html(
						handlebarsTemplates.render(
							this.options.template,
							{
								type : this.type,
								products : data[ "products_in_" + this.type ] || [],
								total_products : data.total_products || {}
							}
						)
					)
					.removeClass( 'loading' );
		}
	};


	/**
	 * Show the DropDown list
	 * Automatically close it later
	 */
	GetList.prototype.showCart = function () {
		var that = this;

		if ( !this.$element.is('.open') ) {
			$('[data-toggle="dropdown"]:visible', this.$element).dropdown("toggle");

			setTimeout(
				function () {
					if ( that.$element.is('.open') && !that.$element.is(':hover') ) { // If is still open close it
						$('[data-toggle="dropdown"]:visible', that.$element).dropdown("toggle");
					}
				},
				( that.options.autoShowTimer || 5000 )
			);
		}
	};


	/**
	 *
	 *
	 * @param {Object} data
	 * @param {?boolean=} showCart
	 */
	GetList.prototype.updateList = function ( data, showCart ) {
		// render the products for the list
		this.renderList( data );


		// $total.text( data.total_products.total_amount );
		// $items.text( data.total_products.total_items );

		if ( !data.total_products || !data.total_products.total_items ) {
			// If there are no products and the configuration says so we hide the list
			if ( this.options.hideIfEmpty ) {
				this.$element.addClass('hidden');
			}
		} else {
			// There are products, we display again the list
			this.$element.removeClass('hidden');

			// Open the list
			if (showCart && this.options.autoShow) {
				this.showCart();
			}
		}

		$(document).trigger('zg.getProductList.updated');
	};



	// GETPRODUCTLIST PLUGIN DEFINITION
	// =========================

	function Plugin ( option ) {
		return this.each(function () {
			var $this   = $(this),
				data    = $this.data('zg.getProductList'),
				options = $.extend({}, GetList.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option),
				type    = (typeof option === 'string' && option) || options.type || 'cart';

			if (!data) {
				$this.data('zg.getProductList', (data = new GetList(this, type, options)));
			}

		});
	}

	$.fn.getProductList             = Plugin;
	$.fn.getProductList.Constructor = GetList;


	// GETPRODUCTLIST DATA-API
	// ================

	$(function () {
		$('[data-zg-role="cart"]').each( function () {
			Plugin.call( $(this), 'cart' );
		});
		$('[data-zg-role="wishlist"]').each( function () {
			Plugin.call( $(this), 'wishlist' );
		});
	});

}( jQuery ));




/* ============================================================
 * Update the total cost when another country is selected
 *
 *
 * ============================================================ */

(function ( $ ) {
	"use strict";

	// VARIABLES
	// =========

	var elementShipping = '[data-zg-role="cart-shipping-cost"]',
		elementTotal =    '[data-zg-role="cart-total"]';

	// FUNCTIONS
	// =========

	// Update total price.
	function recalculateTotal ( shipping_cost ) {
		var lastCart = window.getLastCart();

		shipping_cost = +(shipping_cost);

		if ( lastCart && !isNaN( shipping_cost ) ) {
			// prices are coming as a string and the thousands and millions are divided with ","  T_T
			var total_noShip = +( lastCart.total_products.total_no_shipcost.replace(/,/g, '') );
			var total = total_noShip + shipping_cost;

			$( elementShipping ).text( shipping_cost.toFixed( 2 ) );
			$( elementTotal ).text( total.toFixed( 2 ) );
		}
	}

	// EVENT HANDLERS
	// ==============

	//
	$( '[data-zg-role="cart-shipping-select"]' )
		.on('change.zg.recalculateTotal', function () {
			recalculateTotal( +( $(this).val() ) );
		})
		.trigger('change.zg.recalculateTotal');
}( jQuery ));