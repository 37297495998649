/* ============================================================
 * zg-facebook-auth.js
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 * @author: G K <gkasa@zerogrey.com>
 *
 * ============================================================ */

(function ( $ ) {

	"use strict";

	if ( typeof FB_READY == 'undefined' ) {
		return false;
	}

	FB_READY.done( function () {
		var keepAlive      = 1;
		var keepAliveTimer = 0;
		var logoutTimer    = 0;

		window.checkStatusFB = function () {
			FB.getLoginStatus( function ( response ) {
				if ( response.status === 'connected' ) {
					// the user is logged in and has authenticated your app
					var url  = window.SGL_JS_WEBROOT + '/eshop/eshop/';
					var data = {
						action:  "checkStatusSN",
						sn_type: "facebook"
					};
					$.post( url, data, function ( response ) {
						if ( response.result === 'success' ) {
							//                            window.console.log(response);
						} else if ( response.result === 'failure' ) {
							window.clearInterval( keepAliveTimer );
							keepAlive = 0;
						}
					}, 'json' );
				} else if ( response.status === 'not_authorized' ) {
					// the user is logged in to Facebook,
					// but has not authenticated your app
					window.clearInterval( keepAliveTimer );
				} else if ( typeof keepAliveTimer !== 'undefined' ) {
					// the user isn't logged in to Facebook.
					window.clearInterval( keepAliveTimer );
				}
			}, true );
		};

		window.forceLogout = function () {
			window.location.href = window.SGL_JS_WEBROOT + '/user/login/action/logout/';
		};

		FB.Event.subscribe( 'auth.statusChange', function ( response ) {

			if ( response.status === 'connected' && window.SGL_JS_ISLOGGED && keepAlive === 1 ) {
				if ( typeof keepAliveTimer === 'undefined' || keepAliveTimer === 0 ) {
					keepAliveTimer = setInterval( window.checkStatusFB, 200000 );
				}
			} else if ( typeof keepAliveTimer !== 'undefined' ) {
				window.clearInterval( keepAliveTimer );
			}
		} );

		$( ".zg_fblogin" ).on( "click", function () {

			// Changed due to new Facebook policies about the App Review
			//var scope = 'email,user_birthday';
			var scope = 'email';
			
			FB.login( function ( response ) {
				if ( response.status === "connected" ) {
					var currentUrl = window.location.toString();
					var redirectTo = $( '#login-redir' ).attr( 'value' );
					if ( typeof redirectTo === "undefined" ) {
						redirectTo = currentUrl;
					}
					//var fbLoginForm = $('<form name="fbLogin" action="' + SGL_JS_WEBROOT + '/eshop/login/"
					// method="post">' + '<input type="hidden" name="sn_login" value="1" />' + '<input type="hidden"
					// name="sn_type" value="facebook" />' + '<input type="hidden" name="redir" value="' + redirectTo +
					// '" />' + '</form>');
					var $fbLoginForm = $( '<form />' );
					$fbLoginForm.attr( {
						'name':   'fbLogin',
						'action': SGL_JS_WEBROOT + '/eshop/login/',
						'method': 'post'
					} );
					var $input1      = $( '<input type="hidden" />' );
					$input1.attr( {
						'name':  'sn_login',
						'value': '1'
					} );
					var $input2      = $( '<input type="hidden" />' );
					$input2.attr( {
						'name':  'sn_type',
						'value': 'facebook'
					} );
					var $input3      = $( '<input type="hidden" />' );
					$input3.attr( {
						'name':  'redir',
						'value': redirectTo
					} );
					$input1.appendTo( $fbLoginForm );
					$input2.appendTo( $fbLoginForm );
					$input3.appendTo( $fbLoginForm );

					$( 'body' ).append( $fbLoginForm );
					$fbLoginForm.submit();
				}
			}, {
				scope: scope
			} );
		} );

		$( ".zg_fblogout" ).on( "click", function () {
			logoutTimer = setTimeout( window.forceLogout, 2000 );

			FB.getLoginStatus( function ( response ) {
				window.clearTimeout( logoutTimer );
				if ( response.status === "connected" ) {
					FB.logout( function ( response ) {
						window.forceLogout();
					} );
				} else {
					window.forceLogout();
				}
			}, true );

			return false;
		} );

	} );
})( jQuery );
