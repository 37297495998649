﻿jQuery.validator.addMethod("creditcard2", function(value, element, param) {
	var cardName = param;

	var cards = [
		{ cardName: "VISA-SSL", lengths: "13,16", prefixes: "4", checkdigit: true },
		{ cardName: "ECMC-SSL", lengths: "16", prefixes: "51,52,53,54,55", checkdigit: true },
		{ cardName: "DINERS-SSL", lengths: "14,16", prefixes: "305,36,38,54,55", checkdigit: true },
		{ cardName: "AMEX-SSL", lengths: "15", prefixes: "34,37", checkdigit: true },
		{ cardName: "JCB-SSL", lengths: "16", prefixes: "35", checkdigit: true },
		{ cardName: "MAESTRO-SSL", lengths: "12,13,14,15,16,18,19", prefixes: "5018,5020,5038,6304,6759,6761", checkdigit: true },
		{ cardName: "VisaElectron", lengths: "16", prefixes: "417500,4917,4913,4508,4844", checkdigit: true },
		{ cardName: "LASER-SSL", lengths: "16,17,18,19", prefixes: "6304,6706,6771,6709", checkdigit: true }
	];

	var cardType = -1;
	for (var i = 0; i < cards.length; i++) {
		if (cardName.toLowerCase() == cards[i].cardName.toLowerCase()) {
			cardType = i;
			break;
		}
	}

	// card type not found
	if (cardType == -1) { return false; }

	// remove spaces and dashes
	value = value.replace(/[\s-]/g, "");

	// no length
	if ( !value.length ) { return false; }

	var cardNo = value;
	var cardexp = /^[0-9]{13,19}$/;

	// has chars or wrong length
	if (!cardexp.exec(cardNo)) { return false; }

	// strip down to digits
	cardNo = cardNo.replace(/\D/g, "");

	if (cards[cardType].checkdigit) {
		var checksum = 0;
		var mychar = "";
		var j = 1;

		var calc;
		for (i = cardNo.length - 1; i >= 0; i--) {
			calc = Number(cardNo.charAt(i)) * j;
			if (calc > 9) {
				checksum = checksum + 1;
				calc = calc - 10;
			}
			checksum = checksum + calc;

			if (j == 1) { j = 2; } else { j = 1; }
		}

		// not mod10
		if (checksum % 10 !== 0) { return false; }
	}

	var lengthValid = false;
	var prefixValid = false;
	var prefix = [];
	var lengths = [];

	prefix = cards[cardType].prefixes.split(",");
	for (i = 0; i < prefix.length; i++) {
		var exp = new RegExp("^" + prefix[i]);
		if (exp.test(cardNo)) prefixValid = true;
	}

	// invalid prefix
	if (!prefixValid) { return false; }

	lengths = cards[cardType].lengths.split(",");

	for (var t = 0; t < lengths.length; t++) {
		if (cardNo.length == lengths[t]) {
			lengthValid = true;
		}
	}

	// wrong length
	if (!lengthValid) { return false; }

	return true;
}, jQuery.validator.messages.creditcard);