(function ($) {
	"use strict";

	// CLEANQUANTITY CLASS DEFINITION
	// ==============================

	var ZG_CleanQuantity = function ( element, enableFloat ) {
		this.$element = $( element );
		this.enableFloat = enableFloat;

		this.step = +(this.$element.attr('step')) || ( this.enableFloat ? 0.01 : 1 );
		this.min  = +(this.$element.attr('min')) || 0;
		this.max  = +(this.$element.attr('max')) || null;
	};


	ZG_CleanQuantity.prototype.clean = function ( quantity ) {
		// get the current quantity
		quantity = +(quantity || this.$element.val() || 1);

		if ( this.max ) {
			quantity = Math.min( quantity, this.max );
		}

		// make sure the quantity is an integer and at least 1
		quantity = Math.max( this.min, Math.round( quantity / this.step ) || 1 ) * this.step;

		//
		if ( this.enableFloat ) {
			quantity = ( quantity ).toFixed(2);
		}

		// set quantity as the 'clean' version
		this.$element.val( quantity );
	};


	// CLEANQUANTITY PLUGIN DEFINITION
	// ===============================

	function Plugin( quantity, enableFloat ) {
		return this.each(function () {
			var $this  = $(this);
			var data   = $this.data('zg.cleanQuantity');

			if (!data) {
				$this.data('zg.cleanQuantity', (data = new ZG_CleanQuantity(this, enableFloat)));
			}

			data.clean( quantity );
		});
	}

	$.fn.zg_cleanQuantity             = Plugin;
	$.fn.zg_cleanQuantity.Constructor = ZG_CleanQuantity;


	// CLEANQUANTITY DATA-API
	// ======================

	// $(document).on('change.zg.cleanQuantity.data-api', '[data-zg-role~="quantity"]', function (e) {
	// 	Plugin.call( $(this) );
	// });

})(jQuery);