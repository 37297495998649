/* ==================================================================================================================
 * zgGetDirections
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ================================================================================================================== */

(function () { /* global _, DEBUG, google, isGoogleMapsAvailable */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;


	/**
	 *
	 */
	var GetDirections = function ( options ) {
		/** @type {null|google.maps.DirectionsService} */
		this.directionsService = null;

		/** @type {null|google.maps.DirectionsRenderer} */
		this.directionsRenderer = null;

		this.options = _.extend( {}, GetDirections.DEFAULTS, root.ZG_CONFIG, typeof options === 'object' && options );

		this.__init();
	};


	GetDirections.DEFAULTS = {
		directionsContainer: '[data-zg-role="sl-directions-container"]'
	};


	GetDirections.prototype.calculate = function ( origin, destination, map, callback ) {
		var $directions, request, that;

		// remove the previous route
		this.clear();

		if ( origin && destination && map && this.directionsService ) {
			that = this;

			$directions = $( this.options.directionsContainer );

			request = {
				origin:      origin,
				destination: destination,
				travelMode:  google.maps.TravelMode.DRIVING,
				unitSystem:  google.maps.UnitSystem.METRIC
			};

			this.directionsService.route( request, function ( result, status ) {
				if ( status === google.maps.DirectionsStatus.OK ) {

					that.directionsRenderer.setDirections( result );
					that.directionsRenderer.setMap( map );
					that.directionsRenderer.setPanel( $directions[0] );

					$directions.removeClass( 'hidden' ).fadeIn();

				} else {

					$( document ).trigger( 'zg-error', [{
						eventType: 'empty-list',
						message:   window.JS_TRANSLATIONS.genericErrorMsg
					}] );
				}

				if ( DEBUG ) {
					console.log( 'StoreLocator.GetDirections', status, result );
				}

				if ( _.isFunction( callback ) ) {
					callback();
				}
			} );
		} else {
			$( document ).trigger( 'zg-error', [{
				eventType: 'empty-list',
				message:   window.JS_TRANSLATIONS.genericErrorMsg
			}] );
		}

	};


	GetDirections.prototype.clear = function () {
		if ( this.directionsRenderer ) {
			this.directionsRenderer.setMap( null );
			this.directionsRenderer.setPanel( null );

			$( this.options.directionsContainer ).hide();
		}
	};


	GetDirections.prototype.__init = function () {
		if ( isGoogleMapsAvailable() ) {
			this.directionsService  = new google.maps.DirectionsService();

			this.directionsRenderer = new google.maps.DirectionsRenderer( {
				suppressMarkers:     true,
				suppressInfoWindows: true
			} );
		} else if ( DEBUG ) {
			console.error( 'ZgStoreLocatorGetDirections - FAILED: google maps is not available' );
		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorGetDirections = GetDirections;

}.call( this ));
