// This file is included in Adyen Skin. Please set theme and merchant_id before go live with Adyen. The less connected is custom/zg-adyen.less
var onJqueryLoaded = function () {

    $.ajax({
        url: '//securecc.zerogrey.com/eshop/eshop/action/getLastCompiled/',
        cache: false,
        dataType: "json",
        data : {
            type        : 'css',
            theme       : 'Turbolenza_2016',
            merchant_id : '287',
            force_ajax  : 1
        },
        success: function ( response ) {

            $.each(response.css, function(index, value) {

                $('head').append('<link rel="stylesheet" type="text/css" href="'+value+'">');

            });
        }
    });

};

// Load jquery
if ( window.zg )
    zg.require( 'js', 'https://code.jquery.com/jquery-1.11.3.min.js', onJqueryLoaded );
