/* ============================================================
 * zg-utils.js
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

// In case we forget to take out console statements.
// IE becomes very unhappy when we forget.
// Let's not make IE unhappy
(function () {
	"use strict";

	var method,
		noop = function () {},
		methods = [
			'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed',
			'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline',
			'timelineEnd', 'timeStamp', 'trace', 'warn'
		],
		length = methods.length,
		console = (window.console = window.console || {});

	while ( length-- ) {
		method = methods[length];

		// Only stub undefined methods.
		if ( !console[method] ) {
			console[method] = noop;
		}
	}
}());


(function ( $ ) {  /* global _ */
	"use strict";

	window.makeUrl = function ( params ) {
		var rslt = window.SGL_JS_WEBROOT + '/' + window.SGL_JS_FRONT_CONTROLLER;
		var moduleName = params.module ? params.module : '';
		var managerName = params.manager ? params.manager : moduleName;
		var x;

		if ( 'SGL_UrlParser_ClassicStrategy' === window.SGL_JS_URL_STRATEGY ) {
			if ( rslt.charAt( rslt.length - 1 ) !== '?' ) {
				rslt += '?';
			}
			rslt = rslt + 'moduleName=' + window.escape( moduleName ) + '&managerName=' + window.escape( managerName ) + '&';
			for ( x in params ) {
				if ( (x === 'module') || (x === 'manager') ) {
					continue;
				}
				rslt = rslt + window.escape( x ) + '=' + window.escape( params[x] ) + '&';
			}
		} else {
			if ( rslt[rslt.length - 1] === '/' ) {
				rslt = rslt + window.escape( moduleName ) + '/' + window.escape( managerName ) + '/';
			} else {
				rslt = rslt + '/' + window.escape( moduleName ) + '/' + window.escape( managerName ) + '/';
			}
			for ( x in params ) {
				if ( (x === 'module') || (x === 'manager') ) {
					continue;
				}
				rslt = rslt + window.escape( x ) + '/' + window.escape( params[x] ) + '/';
			}
		}
		return rslt;
	};

}( jQuery ));


(function ( $ ) { /* global _, zgGetObjectPropertyValue */
	"use strict";

	/**
	 * Comparison script to sort a normal array, an array of objects or a jQuery array of DOM elements.
	 * For Objects and DOM arrays it is required to provide a property to sort by.
	 *
	 * @param {!Object} primary
	 * @param {?Object} secondary
	 *
	 * Objects structure:
	 *   dir - {number} - if is a positive number sorts ascending, if negative sorts descending. Defaults to 1
	 *   attr - {string} - value to use for sorting. for DOM elements can be a attribute or data-attribute and for
	 *     objects a property
	 *   pattern - {Array} - pattern to use for sorting
	 *   avoidNumbersOnTop - {boolean} - do not sort numbers before text
	 *
	 */
	window.zg_sortElements = function zg_sortElements ( primary, secondary ) {
		var dir = primary.dir || 1;

		var defaultPatterns = [
			['3xs', 'xxxs', '2xs', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', '2xl', 'xxxl', '3xl']
		];


		var sortByPattern = function ( aa, bb, pattern ) {
			if ( _.contains( pattern, aa ) && !_.contains( pattern, bb ) ) {
				return -1;
			}

			if ( !_.contains( pattern, aa ) && _.contains( pattern, bb ) ) {
				return 1;
			}

			if ( _.contains( pattern, aa ) && _.contains( pattern, bb ) ) {
				return (($.inArray( aa, pattern ) - $.inArray( bb, pattern )));
			}

			return 0;
		};

		return function ( a, b ) {
			var aa = zgGetObjectPropertyValue( a, primary.attr ),
				bb = zgGetObjectPropertyValue( b, primary.attr ),
				res = 0;

			if ( aa === bb ) {
				// a and b are equal. try to use the secondary sorting method
				if ( secondary ) {
					var secAr = [a, b].sort( zg_sortElements( secondary ) );
					res = +(secondary.dir || 1) * (zgGetObjectPropertyValue( secAr[0], secondary.attr ) === zgGetObjectPropertyValue( a, secondary.attr ) ? -1 : 1);
				}

				return res;
			}

			if ( aa && _.isNull( bb ) ) {
				return (+dir);
			}

			if ( bb && _.isNull( aa ) ) {
				return -(+dir);
			}

			// try to sort the elements using the user defined pattern
			if ( primary.pattern ) {
				res = sortByPattern( aa, bb, primary.pattern );

				if ( res !== 0 ) {
					return (+dir) * res;
				}
			}

			// try to sort the elements using the predefined pattern
			for ( var i = 0; i < defaultPatterns.length; i++ ) {
				res = sortByPattern( aa, bb, defaultPatterns[i] );

				if ( res !== 0 ) {
					return (+dir) * res;
				}
			}

			// ordering numbers
			if ( !isNaN( aa ) && !isNaN( bb ) ) {
				return ((+dir) * ( (+aa) - (+bb) ));
			}

			if ( !primary.avoidNumbersOnTop ) {
				// numbers should be on top
				if ( !isNaN( aa ) && isNaN( bb ) ) {
					return -(+dir);
				}

				if ( isNaN( aa ) && !isNaN( bb ) ) {
					return (+dir);
				}
			}

			// this function (if available) will sort the elements even if they have non ASCII characters.
			if ( aa && _.isFunction( aa.localeCompare ) ) {
				return ((+dir) * aa.localeCompare( bb ));
			}

			// fallback
			return ((+dir) * ((aa < bb) ? -1 : ((aa > bb) ? 1 : 0)));
		};
	};
	// Usage:
	// var $elements = $( selector )
	// $elements.sort( zg_sortElements( {
	//		dir: [ 1 | -1 ],
	//		attr: [ "attribute" | null ],
	//		pattern: [array],
	//		avoidNumbersOnTop: [true|false]
	//	} ));
	// $elements.parent().append( $elements );


	/**
	 *
	 *
	 * @param {Object}  values
	 * @param {string=} prop
	 *
	 * @returns {Array}
	 */
	window.zgSortObjectByProp = function ( values, prop ) {
		var key, array;

		array = [];

		for ( key in values ) {
			if ( values.hasOwnProperty( key ) ) {
				// include the key as a property and add the object to the array
				values[key].key = key;
				array.push( values[key] );
			}
		}

		array.sort( window.zg_sortElements( {
			attr: prop || 'name',
			avoidNumbersOnTop: true
		} ) );

		return array;
	};

}( jQuery ));


/**
 * COOKIEEEES!!!
 *
 */
(function ( $ ) {
	"use strict";

	function createCookie ( name, value, days ) {
		var expires = "";

		if ( days ) {
			var date = new Date();
			date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );

			expires = "; expires=" + date.toGMTString();
		}

		document.cookie = name + "=" + value + expires + "; path=/";
	}

	function readCookie ( name ) {
		var nameEQ = name + "=";
		var ca = document.cookie.split( ';' );
		for ( var i = 0; i < ca.length; i++ ) {
			var c = ca[i];
			while ( c.charAt( 0 ) == ' ' ) {
				c = c.substring( 1, c.length );
			}
			if ( c.indexOf( nameEQ ) == 0 ) {
				return c.substring( nameEQ.length, c.length );
			}
		}
		return null;
	}

	function eraseCookie ( name ) {
		createCookie( name, "", -1 );
	}

	// eraseCookie('ZG-cookiepolicy-'+SGL_JS_THEME);

	var cookieAlert = '[data-zg-role="cookie-alert"]',
		cookieName = 'ZG-cookiepolicy-' + window.SGL_JS_THEME;

	// CHECK IF THERE IS COOKIE
	if ( !readCookie( cookieName ) ) {
		var $cookiepolicy = $( cookieAlert );

		$cookiepolicy.on( 'close.bs.alert', function () {
			createCookie( cookieName, 'ZG Cookies Policy Accepted', 30 );
		} );

		$cookiepolicy.removeClass( "hidden" );
	}

}( jQuery ));


(function ( $ ) {
	"use strict";

	$( function () {
		// Shorthand to print the current page
		$( document ).on( 'click', '[data-role="print"]', function () {
			window.print();
		} );

		// Don't allow the user to click multiple times on those links
		$( '[data-zg-role="stopDoubleclick"]' ).on( 'click', function () {
			$( this ).off( 'click' );
			$( this ).on( 'click', function () { return false; } );
		} );

	} );

}( jQuery ));
