(function ( $ ) { /* global _, handlebarsTemplates, zgProcessProductImages */
	"use strict";

	var selector = '[data-zg-role="get-product"]';


	// GETPRODUCT CLASS DEFINITION
	// ===========================

	/**
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var GetProductInfo = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( GetProductInfo.DEFAULTS );
		this.request = _.clone( GetProductInfo.DEFAULT_REQUEST );
		this.updateOptions( options );

		this.$container = this.options.productsContainer ? $( this.options.productsContainer ) : this.$element;
	};

	// AJAX CALL VALUES
	GetProductInfo.DEFAULT_REQUEST = {
		// only use one of the following
		categoryId: null,
		products: null,

		// lang : "en",  // you could use this to force a language in the result
		forceResult: false,  // forces to get the product info even if the shop configuration would prevent it

		getCategories: false,
		getCharacteristics: false,
		getClassification: false,
		getCustomValues: false,
		getDescriptions: false,
		getImages: false,
		getLinkedProducts: false,
		getNextPrev: false,
		getOptions: false,
		getQuantity: false, // Do not enable this one. It is evil
		getPrice: false,
		getPromotions: false,
		getPhysicalCharacteristics: false,
		getProperties: false,
		getSeo: false,
		getSkus: false,
		getStandardOptions: false,
		getTextile: false,

		// get the SEO url
		getUrl: false,

		// get filters info
		getFilterCategories: false,
		getFilterCharacteristics: false,
		getFilterClassification: false,
		getFilterOptions: false,
		getFilterProperties: false,
		getFilterPrice: false,
		getFilterPromotions: false
	};


	// SCRIPT OPTIONS
	GetProductInfo.DEFAULTS = {
		url: window.makeUrl( { module: 'eshop', action: 'getProductsInfo' } ),

		type: 'product',

		// target element where the items will be added. if its empty it will try to use the current element
		productsContainer: null,
		// handlebars template to create the items
		productsTemplate: null,
		// Should we send all the products together to the template (true) or one by one (false)
		isGroupTemplate: false,

		errorTemplate: "product-error", // handlebars template for the item to be created if the request fails
		namespace: null,

		filterContainer: '[data-zg-role="filters"]',
		paginationContainer: '[data-zg-role="pagination"]',

		// Empty the target element before adding the new items
		empty: true,

		// Process the crazy back-end structure for the images and creates something actually usable.
		// should be true only for the product page and maybe the quickbuy.
		// DO NOT SET AS TRUE FOR THE CATEGORIES. this code is quite heavy.
		processImages: false
	};


	/**
	 *
	 *
	 * @param {Array=}  filters
	 * @param {Array=}  products
	 * @param {string=} url
	 */
	GetProductInfo.prototype.createFilters = function ( filters, products, url ) {
		var $filterContainer = $( this.options.filterContainer );

		if ( $filterContainer.length > 1 ) {
			$filterContainer = $( this.options.filterContainer, this.$element );
		}

		$filterContainer.zg_filters( this.options, filters, products, url );
	};


	/**
	 *
	 * @param {Array} products
	 */
	GetProductInfo.prototype.createProducts = function ( products ) {
		var i;

		if ( this.options.empty ) {
			// remove the product events
			this.$container.find( '[data-zg-role="product"]' ).each( function () {
				var productData = $( this ).data( "zg.product" );

				if ( productData ) {
					$( document ).off( "." + productData.options.namespace );
				}

				// TODO: destroy zoom
			} );

			this.$container.empty();
		}

		if ( this.options.isGroupTemplate ) {

			// send all the products together to the same template
			this.renderProduct( products );

		} else {

			// send the products one by one
			for ( i = 0; i < products.length; i++ ) {
				this.renderProduct( products[i] );
			}
		}
	};


	/**
	 *
	 */
	GetProductInfo.prototype.getInfo = function () {
		var that = this;

		$.ajax( {
			type: 'POST', // 'GET' breaks on the search version (too many parameters)
			url: that.options.url,
			dataType: 'json',
			data: that.request,

			beforeSend: function () {
				that.onBeforeSend();
			},

			success: function ( response ) {
				that.onSuccess( response );
			},

			error: function ( response ) {
				that.onError( response );
			},

			complete: function () {
				that.onComplete();
			}
		} );
	};


	/**
	 *
	 * @param {Object|Array} product
	 */
	GetProductInfo.prototype.renderProduct = function ( product ) {
		var $item = $( handlebarsTemplates.render( this.options.productsTemplate, product ) );

		this.$container.append( $item );

		// Fade-in in using bootstrap classes.
		setTimeout(
			function () {
				$item.addClass( 'in' );
			},
			150
		);

		if ( window.DEBUG ) {
			console.log( "GetProductInfo - renderProduct", product );
		}

		$( document ).trigger( 'zg.getProductInfo.productCreated', [$item, this.options, product] );
	};


	/**
	 *
	 * @returns {boolean}
	 */
	GetProductInfo.prototype.hasRequestedFilters = function () {
		var requested = false,
			option;

		for ( option in this.request ) {
			if (
				this.request.hasOwnProperty( option ) &&
				this.options[option] &&
				option.indexOf( 'getFilter' ) === 0
			) {
				requested = true;
				break;
			}
		}

		return requested;
	};


	/**
	 *
	 */
	GetProductInfo.prototype.onBeforeSend = function () {
		this.$container.addClass( "loading" );

		$( document ).trigger( 'zg.getProductInfo.start' );
	};


	/**
	 *
	 */
	GetProductInfo.prototype.onComplete = function () {
		this.$container.removeClass( "loading" );

		$( document ).trigger( 'zg.getProductInfo.complete' );
	};


	/**
	 *
	 * @param {Object} response
	 */
	GetProductInfo.prototype.onError = function ( response ) {
		if ( window.DEBUG ) {
			console.log( 'GetProductInfo - ERROR', response );
		}

		if ( this.options.empty ) {
			this.$container.empty();
		}

		// if there is an error template we add it in the target. otherwise we display an error message
		if ( this.options.errorTemplate ) {
			this.$container.append(
				handlebarsTemplates.render( this.options.errorTemplate, response || {} )
			);
		} else {
			$( document ).trigger( 'zg-error', [{
				eventType: 'get-products-information',
				message: window.JS_TRANSLATIONS.genericErrorMsg
			}] );
		}

		$( document ).trigger( 'zg.getProductInfo.error' );
	};


	/**
	 *
	 * @param {Object} response
	 */
	GetProductInfo.prototype.onSuccess = function ( response ) {
		var $pagination;

		if ( window.DEBUG ) {
			console.log( 'GetProductInfo - SUCCESS', response );
		}

		// Update each product in the array with some extra info
		if ( response.products ) {
			this.processProducts( response.products );
		}

		if ( response.filters || this.hasRequestedFilters() ) {
			this.createFilters( response.filters, (response.products || []), response.url );
		} else if ( response.products && this.options.productsTemplate ) {
			// A template was set up for the products. We render them.
			this.createProducts( response.products );
		} else {
			$pagination = $( this.options.paginationContainer, this.$element );

			if ( !$pagination.length ) {
				$pagination = this.$container;
			}

			$pagination.zg_pagination( this.options, response.products || [] );
		}

		$( document ).trigger( 'zg.getProductInfo.success', [response.products, response.filters] );
	};


	/**
	 *
	 * @param {Array} products
	 */
	GetProductInfo.prototype.processProducts = function ( products ) {
		var i;

		for ( i = 0; i < products.length; i++ ) {
			// set the type of the requested product
			products[i].type = this.options.type;

			if ( this.request.categoryId ) {
				products[i].currentCategory = this.request.categoryId;
			}
		}
	};


	/**
	 *
	 */
	GetProductInfo.prototype.processRequest = function () {
		var param, value;

		for ( param in this.request ) {
			// Filter by values by the default properties and not falsy values.
			// We don't want to create an unnecessarily big request
			if (
				this.request.hasOwnProperty( param ) &&
				GetProductInfo.DEFAULT_REQUEST.hasOwnProperty( param ) &&
				this.options[param] // only truthy values
			) {
				value = this.options[param];

				// if the value is a string split into array (necessary for backend).
				// This has to happen even if there is just one value.
				if ( _.isString( value ) ) {
					value = value.split( "," );
				}

				this.request[param] = value; // only truthy values;
			} else {
				delete this.request[param];
			}
		}
	};


	/**
	 *
	 * @param {Object=} options
	 */
	GetProductInfo.prototype.updateOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );
		_.extendOwn( this.request, options || {} );

		this.processRequest();
	};


	// GETPRODUCT PLUGIN DEFINITION
	// ============================

	function Plugin ( option, updateOptions ) {
		return this.each( function () {
			var $this = $( this );
			var data = $this.data( 'zg.getProductInfo' );
			var options = $.extend( {}, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.getProductInfo', (data = new GetProductInfo( this, options )) );
			} else if ( updateOptions && typeof option === 'object' ) {
				data.updateOptions( option );
			}

			data.getInfo();
		} );
	}

	$.fn.getProductInfo = Plugin;
	$.fn.getProductInfo.Constructor = GetProductInfo;


	// GETPRODUCT DATA-API
	// ===================

	// default product - called on page load
	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );

		// do a new AJAX request updated with new data attributes
		$( selector ).on( 'click.zg.getProductInfo.data-api', '[data-zg-role="update-product-info"]', function ( e ) {
			var $this = $( this );

			Plugin.call( $this.closest( selector ), $this.data(), true );

			e.preventDefault();
		} );

		// do a new AJAX request updated with new data attributes - from event
		$( selector ).on( 'UpdateProductInfo.zg.getProductInfo.data-api', function ( e, data ) {
			Plugin.call( $( this ), data, true );
		} );


		// quickbuy
		$( document ).on( 'click.zg.getProductInfo.data-api', '[data-zg-role~="quickbuy"]', function ( e ) {
			Plugin.call( $( this ), { type: 'quickbuy' } );

			e.preventDefault();
		} );

		// return && edit cart
		$( document ).on( 'click.zg.getProductInfo.data-api', '[data-zg-role~="quickedit"]', function ( e ) {
			Plugin.call( $( this ), { type: 'exchange' } );

			e.preventDefault();
		} );
	} );

}( jQuery ));
