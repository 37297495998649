$.fn.persistentMenu = function(options) 
{
	var settings = $.extend({
		css: {
			'position': 'fixed',
			'top': '0px',
			'left': '0px',
			'width': '100%',
			'z-index': '10000'
		},
		flickerStopElement: null,
		flickerStopCSS: null,
		flickerStopCSSMobile: null,
		desktopWidthMin: 768
	},options);

	var persistentMenuEnabled = false;

	function eventScrollResize(top,element){
		scrollTop = $(document).scrollTop();
		width = $(document).width();
		if ( scrollTop >= top ){
			$(element).css(settings.css).addClass('persistent');
			$('.nav-lang .dropdown').removeClass('open');
			$('.nav-lang .dropdown-menu').slideUp();
			if ( width >= settings.desktopWidthMin ) $(settings.flickerStopElement).css(settings.flickerStopCSS);
			else $(settings.flickerStopElement).css(settings.flickerStopCSSMobile);
			this.persistentMenuEnabled = true;
		}
		else{
			eventScrollReset(element);
		}
	}
	
	function eventScrollReset(element){
		$(element).removeAttr('style').removeClass('persistent');
		$(settings.flickerStopElement).removeAttr('style');
		this.persistentMenuEnabled = false;
	}

	return this.each(function()
	{
		var element = this;
		var top = $(element).offset().top;
		$(document).scroll(function(){
			eventScrollResize(top,element);
		});
		$(window).resize(function(){
			eventScrollReset(element);
			top = $(element).offset().top;
			eventScrollResize(top,element);
		});
		$('#cookieAlert .close').click(function(){
			setTimeout(function(){
				eventScrollReset(element);
				top = $(element).offset().top;
				eventScrollResize(top,element);
			},150);
		});
		eventScrollResize(top,element);
	}); 
};
