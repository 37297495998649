(function ( $ ) {

	"use strict";

	var toggle = '[data-zg-role="fidelity"]';
	var getFidelityInfo = '[data-zg-role="get-fidelity-info"]';
	var getPrintableCard = '[data-zg-role="get-printable-card"]';
	var getPrintableCardSubmit = '[data-zg-role="get-printable-card-submit"]';
	var linkFidelityCardSubmit = '[data-zg-role="link-fidelity-card-submit"]';
	var fidelityCardInfoRefreshSubmit = '[data-zg-role="fidelity-card-info-refresh-submit"]';
	var use_fidelity_card_checkbox = '[data-zg-role="use-fidelity-card-checkbox"]';
	var useFidelityPointsSubmit = '[data-zg-role="use-fidelity-points-submit"]';
	var registerFidelityCardSubmit = '[data-zg-role="register-fidelity-card-submit"]';
	var checkFidelityCardStatus = '[data-zg-role="check-fidelity-card-status"]';
	var deleteFidelityDiscount = '[data-zg-role="deleteFidelityDiscount"]';
	
	var ZG_Fidelity = function ( element, config ) {
		var self = this;
		
		this.init = function ( element, config ) {
			var $element = $(element);
			self.$element = $element;
			self.$eventBus = $(document);

			self.$getFidelityInfo = self.$element.find( getFidelityInfo );
			self.$getPrintableCard = self.$element.find( getPrintableCard );
			self.$getPrintableCardSubmit = self.$element.find( getPrintableCardSubmit );
			self.$linkFidelityCardSubmit = self.$element.find( linkFidelityCardSubmit );
			self.$fidelityCardInfoRefreshSubmit = self.$element.find( fidelityCardInfoRefreshSubmit );
			self.$use_fidelity_card_checkbox = self.$element.find( use_fidelity_card_checkbox );
			self.$useFidelityPointsSubmit = self.$element.find( useFidelityPointsSubmit );
			self.$registerFidelityCardSubmit = self.$element.find( registerFidelityCardSubmit );
			self.$checkFidelityCardStatus = self.$element.find( checkFidelityCardStatus );
			self.$deleteFidelityDiscount = self.$element.find( deleteFidelityDiscount );

			self.$getFidelityInfo.on('click', function ( event ) {
				event.preventDefault();
				self.getFidelityInfo();
				return false;
			});
			
			self.$getPrintableCard.on('click', function ( event ) {
				event.preventDefault();
				self.getPrintableCard();
				return false;
			});
			self.$getPrintableCardSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.getPrintableCardSubmit();
				return false;
			});
			self.$linkFidelityCardSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.linkFidelityCardSubmit();
				return false;
			});
			
			self.$fidelityCardInfoRefreshSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.fidelityCardInfoRefreshSubmit();
				return false;
			});

			self.$use_fidelity_card_checkbox.on('click', function ( event ) {
				//event.preventDefault();
				self.use_fidelity_card_checkbox();
				//return false;
			});

			self.$useFidelityPointsSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.useFidelityPointsSubmit();
				return false;
			});
			self.$registerFidelityCardSubmit.on('click', function ( event ) {
				event.preventDefault();
				self.registerFidelityCardSubmit();
				return false;
			});
			self.$checkFidelityCardStatus.on('click', function ( event ) {
				event.preventDefault();
				self.checkFidelityCardStatus();
				return false;
			});
			
			self.$deleteFidelityDiscount.on('click', function ( event ) {
				event.preventDefault();
				self.deleteFidelityDiscount();
				return false;
			});
			
		};
		
		this.getFidelityInfo = function () {
	        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getFidelityInfo'});
	        var cardCode = $('#fidelity-getFidelityInfo-cardCode').val();    
	        $.ajax({
	    		    type: 'post',
	    		    url: url,
	    		    dataType: 'json',
	    		    data: { 'cardCode': cardCode },
	    		    success: function (response) {
	    		        if (response && response.status === "success"){
	    		        	$(".fidelity-getFidelityInfo-response").html(
	    		        	"fidelity card number: " + response.data.code + "<br>" +
	    		        	"available points: " + response.data.usablepoints + "<br>" +
	    		        	"available value: " + response.data.usablevalue + "<br>"+
	    		        	"total points: " + response.data.totalpoints + "<br>" +
	    		        	"total value: " + response.data.totalvalue + "<br>"
	    		        	);
	    		        } else {
	    		        	$(".fidelity-getFidelityInfo-response").html(response.message);
	    	                $(document).trigger('zg-error', [{
	    	                    eventType: 'fidelity-getFidelityInfo',
	    	                    message: (response.message)
	    	                }]);    		        	
	    		        	
	    		        }
	    		    },
	    	        error: function () {
		                $(document).trigger('zg-error', [{
		                    eventType: 'fidelity-getFidelityInfo',
		                    message: ('Ajax request failed.')
		                }]);
	    	        }
	        });
	    };
		
	    this.getPrintableCard = function () {
	        var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getPrintableCard'});
	        var cardCode = $('#fidelity-getPrintableCard-cardCode').val();
	        $.ajax({
	    		    type: 'post',
	    		    url: url,
	    		    dataType: 'json',
	    		    data: { 'cardCode': cardCode },
	    		    success: function (response) {
	    		        if (response && response.data.status === "success"){
	    		        	$(".fidelity-getPrintableCard-response").html(
	    		        			"fidelity card: " + response.data.card +'</br> <img src="'+ response.data.card +'"/>'
	    		        	);
	    		        } else {
	    		        	$(".fidelity-getPrintableCard-response").html('');
	    	                $(document).trigger('zg-error', [{
	    	                    eventType: 'fidelity-getPrintableCard',
	    	                    message: (response.message)
	    	                }]);
	    		        }
	    		    },
	    	        error: function () {
			        	$(".fidelity-getPrintableCard-response").html('');
		                $(document).trigger('zg-error', [{
		                    eventType: 'fidelity-getPrintableCard',
		                    message: ('Request failed.')
		                }]);
	    	        }
	        });
	    }
	    
	    
	    this.getPrintableCardSubmit = function () {
	    	var frameSrc = "/eshop/fidelity/action/printcard/";
	    	var $modal = $('#fidelityPrintCardSubmitModal');

	    	$modal.on('show.bs.modal', function () {
                    $modal.find('iframe').attr("src",frameSrc);	          
	    	});
	    	
	    	$modal.modal('show');

	    }
		
		this.linkFidelityCardSubmit = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'linkCardToAccount'});
			//var url="http://development.zerogrey.com/eshop/fidelity/action/linkCardToAccount/";
			var cardCode = $('#cardCode').val();
			var email=$('#emailFidelity').val();
			var phone=$('#phoneFidelity').val();

			
				$.ajax({
				    type: 'post',
				    url: url,
				    dataType: 'json',
				    data: { 'cardCode': cardCode ,'name':email,'lname':phone},
				    success: function (response) {
				    	console.log(response);
				        if (response && response.status == "success"){
				        	$('#fidelity_link_div').fadeOut('slow');
				        	$('#fidelity_new_div').fadeOut('slow');
				        	$('#fidelity-info-cardCode').html(response.data.info.custbarcode);
				        	$('#fidelity-info-usablePoints').html(response.data.info.usablepoints);
				        	$('#fidelity-info-usableValue').html(response.data.info.usable_currency_value);
				        	$('#fidelity-info-totalPoints').html(response.data.info.totalpoints);
				        	$('#fidelity-info-totalValue').html(response.data.info.total_currency_value);
				        	$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.cardadded']);
				        	$('#fidelity_summary_div').fadeIn('slow');
				        	$('#fidelity_print_div').fadeIn('slow');
				        } else {
				        	$(".fidelity-linkCardToAccount-response").html('');
			                $(document).trigger('zg-error', [{
			                    eventType: 'fidelity-linkCardToAccount',
			                    message: (response.message)
			                }]);    		        	
				        	
				        }
				    },
			        beforeSend: function () {
			        	$(".fidelity-linkCardToAccount-response").html(window.JS_TRANSLATIONS['fidelity.loading']);
			        },
			        error: function () {
			        	$(".fidelity-linkCardToAccount-response").html('');
		                $(document).trigger('zg-error', [{
		                    eventType: 'fidelity-linkCardToAccount',
		                    message: ('Request Failed')
		                }]);
			        }
		    });
	    }
		
		this.fidelityCardInfoRefreshSubmit = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'getFidelityInfo'});
			$.ajax({
			    type: 'post',
			    url: url,
			    dataType: 'json',
			    data: {},
			    success: function (response) {
			        if (response && response.status == "success"){
			        	$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.pointupdated']);
			        	$('#fidelity-info-cardCode').html(response.data.code);
			        	$('#fidelity-info-usablePoints').html(response.data.usablepoints);
			        	$('#fidelity-info-usableValue').html(response.data.usable_currency_value);
			        	$('#fidelity-info-totalPoints').html(response.data.totalpoints);
			        	$('#fidelity-info-totalValue').html(response.data.total_currency_value);
			        } else {
			        	$(".fidelity-linkCardToAccount-response-ok").html('');
		                $(document).trigger('zg-error', [{
		                    eventType: 'fidelity-getFidelityInfo',
		                    message: (response.message)
		                }]);    		        	
			        	
			        }
			    },
		        beforeSend: function () {
		        	$(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.loading']);
		        },
		        error: function () {
		        	$(".fidelity-linkCardToAccount-response-ok").html('');
		            $(document).trigger('zg-error', [{
		                eventType: 'fidelity-linkCardToAccount',
		                message: ('Ajax request failed.')
		            }]);
		        }
			});
		}


		this.use_fidelity_card_checkbox = function () {

			$('#fidelitybox').slideToggle();

		}

		this.useFidelityPointsSubmit = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'useFidelityPoint'});
			var cardCode = $('#useFidelityCard').val();
			var point = $('#useFidelityPoints').val();
			var cartTotal = $('#total_with_shipCost').html();

			        $.ajax({
			        type: 'post',
			        url: url,
			        dataType: 'json',
			        data: { 'cardCode': cardCode ,'point':point,'cartTotal':cartTotal},
			        success: function (response) {
			        $(".fidelity-linkCardToAccount-response-ok").html(response.message);
			            if (response && response.status === "success"){
			            location.reload();
			            } else {
			            $(".fidelity-linkCardToAccount-response-ok").html('');
			            $('#useFidelityPoints').val(response.discountpoints);
			                    $(document).trigger('zg-error', [{
			                        eventType: 'fidelity-getFidelityInfo',
			                        message: (response.message)
			                    }]);    	        
			    	        
			            }
			        },
			        beforeSend: function () {
			            $(".fidelity-linkCardToAccount-response-ok").html(window.JS_TRANSLATIONS['fidelity.creatingpromotion']);
			        },
			        error: function () {
			            	$(".fidelity-linkCardToAccount-response-ok").html('');
			                $(document).trigger('zg-error', [{
			                    eventType: 'fidelity-useFidelityPoint',
			                    message: ('Ajax request failed.')
			                }]);
			            }
			        });
		};
		
		this.checkFidelityCardStatus = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'checkFidelityDiscountInfo'});
			var page=$('#checkFidelity').attr('href'); 
			$.ajax({
			        type: 'post',
			        url: url,
			        dataType: 'json',
			        data: {},
			        success: function (response) {
			            if (response && response.status === "success"){
			            	$(location).attr('href',page);
			            }else{
			            	$(document).trigger('zg-error', [{
		                        eventType: 'fidelity-checkFidelityPoint',
		                        message: (response.message)
		                    }]);    
			            	return false;
			            }
			        },
			        error: function () {
			                $(document).trigger('zg-error', [{
			                    eventType: 'fidelity-checkFidelityPoint',
			                    message: ('Ajax request failed.')
			                }]);
			            }
			        });
			return false;
			
		};
		
		this.deleteFidelityDiscount = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'fidelity', action: 'deleteFidelityDiscount'});
			 
			$.ajax({
			        type: 'post',
			        url: url,
			        dataType: 'json',
			        data: {},
			        success: function (response) {
			            if (response && response.status === "success"){
			            	location.reload();
			            }else{
			            	$(document).trigger('zg-error', [{
		                        eventType: 'fidelity-deleteFidelityDiscount',
		                        message: (response.message)
		                    }]);    
			            	return false;
			            }
			        },
			        error: function () {
			                $(document).trigger('zg-error', [{
			                    eventType: 'fidelity-deleteFidelityDiscount',
			                    message: ('Ajax request failed.')
			                }]);
			            }
			        });	
		};
		
		this.registerFidelityCardSubmit = function () {
			alert('todo...');	
		};
		
		this.init( element, config );
	}
	
	$.fn.zg_fidelity = function ( config ) {
		return this.each( function () {
			var $this = $(this);
			var data = $this.data('zgFidelity');
			if (!data) {
				$this.data('zgFidelity', (data = new ZG_Fidelity( this, config )) );
			}
		} );
	};

	$(function () {
		$('body').find(toggle).zg_fidelity( window.ZG_CONFIG );
	});
	
})( jQuery );