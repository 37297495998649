(function () {
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;

	/**
	 *
	 * @returns {boolean}
	 */
	root.isGoogleMapsAvailable = function () {
		return !!( root.google && root.google.maps && root.google.maps.Geocoder );
	};

}.call( this ));
