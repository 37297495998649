(function($) {

	"use strict";

	var toggle = '[data-zg-role="zg_send_email"]';

	var sentEmailForm = '[data-zg-role="zg_send_email_form"]';
	var emailSubmitBtn = '[data-zg-role="zg_send_email_submit"]';

	var ZG_SendEmail = function(element, config) {

		var self = this;
		this.init = function(element, config) {
			var $element = $(element);
			self.$element = $element;
			self.$eventBus = $(document);
			self.config   = $.extend( {}, config, self.$element.data('config') || {} ) ;
			self.$zgSendEmailForm = self.$element.find(sentEmailForm);
			self.$zgSendEmailSubmit = self.$element.find(emailSubmitBtn);

			self.$zgSendEmailForm.on('submit', function( e ) {
				e.preventDefault();
				self.zgSendEmailFormSubmit();
			});

		};

		this.zgSendEmailFormSubmit = function() {
			if (self.$zgSendEmailForm.data('zgValidator')) {
				if (!self.$zgSendEmailForm.data('zgValidator').validate()) {
					return false;
				}
			}
			var url = window.SGL_JS_WEBROOT + '/eshop/eshop/';
			/*var req_data = {
				action : "sendFormEmail",
				data : "action=sendFormEmail&"+self.$zgSendEmailForm.serialize()
			};*/
			$.ajax({
						type : 'post',
						url : url,
						dataType : 'json',
						data : "action=sendFormEmail&"+self.$zgSendEmailForm.serialize(),
						success : function(response) {
							console.log(response);
							if (response && response.status == "success") {
								$(document).trigger('zg-notification', [ {
									eventType : 'sendFormEmail',
									message : response.message
								} ]);

								self.$zgSendEmailForm.find("input, textarea").each(function(){
								    $(this).val("");
								});
							} else {
								$(document).trigger('zg-error', [ {
									eventType : 'sendFormEmail',
									message : response.message
								} ]);
							}
						},
						error : function() {
							$(document).trigger('zg-error', [ {
								eventType : 'sendFormEmail',
								message : ('Request Failed')
							} ]);
						}
					});
		};

		this.init(element, config);
	};

	$.fn.zg_sendEmail = function(config) {
		return this.each(function() {
			var $this = $(this);
			var data = $this.data('zgSendEmail');

			if (!data) {
				$this.data('zgSendEmail', (data = new ZG_SendEmail(this, config)));
			}
		});
	};

	$(function() {
		$(toggle).zg_sendEmail(window.ZG_CONFIG);
	});

})(jQuery);
