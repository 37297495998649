/* ==================================================================================================================
 * setUserMarker
 *
 *    Create a table with all the stores with missing geolocation.
 *    Creates a csv file to be imported in the CMS.
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ================================================================================================================== */

(function () {
	/* global _, DEBUG, google, handlebarsTemplates, isGoogleMapsAvailable, JS_TRANSLATIONS, SGL_JS_ISLOGGED */
	'use strict';

	// Establish the root object (`window` in the browser)
	var root = this;


	// CLASS DEFINITION
	// ================

	var SetUserMarker = function ( storeLocator ) {
		this.storeLocator = storeLocator;

		this.options = _.extend( {}, SetUserMarker.DEFAULTS, root.ZG_CONFIG, storeLocator.options || {} );

		/** @type {null|google.maps.Marker} */
		this.marker = null;

		/** @type {null|number|string} */
		this.positionWatcherId = null;

		this.userPosition = null;

		/** @type {null|string} */
		this.type = null;

		this.__init();
		this.__setEventHandlers();
	};


	SetUserMarker.DEFAULTS = {
		userMarkerDialogContainer: '[data-zg-role="sl-user-marker-container"]',
		userMarkerDialogTemplate:  'storelocator-user-marker-dialog',
		userMarkerSelector:        '[data-zg-role="sl-show-user-marker"]',

		// image to use as the user marker
		/** @type {null|string} */
		userMarkerIcon: null,

		// select user address.
		userMarkerAddressContainer: '[data-zg-role="address-list"]',
		userMarkerAddressInput:     '[data-zg-role="sl-user-marker-address-input"]',
		userMarkerSearchInput:      '[data-zg-role="sl-user-marker-search-input"]'
	};


	SetUserMarker.prototype.getUserPosition = function () {
		return this.userPosition;
	};


	/**
	 *
	 */
	SetUserMarker.prototype.hide = function () {
		if ( this.marker ) {
			this.marker.setMap( null );
		}
	};


	/**
	 *
	 */
	SetUserMarker.prototype.show = function () {
		if ( this.marker && this.storeLocator && this.storeLocator.map ) {
			this.marker.setMap( this.storeLocator.map );
		}
	};

	// -----------------------------------------------------------------------------------------------------------------

	/**
	 *
	 * @private
	 */
	SetUserMarker.prototype.__init = function () {
		var data, $dialogContainer;

		data = {
			geolocation: ( "geolocation" in navigator ),
			address:     SGL_JS_ISLOGGED,
			search:      true
		};

		$dialogContainer = $( this.options.userMarkerDialogContainer );
		$dialogContainer
			.html( handlebarsTemplates.render( this.options.userMarkerDialogTemplate, data ) )
			.removeClass( 'hidden' );

		// request user addresses
		if ( data.address ) {
			$dialogContainer
				.find( this.options.userMarkerAddressContainer )
				.zgAddressList();
		}
	};


	/**
	 *
	 * @param {string} address
	 * @private
	 */
	SetUserMarker.prototype.__setByAddress = function ( address ) {
		var that;

		if ( _.isString( address ) && this.storeLocator.map && isGoogleMapsAvailable() ) {
			that = this;

			if ( !this.storeLocator.geocoder ) {
				this.storeLocator.geocoder = new google.maps.Geocoder();
			}

			this.storeLocator.geocoder.geocode( { 'address': address }, function ( results, status ) {
				if ( status === google.maps.GeocoderStatus.OK ) {
					that.storeLocator.centerMap.byLocationObject( results[0].geometry.location );
					that.__setPosition( results[0].geometry.location );
				} else {

					$( document ).trigger( 'zg-error', [{
						eventType: 'StoreLocator.userMarker.__setByAddress',
						message:   JS_TRANSLATIONS['storeLocator.error.invalidAddress'] + '<br>' + address
					}] );
				}
			} );

		} else if ( DEBUG ) {

			$( document ).trigger( 'zg-error', [{
				eventType: 'StoreLocator.userMarker.__setByAddress',
				message:   JS_TRANSLATIONS['storeLocator.error.invalidAddress'] + '<br>' + address
			}] );

		}
	};


	/**
	 *
	 * @private
	 */
	SetUserMarker.prototype.__setByGeolocation = function () {
		var center, location, that;

		if ( "geolocation" in navigator ) {
			center = true;
			that   = this;

			// destroy the previous positionWatcher
			if ( !_.isNull( this.positionWatcherId ) ) {
				navigator.geolocation.clearWatch( this.positionWatcherId );
				this.positionWatcherId = null;
			}

			//this.positionWatcherId = navigator.geolocation.watchPosition(
			navigator.geolocation.getCurrentPosition(
				// success
				function ( position ) {
					if ( that.type === 'geolocation' ) {
						if ( position && position.coords ) {

							location = new google.maps.LatLng( position.coords.latitude, position.coords.longitude );

							if ( center ) {
								center = false;
								that.storeLocator.centerMap.byLocationObject( location );
							}

							that.__setPosition( location );
						}
					}
				},
				// error
				function ( error ) {
					if ( DEBUG ) {
						console.log( 'StoreLocator.userMarker.__setByGeolocation', error );
					}

					$( document ).trigger( 'zg-error', [{
						eventType: 'StoreLocator.userMarker.__setByGeolocation',
						message:   JS_TRANSLATIONS['storeLocator.error.geolocationRequired']
					}] );
				},
				// options
				{}
			);

		} else {

			$( document ).trigger( 'zg-error', [{
				eventType: 'StoreLocator.userMarker.__setByGeolocation',
				message:   JS_TRANSLATIONS['storeLocator.error.geolocationRequired']
			}] );

		}
	};


	/**
	 *
	 * @private
	 */
	SetUserMarker.prototype.__setEventHandlers = function () {
		var that = this;

		// create user marker
		$( document ).on( 'click.zg.storeLocator', this.options.userMarkerSelector, function () {
			that.__setup( $( this ).data( 'value' ) );
		} );

		// address selector
		$( document ).on( 'change.zg.storeLocator', this.options.userMarkerAddressInput, function () {
			if ( that.type === 'address' ) {
				that.__setByAddress( $( this ).find( 'option:selected' ).text() );
			}
		} );

		// search field
		$( document ).on( 'change.zg.storeLocator', this.options.userMarkerAddressInput, function () {
			if ( that.type === 'search' ) {
				that.__setByAddress( $( this ).val() );
			}
		} );
	};


	/**
	 *
	 * @param {Object=} position
	 * @private
	 */
	SetUserMarker.prototype.__setPosition = function ( position ) {
		var markerData;

		if ( position ) {
			this.userPosition = position;

			// update the stores distance info
			this.storeLocator.calculateStoresDistance( { lat: position.lat(), lng: position.lng() } );

			// setup the marker
			if ( this.storeLocator.map && isGoogleMapsAvailable() ) {

				if ( this.marker ) {

					this.marker.setMap( this.storeLocator.map );
					this.marker.setPosition( position );

				} else {
					// create marker options
					markerData = {
						map:         this.storeLocator.map,
						position:    position,
						zoomOnClick: true,
						flat:        true//,
						//title:       "",
					};

					if ( this.options.userMarkerIcon ) {
						markerData.icon = this.options.userMarkerIcon;
					}

					this.marker = new google.maps.Marker( markerData );
				}

			} else if ( DEBUG ) {

				console.log( "StoreLocator.userMarker.__setPosition - FAILED" );

			}
		} else {
			// reset the stores distance information
			this.storeLocator.calculateStoresDistance( null );
		}
	};


	/**
	 *
	 * @param {!string} type
	 * @private
	 */
	SetUserMarker.prototype.__setup = function ( type ) {
		var address;

		if ( this.storeLocator.map ) {
			switch ( type ) {
				case 'geolocation':
					this.__setType( type );

					this.__setByGeolocation();

					break;

				case 'address':
					this.__setType( type );

					address = $( this.options.userMarkerAddressInput + ' option:selected' ).text();
					this.__setByAddress( address );

					break;

				case 'search':
					this.__setType( type );

					address = $( this.options.userMarkerSearchInput ).val();
					this.__setByAddress( address );

					break;

				case 'reset':
					this.__setType( null );
					this.__setPosition( null );
					this.hide();
					break;

				default:
					$( document ).trigger(
						'zg-error', [{
							eventType: 'StoreLocator - userMarker.createNew',
							message:   JS_TRANSLATIONS.genericErrorMsg
						}]
					);
					break;
			}
		}
	};


	/**
	 *
	 * @param {string=} type
	 * @private
	 */
	SetUserMarker.prototype.__setType = function ( type ) {
		this.type = type;

		$( this.options.userMarkerSelector )
			.removeClass( 'active' )
			.filter( '[data-value="' + type + '"]' )
			.addClass( 'active' );

		// destroy the positionWatcher
		if ( type !== 'geolocation' && !_.isNull( this.positionWatcherId ) ) {
			navigator.geolocation.clearWatch( this.positionWatcherId );
			this.positionWatcherId = null;
		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorSetUserMarker = SetUserMarker;

}.call( this ));
