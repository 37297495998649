/* ============================================================
 * ADDRESS
 *
 * Toggle password info in guest checkout (registration)
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */


(function ( $ ) { /* global _, DEBUG */
	"use strict";

	var selector             = '[data-zg-role="registration-type-selector"]',
		regType              = '[name="registration_type"]',
		infoContainer        = 'data-registration-type-info',
		passwordInput        = '[data-zg-role="password"]',
		passwordInputConfirm = '[data-zg-role="password-confirm"]';


	function initToggle () {
		$( selector + ':checked' ).each( function () {
			toggleRegistrationType( this );
		} );
	}


	function toggleRegistrationType ( item ) {

		var $item        = $( item ),
			$regType     = $( regType ),
			$passInput   = $( passwordInput ),
			$passConfirm = $( passwordInputConfirm ),
			id,
			type         = $item.val();

		$( '[' + infoContainer + ']' ).hide();
		$( '[' + infoContainer + '="' + type + '"]' ).removeClass( 'hidden' ).show();

		if ( type === 'Register' ) {

			id = $passInput.prop('id') || _.uniqueId( 'pass_' );

			$passInput
				.prop( 'id', id )
				.prop( 'disabled', false )
				.prop( 'required', true );

			$passConfirm
				.prop( 'required', true )
				.prop( 'disabled', false )
				.prop( 'equalto', id );

		} else if ( type === 'Guest' ) {

			$passInput
				.removeClass( 'required' )
				.prop( 'required', false )
				.prop( 'disabled', true );

			$passConfirm
				.removeClass( 'required' )
				.prop( 'required', false )
				.prop( 'equalto', false )
				.prop( 'disabled', true );

		} else if ( DEBUG ) {
			console.error( 'registrationTypeUpdate - ERROR: invalid registration type' );
		}

		$regType.val( type );
	}


	$( function () {
		$( document ).on( 'change.zg.address', selector, function () {
			toggleRegistrationType( this );
		} );

		// address form added to the page
		// shouldn't be necessary, but hey, just in case we use it like this in the future
		$( document ).on( 'zg.addressForm.ready', initToggle );

		// start script on page load
		initToggle();
	} );

})( jQuery );
