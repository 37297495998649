(function ( $ ) {

	"use strict";

	var toggle 					= '[data-zg-role="giftcard"]';
	var getGiftCardInfo 		= '[data-zg-role="get-giftcard-info"]';
	var use_gift_card_checkbox 	= '[data-zg-role="use-gift-card-checkbox"]';
	var use_gift_card_btn		= '[data-zg-role="use-giftcard-btn"]';
	var delete_giftcard_btn		= '[data-zg-role="delete-giftcard-btn"]';
	
	
	var ZG_Giftcard = function ( element, config ) {
		var self = this;
		
		this.init = function ( element, config ) { 
			var $element 	= $(element);
			self.$element 	= $element;
			self.$eventBus 	= $(document);

			self.$getGiftCardInfo 			= self.$element.find( getGiftCardInfo );
			self.$use_gift_card_checkbox 	= self.$element.find( use_gift_card_checkbox );
			self.$use_gift_card_btn			= self.$element.find( use_gift_card_btn );
			self.$delete_giftcard_btn		= self.$element.find( delete_giftcard_btn );
			

			self.$getGiftCardInfo.on('click', function ( event ) {
				event.preventDefault();
				self.getGiftCardInfo();
				return false;
			});
			
			self.$use_gift_card_checkbox.on('click', function ( event ) {
				event.preventDefault();
				self.use_gift_card_checkbox();
				return false;
			});
			
			self.$use_gift_card_btn.on('click', function ( event ) {
				event.preventDefault();
				self.use_gift_card();
				return false;
			});
			
			self.$delete_giftcard_btn.on('click', function ( event ) {
				event.preventDefault();
				//console.log($(this).data('zg-value'));
				//console.log($('#gift-info-response').data('zg-value'));
				self.delete_gift_card($(this).data('zg-value'));
				return false;
			});
			
			
		};		
		
		this.getGiftCardInfo = function () {
			var url = window.makeUrl({module: 'eshop', manager: 'giftcard', action: 'getGiftCardInfo'});
	        var cardCode =  $('#giftCardCode').val();  
	        $.ajax({
	    		    type: 'post',
	    		    url: url,
	    		    dataType: 'json',
	    		    data: { 'cardCode': cardCode },
	    		    success: function (response) {	    		    	
	    		        if (response && response.status === "success"){
	    		        	$('#gift-info-response').removeClass("hidden");
	    		        	$(".gift-checkGiftCard-response").html('');
	    		        	$('#giftinfo-cardCode').html(cardCode);
	    		        	$('#gift-info-usableBalence').html(response.data.saldo+ ' ' +response.data.currency);
	    		        	$('#cardUseVal').attr("maximum",response.data.saldo);
	    		        	//$('#gift-info-startDate').html(response.data.startDate);
	    		        	//$('#gift-info-endDate').html(response.data.endDate);
	    		        } else {
	    		        	$(".gift-checkGiftCard-response").html(response.message);
	    		        	$('#gift-info-response').addClass("hidden");
	    	                $(document).trigger('zg-error', [{eventType: 'giftcard-getFidelityInfo',message: (response.message)}]);   
	    		        }
	    		    },
	    		    beforeSend: function () {
			            $(".gift-checkGiftCard-response").html(window.JS_TRANSLATIONS['Loading']);
			        },
	    	        error: function () {
		                $(document).trigger('zg-error', [{ eventType: 'fidelity-getFidelityInfo',message: ('Ajax request failed.')}]);
	    	        }
	        });
	    };
	    
	    this.use_gift_card_checkbox = function () {
			if($('#giftbox').css('display') == 'none'){
				$('#giftbox').slideDown();
			}else {
				$('#giftbox').slideUp();
			}
		};
		
		this.use_gift_card = function () {
			var cardCode 	=  $('#giftCardCode').val();  
			var cardUseVal  =  parseFloat($('#cardUseVal').val()); 			
			var cardUseMax 	=  parseFloat($('#cardUseVal').attr("maximum"));
			var cartTotal 	=  parseFloat($('#total_with_shipCost').html()); 
			if(cardUseVal <= 0){
				$(document).trigger('zg-error', [{eventType: 'giftcard-getFidelityInfo',message: (window.JS_TRANSLATIONS['external.giftcard.invalid.usage1'])}]);
			} else if(cardUseVal > cardUseMax){
				$(document).trigger('zg-error', [{eventType: 'giftcard-getFidelityInfo',message: (window.JS_TRANSLATIONS['external.giftcard.invalid.bal'])}]);
			} else if(cardUseVal > cartTotal) {
				$(document).trigger('zg-error', [{eventType: 'giftcard-getFidelityInfo',message: (window.JS_TRANSLATIONS['external.giftcard.invalid.usage'])}]);
			} else{				
				var url = window.makeUrl({module: 'eshop', manager: 'giftcard', action: 'useGiftCardInfo'});				
				$.ajax({
	    		    type: 'post',
	    		    url: url,
	    		    dataType: 'json',
	    		    data: { 'cardCode': cardCode , 'cardUseVal':cardUseVal},
	    		    success: function (response) {	    		    	
	    		        if (response && response.status === "success"){
	    		        	location.reload();
	    		        } else {
	    		        	$(".gift-checkGiftCard-response").html(response.message);	    		        	
	    	                $(document).trigger('zg-error', [{ eventType: 'giftcard-getFidelityInfo', message: (response.message)}]);    	       
	    		        }
	    		    },
	    		    beforeSend: function () {
			            $(".gift-checkGiftCard-response").html(window.JS_TRANSLATIONS['Loading']);
			        },
	    	        error: function () {
		                $(document).trigger('zg-error', [{eventType: 'fidelity-getFidelityInfo', message: ('Ajax request failed.')}]);
	    	        }
				});
			}
		};		
		
		this.delete_gift_card = function (card) {
			var url = window.makeUrl({module: 'eshop', manager: 'giftcard', action: 'deleteGiftCardUsage'});				
			$.ajax({
    		    type: 'post',
    		    url: url,
    		    dataType: 'json',
    		    data: { 'cardCode': card}, 
    		    success: function (response) {	    		    	
    		        if (response && response.status === "success"){
    		        	location.reload();
    		        } else {
    		        	$(".gift-delete-msg").html(response.message);	    		        	
    	                $(document).trigger('zg-error', [{ eventType: 'deleteGiftCardUsage', message: (response.message)}]);    	       
    		        }
    		    },
    		    beforeSend: function () {
		            $(".gift-delete-msg").html(window.JS_TRANSLATIONS['Loading']);
		        },
    	        error: function () {
	                $(document).trigger('zg-error', [{eventType: 'deleteGiftCardUsage', message: ('Ajax request failed.')}]);
    	        }
			});
		};
		
		this.init( element, config );
	}
	
	$.fn.zg_giftcard = function ( config ) {
		return this.each( function () {
			var $this = $(this);
			var data = $this.data('ZG_Giftcard');
			if (!data) {
				$this.data('ZG_Giftcard', (data = new ZG_Giftcard( this, config )) );
			}
		} );
	};

	$(function () {
		$('body').find(toggle).zg_giftcard( window.ZG_CONFIG );
	});
	
})( jQuery );