/* ============================================================
 * zg-wishlist.js
 * ============================================================
 *
 * Add a product to the wishlist.
 *
 * You can add data-target="#selector" to link the button with
 * a specific product (selector would be the id of an element
 * with data-zg-role="product").
 *
 * <a href="#" data-zg-role="add-to-wishlist" data-target="#product_unique_id">...</a>
 * <div id="product_unique_id" >
 *
 * If data-target is not present the button will just try to find
 * a data-zg-role="product" within its parents.
 *
 * ============================================================ */



(function ( $ ) { "use strict";

	var toggle         = '[data-zg-role="add-to-wishlist"]';
	var toggle_product = '[data-zg-role="product"]';

	/**
	 * Main function
	 *
	 * @param {Element}  element
	 * @param {Object}   config
	 */
	var ZG_addToWishlist = function ( element, config ) {
		var self = this;

		/**
		 * Initialise the module
		 *
		 * @param {Element} element
		 * @param {Object}  config
		 */
		this.init = function ( element, config ) {
			var $element      = $(element);
			self.$element     = $element;
			self.config       = $.extend( {}, config, self.$element.data('config') || {});
			self.$eventBus    = $(document);

			self.$target      = $( $element.data("target") );
			if ( !self.$target || !self.$target.length ) {
				self.$target   = $element.closest( toggle_product );
			}

			if ( !self.$target || !self.$target.length ) {
				// the element doesn't have a target. we hide it and stop the script
				$element.hide();
				return;
			}

			self.product_id   = self.$target.data('id');
			self.inWishlist   = false;

			this.setEventHandlers();

			self.updateButtonInfo();
		};


		this.addToWishlist = function () {
			if ( !window.SGL_JS_ISLOGGED ) {
				window.location.href =  window.makeUrl({module: 'eshop', manager: 'wishlist', action: 'addproduct'}) + "pid-wishlist/" + self.product_id;
				return;
			}

			var request = {
				product_id: self.product_id
				//, sku: (self.sku || 0)
			};

			var url = window.makeUrl({module: 'eshop', action: 'addProductToWishlist'});
			$.getJSON( url, request, function ( response ) {
				if ( 'success' === response.status ) {
					self.$eventBus.trigger('zg-notification', [{
						eventType: 'added-to-wishlist',
						message: window.JS_TRANSLATIONS.product_has_been_added_to_the_wishlist || "Product has been added to wishlist"
					}]);

					self.inWishlist = true;
					self.updateButton();
				} else {
					self.$eventBus.trigger('zg-error', [{
						eventType: 'addedToWishlist',
						message: response.message
					}]);
				}

				$(document).trigger('zg.product.addedToWishlist', [request]);
			});
		};


		/**
		 *
		 */
		this.setEventHandlers = function () {
			// Add to cart (button)
			self.$element.on('click.zg.wishlist.addToWishlist', function ( e ) {
				self.addToWishlist();
				e.preventDefault();
			});

			self.$eventBus.on("zg.getProductListInfo.success", function ( e, type ) {
				if ( type === 'wishlist' ) {
					self.updateButtonInfo();
				}
			});
		};


		this.updateButtonInfo = function () {
			var list = window.getLastWishlist ? window.getLastWishlist() : null,
				inWishlist = false,
				i;

			if ( list && list.products_in_wishlist ) {
				for ( i = 0; i < list.products_in_wishlist.length && !inWishlist; i++ ) {
					if ( self.targetData && self.targetData.product_id === list.products_in_wishlist[i].product_id ) {
						inWishlist = true;
					}
				}
			}

			self.inWishlist = inWishlist;
			self.updateButton();
		};


		this.updateButton = function () {
			if ( self.inWishlist ) {
				self.$element
						.removeClass( self.config.wishlist_btn_class_default )
						.addClass( self.config.wishlist_btn_class_added );
			} else {
				self.$element
						.removeClass(self.config.wishlist_btn_class_added)
						.addClass(self.config.wishlist_btn_class_default);
			}
		};

		this.init(element, config);
	};

	$.fn.zg_add_to_wishlist = function ( config ) {
		var defaults = {
			"wishlist_btn_class_default" : "",  // class the button will have by default
			"wishlist_btn_class_added"   : "added"  // class the button will have when its target is in the wishlist
		};
		config = $.extend({}, defaults, config || {});

		return this.each( function () {
			var $this = $(this);
			var data = $this.data('zgAddToWishlist');
			if (!data) {
				$this.data('zgAddToWishlist', (data = new ZG_addToWishlist(this, config)) );
			}

			//data.addToWishlist();
		} );
	};

	$(document).on('zg.getProductInfo.productCreated', function ( e, $item, options, productInfo ) {
		$item.find( toggle ).zg_add_to_wishlist( options );
	});

})( jQuery );