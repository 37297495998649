/* ============================================================
 * zg-gallery.js
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $ ) { /* global _, handlebarsTemplates, DEBUG */
	"use strict";

	// ZOOM DEFINITION
	// ===============

	var Zoom = {
		// all the following functions are here because of the wonderful zoom plugin we are using.
		// Its faster to destroy the zoom and create it again than just use it's update function  :'(
		// TODO: change the freaking plugin

		/**
		 *
		 */
		destroy: function ( $zoom ) {
			if ( !$zoom ) {
				if ( DEBUG ) {
					console.log( "zoomDestroy - invalid arguments" );
				}
				return;
			}

			$zoom.data( 'zoom-active', false );

			Zoom.stop( $zoom );
		},


		/**
		 *
		 */
		init: function ( $zoom ) {
			if ( !$zoom ) {
				if ( DEBUG ) {
					console.log( "zoomInit - invalid arguments" );
				}
				return;
			}

			$zoom.data( 'zoom-active', true );

			window.setTimeout( function () {
				Zoom.start( $zoom );
			}, 100 );
		},


		/**
		 *
		 */
		start: function ( $zoom ) {
			if ( !$zoom ) {
				if ( DEBUG ) {
					console.log( "zoomStart - invalid arguments" );
				}
				return;
			}

			// don't start if the element is not active, or not visible, or has no zoom-image
			if ( !$zoom.is( ':visible' ) || !$zoom.data( 'zoom-image' ) ) {
				Zoom.stop( $zoom );
				console.log("stop"+$zoom.data( 'zoom-active' ) +" - "+$zoom.is( ':visible' )+" - "+$zoom.data( 'zoom-image' ));
				return;
			}

			var data = $zoom.data( 'elevateZoom' );

			if ( data ) {
				Zoom.stop( $zoom, true );
				return;
			}

			$zoom.elevateZoom( {
				zoomType: 'inner',
				cursor:   'crosshair'
			} );
		},


		/**
		 *
		 */
		stop: function ( $zoom, restart, iteration ) {
			if ( !$zoom ) {
				if ( DEBUG ) {
					console.log( "zoomStop - invalid arguments" );
				}
				return;
			}

			var data = $zoom.data( 'elevateZoom' );

			if ( data ) {
				if ( !data.zoomContainer ) {
					if ( !iteration || iteration < 25 ) {
						window.setTimeout( function () {
							Zoom.stop( $zoom, restart, ((iteration || 0) + 1) );
						}, 100 );

						return;
					} else if ( DEBUG ) {
						console.log( "zoomStop - not stopped", $zoom );
					}
				} else {
					data.zoomContainer.remove();
				}

				$zoom.off( ".elevatezoom" );
				$zoom.data( 'elevateZoom', null );
			}

			if ( restart ) {
				Zoom.start( $zoom );
			}
		},


		/**
		 *
		 */
		update: function ( $zoom, image, zoomImage ) {
			if ( !$zoom ) {
				if ( DEBUG ) {
					console.log( "zoomUpdate" );
				}
				return;
			}

			Zoom.stop( $zoom );

			// update images
			if ( image ) {
				$zoom.attr( "src", image );
			}

			$zoom.data( "zoom-image", zoomImage || null );

			// only restart the zoom if a zoomImage is set
			if ( zoomImage ) {
				Zoom.start( $zoom );
			} else {
				Zoom.stop( $zoom );
			}
		}
	};


	// -------------------------------------------------------------------------


	var selector = '[data-zg-role="gallery"]';

	// GALLERY CLASS DEFINITION
	// ========================

	var Gallery = function ( element, options ) {
		this.$element = $( element );
		this.options  = options;
		this.isActive = options.autoStart;

		this.gallery = {};

		this.$warning = $( this.options.warning );

		this.options.namespace = this.options.namespace || ("" + new Date().getTime());

		if ( this.options.zoom ) {
			this.readyZoom();
		}

		this.setEventHandlers();
	};


	Gallery.DEFAULTS = {
		autoStart:  true,
		item:       '[data-image]',
		warning:    '[data-zg-role="image-overlay"]',
		zoom:       '[data-zg-role="zoom"]',
		zoomToogle: '[data-zg-role="zoom-toggle"]',

		imageMedium: 'medium',

		galleryTemplate: 'gallery-item',

		limit: 0, // maximun number of elements to be created in the gallery. 0 or false means no limit

		namespace: null
	};


	/**
	 *
	 */
	Gallery.prototype.deactivate = function () {
		this.isActive = false;

		// remove the "active" class from the gallery elements
		this.$element.find( this.options.item ).removeClass( "active" );
	};


	/**
	 *
	 */
	Gallery.prototype.preloadImage = function ( img ) {
		if ( _.isString( img ) ) {
			(new Image()).src = img;
		}
	};


	/**
	 *
	 */
	Gallery.prototype.readyZoom = function () {
		$( this.options.zoom ).each( function () {
			var $zoom = $( this );

			if ( $zoom.data( 'auto-start' ) ) {
				Zoom.init( $zoom );
			}
		} );
	};


	/**
	 *
	 */
	Gallery.prototype.renderGallery = function ( gallery ) {
		if ( !gallery ) {
			gallery = this.gallery;
			this.$warning.removeClass( "hidden" ).fadeIn();
		} else {
			this.$warning.hide();
		}

		if ( !gallery ) {
			return;
		}

		this.$element.empty();

		var iteration = 1;

		for ( var i = 0; i < gallery.length; i++ ) {
			if ( this.options.limit && iteration > this.options.limit ) {
				break;
			}

			var $item = $( handlebarsTemplates.render( this.options.galleryTemplate, gallery[i] ) );

			this.$element.append( $item );

			iteration++;
		}

		this.readyZoom();

		if ( this.isActive ) {
			this.$element.find( this.options.item ).first().click();
		}

		$( document ).trigger( 'zg.gallery.rendered', [this.$element, gallery] );
	};


	/**
	 *
	 */
	Gallery.prototype.setEventHandlers = function () {
		var that = this;

		//
		$( document ).on( 'zg.product.optionSelected.' + this.options.namespace, function ( e, option ) {
			if ( option.hasImage ) {
				that.renderGallery( option.gallery );
			}
		} );

		//
		this.$element.on( 'click.zg.gallery', this.options.item, function ( e ) {
			var $item = $( this );

			$( selector ).not( that.$element ).each( function () {
				var $gallery = $( this );

				if (
						$gallery.data( "zoom" ) === that.$element.data( "zoom" ) ||
						$gallery.data( "zoom" ) === that.$element.zoom
				) {
					// deactivate any galleries using the same zoom as the current one
					$gallery.zg_gallery( "deactivate" );
				}
			} );

			// remove the "active" class from the gallery elements
			that.$element.find( that.options.item ).removeClass( "active" );
			// add the "active" class to the current one
			$item.addClass( "active" );

			$( that.options.zoom ).each( function () {
				var image     = $item.data( "image" ),
					zoomImage = $item.data( "zoom-image" );

				that.preloadImage( zoomImage );

				Zoom.update( $( this ), image, zoomImage );
			} );

			e.preventDefault();
		} );

		// ---------------------------------------------------------------------

		var $modal = $( '.modal' );
		$modal.off( '.zg.Zoom.' + this.options.namespace );

		// stop outside zooms
		$modal.on( 'show.bs.modal.zg.Zoom.' + this.options.namespace, function () {
			var $parent = $( this ),
				$zoom;

			$( that.options.zoom ).each( function () {
				$zoom = $( this );
				if ( !$.contains( $parent, $zoom ) ) {
					Zoom.stop( $zoom );
				}
			} );
		} );

		// start inside zooms
		$modal.on( 'shown.bs.modal.zg.Zoom.' + this.options.namespace, function () {
			var $parent = $( this ),
				$zoom;

			$( that.options.zoom ).each( function () {
				$zoom = $( this );
				if ( $.contains( $parent, $zoom ) ) {
					Zoom.start( $zoom );
				}
			} );
		} );

		// stop inside zooms
		$modal.on( 'hide.bs.modal.zg.Zoom.' + this.options.namespace, function () {
			var $parent = $( this ),
				$zoom;

			$( that.options.zoom ).each( function () {
				$zoom = $( this );
				if ( $.contains( $parent, $zoom ) ) {
					Zoom.stop( $zoom );
				}
			} );
		} );

		// start outside zooms
		$modal.on( 'hidden.bs.modal.zg.Zoom.' + this.options.namespace, function () {
			var $parent = $( this ),
				$zoom;

			$( that.options.zoom ).each( function () {
				$zoom = $( this );
				if ( !$.contains( $parent, $zoom ) ) {
					Zoom.start( $zoom );
				}
			} );
		} );
	};


	/**
	 *
	 */
	Gallery.prototype.updateGallery = function ( gallery ) {
		// var that = this;

		this.gallery = gallery;

		this.renderGallery( gallery );

		// $( this.options.zoom ).each( function () {
		// 	Zoom.update( $(this) );
		// });
	};


	// GALLERY PLUGIN DEFINITION
	// =========================

	function Plugin ( option, gallery ) {
		return this.each( function () {
			var $this   = $( this ),
				data    = $this.data( 'zg.gallery' ),
				options = $.extend( {}, Gallery.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			gallery = gallery || options.gallery || null;

			if ( !data ) {
				$this.data( 'zg.gallery', (data = new Gallery( this, options )) );
			}

			if ( option && option === "deactivate" ) {
				data.deactivate();
			}

			if ( gallery ) {
				data.updateGallery( gallery );
			}
		} );
	}

	$.fn.zg_gallery             = Plugin;
	$.fn.zg_gallery.Constructor = Gallery;


	// GALLERY DATA-API
	// ================

	// $(document).on('zg.getProductInfo.productCreated', function (e, element, options, product) {
	//     Plugin.call( $(element), options, ( product.processedImages || product.images || null ) );
	// });

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

	// -------------------------------------------------------------------------

	$( window ).on( 'orientationchange.zg.zoom', function () {
		$( Gallery.DEFAULTS.zoom ).each( function () {
			Zoom.start( $( this ) );
		} );
	} );

	var zoomTimeoutID;
	$( window ).on( 'resize.zg.zoom', function () {
		if ( zoomTimeoutID ) {
			window.clearTimeout( zoomTimeoutID );
		}

		zoomTimeoutID = window.setTimeout( function () {
			$( Gallery.DEFAULTS.zoom ).each( function () {
				Zoom.start( $( this ) );
			} );
		}, 200 );
	} );

	$( document ).on( 'zg.zoom.init', function ( e, elem ) {
		Zoom.init( $( elem ) );
	} );

	$( document ).on( 'zg.zoom.destroy', function ( e, elem ) {
		Zoom.destroy( $( elem ) );
	} );
}( jQuery ));
