/* ============================================================
 * zg-address.js
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 *
 * ============================================================ */


(function ( $ ) {
	"use strict";

	$.validator.addMethod(
		"requireIfMoreThanOneOptionAvailable",
		function ( value, element ) {
			var valid = true;

			if ( $( element ).find( 'option' ).length > 1 && value === "" ) {
				valid = false;
			}

			return valid;
		},
		$.validator.format( "Please select state" )
	);

	$.validator.addClassRules( "require-if-more-than-one-option-available", {
		requireIfMoreThanOneOptionAvailable: true
	} );
})( jQuery );


(function ( $ ) {
	"use strict";

	var toggle = '[data-zg-validate="to-validate"]';

	var ZG_Validate = function ( element, config ) {
		var self = this;

		this.init = function ( element, config ) {
			self.$element = $( element );
			self.$eventBus = $( document );

			self.$form = self.$element.is( 'form' ) ? self.$element : self.$element.closest( 'form' );

			self.config = $.extend(
				{},
				config,
				{
					ignore:         ".ignore", // so it doesn't ignore hidden input fields, but it will ignore anything
											   // with the "ignore" class
					rules:          {
						'hiddenRecaptcha': {
							required: function () {
								if ( grecaptcha.getResponse() == '' ) {
									return true;
								} else {
									return false;
								}
							}
						}
					},
					ajax_form:      false,
					errorElement:   "span",
					errorClass:     "help-block",
					highlight:      function ( element ) {
						var $element = $( element ),
							type = $element.prop( 'type' );

						if ( type === 'radio' ) {
							$element
								.closest( '.radio' )
								.addClass( 'has-error' );

						} else if ( type === 'checkbox' ) {

							$element
								.closest( '.checkbox' )
								.addClass( 'has-error' );

						} else {

							$element
								.closest( '.form-group' )
								.addClass( 'has-error' );
						}
					},
					unhighlight:    function ( element ) {
						var $element    = $( element ),
							$group      = $element.closest( ".has-error" ),
							removeClass = true;

						if ( $group.hasClass( "multi-validation-group" ) ) {
							removeClass = $group.find( self.config.errorElement + "." + self.config.errorClass + ":not(:hidden)" ).length === 0;
						}

						if ( removeClass ) {
							$group.removeClass( "has-error" );
						}
					},
					errorPlacement: function ( error, element ) {
						var type = element.prop( 'type' );

						if ( element.parent( '.input-group' ).length ) {
							error.insertAfter( element.parent() );
						} else if ( type === 'checkbox' || type === 'radio' ) {
							element.parent().append( error );
						} else {
							error.insertAfter( element );
						}
					}
				},
				self.$form.data( 'config' ) || {}
			);

			self.validator = self.$form.validate( self.config );

			if ( !self.config.ajax_form ) {
				self.$form.on( 'submit', function ( e ) {
					return self.validate( e );
				} );
			}
		};

		this.resetForm = function () {
			self.validator.resetForm(); // this removes the error messages but not the error colors

			self.$form.find( 'label' ).removeClass( "text-danger" );
			self.$form.find( '.form-group' ).removeClass( "has-error" );
		};

		this.validate = function ( event ) {
			self.is_valid = self.$form.valid();

			if ( !self.is_valid ) {
				if ( self.validator.errorList[0].element ) {
					try {
						$( self.validator.errorList[0].element ).focus().scrollIntoView();
					}
					catch ( e ) {

					}
				}
				if ( event ) {
					event.stopPropagation();
					event.preventDefault();
				}
			}
			return self.is_valid;
		};

		this.update = function () {
			self.validator.form();
		};

		this.reset = function () {
			self.resetForm();
		};

		this.init( element, config );
	};

	$.fn.zg_validate = function ( config ) {
		return this.each( function () {
			var $this = $( this );
			var data = $this.data( 'zgValidator' );
			if ( !data ) {
				$this.data( 'zgValidator', new ZG_Validate( this, config ) );
			}
		} );
	};

	$( toggle ).zg_validate( window.ZG_CONFIG );

})( jQuery );


(function ( $ ) {
	"use strict";

	var ccNumber  = '#CreditCardNumber',
		$ccNumber = $( ccNumber ),
		ccType    = 'select#cartType',
		$ccType;

	if ( $ccNumber.length > 0 ) {
		$ccType = $( ccType );

		$ccNumber.rules( "add", { creditcard2: function () { return $ccType.val(); } } );

		$ccType.on( 'change', function () {
			if ( $ccNumber.val() ) {
				$( this ).closest( 'form' ).validate().element( ccNumber );
			}
		} );
	}
})( jQuery );


(function ( $ ) {
	"use strict";

	//CHECK THE INTEGER NUMBER IN input[number]
	// 8 "backspace" - 46 "delete" ** 48 "0" - 57 "9"
	$( ':input[type="number"]' ).keydown( function ( event ) {
		// Allow only backspace and delete
		if ( event.keyCode === 46 || event.keyCode === 8 || event.keyCode === 13 || event.keyCode === 108 ) {
			// let it happen, don't do anything
		} else if ( event.keyCode < 48 || (event.keyCode > 57 && event.keyCode < 96) || event.keyCode > 105 ) {
			// Ensure that it is a number and stop the keypress
			event.preventDefault();
		}
	} );
})( jQuery );
