var href = document.location.href;

// add your site specific javascript here
(function ( $ ) {
	"use strict";

	// NO-TOUCH
	var body = document.getElementsByTagName('body')[0];
	if ( !("ontouchstart" in body) ) body.className += (body.className?" ":"") + "no-touch";

	$.ajaxSetup ({
		cache: false
	});

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			type: 'post',
			url: window.makeUrl({module: 'eshop', action: 'setJavascriptEnabled'}),
			dataType: 'json',
			error: function () {
				console.log( 'Error javascript_enabled' );
			}
		});
	}

	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here

	$(function () {

		var isMobile = $('body').hasClass('mobile');
		var isTablet = $('body').hasClass('tablet');
		
		//
		// Retail Stores
		// ------------------------------

		$('#country-select').change(function(){
			var country_image = $('option:selected', this).data('image');
			if ( !country_image ) 
				country_image = $('#country-image').data('default');
			$('#country-image').attr('src',country_image);
			
			var country = $('option:selected', this).data('country');
			if ( !country )
				country = $('#country').data('default');
			$('#country').html(country);
			
			var id = $('option:selected', this).val();
			if ( !id )
				id = 0;
			$('.countries, .country-details').hide();
			$('#country-'+id).show();
		});
		
		$('#site_retail_stores .countries .country a').click(function(event){
			event.preventDefault();
			var country_id = $(this).data('country');
			var store_id = $(this).data('store');
			$('#country-select').val(country_id).change();
			var top = $('#store-' + store_id).offset().top - 70;
			$("html, body").animate({ scrollTop: top });
		});
		
		if ( href ) {
			var explode = href.split('#');
			if ( explode.length == 2 ) {
				var country = explode[1];
				var id = $('#country-select option[data-country="'+country+'"]').val();
				$('#country-select').val(id).change();
			}
		}
		
		$('.country-details .store').each(function(){
			var $store = $(this);
			$('.view', this).click(function(event){
				event.preventDefault();
				$('img', $store).toggle();
				
				$(this).toggleClass('toggled');
				var toggled = $(this).hasClass('toggled');
				if ( toggled ) $(this).html($(this).data('toggled'));
				else $(this).html($(this).data('default'));
				
				var initialized = $('.map', $store).hasClass('initialized');
				$('.map', $store).toggle().addClass('initialized');
				
				if ( !initialized ) {
					var lat = parseFloat($(this).data('lat'));
					var lng = parseFloat($(this).data('lng'));
					var uluru = {lat: lat, lng: lng};
					var map = new google.maps.Map($('.map', $store).get(0), {
						zoom: 12,
						center: uluru
					});
					var marker = new google.maps.Marker({
						position: uluru,
						map: map
					});
				}
			});
		});
		
		//
		// Size Selector
		// ------------------------------
		
		$('select[data-zg-role="option-selector"]').change(function(){
			var option = $(this).val();
			if ( option ) $(this).addClass('selected');
			else $(this).removeClass('selected');
		});
		var option = $('select[data-zg-role="option-selector"]').val();
		if ( option ) $('select[data-zg-role="option-selector"]').addClass('selected');
		else $('select[data-zg-role="option-selector"]').removeClass('selected');

		// COUNTRY
		$('[data-zg-role="zg-language"] a').click(function(event){
			var country = $(this).data('title');
			Cookies.set('country', country, { path: '/', expires: 99999999 });
		});
		var country = Cookies.get('country');
		if ( !country ) country = 'United States';
		var countryLc = country.toLowerCase().replace(' ','-');
		$('.country-flag').each(function(){
			var src = $(this).attr('src');
			src = src.replace('none',countryLc);
			$(this).attr('src',src);
		});
		$('.country-flag + span').html(country);

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$('.nav-tabs').each(function(){
			var $activeTab = $(this).find('.active [data-toggle="tab"]:first');

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$(this).find('[data-toggle="tab"]:first').tab();
			}
		});

		// Tooltips
		//$('[data-toggle="tooltip"]').tooltip();

		// Carousel Settings - Home
		var $slider = $('#carousel').carousel({
			'pause': false,
			'interval': 7000
		});

		$slider.swipe({
			threshold: 50,
			excludedElements: null,
			triggerOnTouchEnd: false,
			preventDefaultEvents: false,
			swipeLeft: function(event, direction, distance, duration, fingerCount) {
				$slider.carousel('next');
			},
			swipeRight: function(event, direction, distance, duration, fingerCount) {
				$slider.carousel('prev');
			}
		});

		// enable placeholders in ie
		$('input, textarea').placeholder();

		// Slideout mobile menu
		var slideout = new Slideout({
			'panel': document.getElementById('panel'),
			'menu': document.getElementById('menu'),
			'padding': -270,
			'tolerance': 70
		});
		$('#menu-toggle').click(function(event){
			event.preventDefault();

			$(this).toggleClass('toggled');
			var scrollTop = $(document).scrollTop();
			if ( $(this).hasClass('toggled') ){
				$('.persistent').css({'position': 'absolute', 'top': scrollTop+'px'});
			}
			else{
				setTimeout(function(){
					$('.persistent').css({'position': 'fixed', 'top': '0px'});
				},400);
			}
			slideout.toggle();
		});

		// Footer lang button
		$('#site_footer_lang a').click(function(event){
			event.preventDefault();
			window.scrollTo(0,0);
			setTimeout(function(){
				$('#site_header_top .nav-lang .dropdown-toggle').click();
			},50);
		});

		// CMS Nav
		$('.cms-box h4').each(function(index){
			var id = 'nav-'+index;
			var title = $(this).html();
			$(this).attr('id', id);
			$('<a></a>').attr('href', '#'+id).html(title).attr('title',title).appendTo('.cms-nav');
		});

		// Homepage Instagram Desktop Carousel
		function carousels(){
			$('#the-latest, #instagram-feed, .options-carousel-container').each(function(){
				if ( !$('.carousel', this).data('jcarousel') ){
					var $carousel = $(".carousel", this).jcarousel({
						'list': '.carousel-list',
						'items': '.carousel-item'
					});

					$('.prev',this)
					.on('jcarouselcontrol:inactive', function() { $(this).addClass("inactive"); })
					.on('jcarouselcontrol:active', function() { $(this).removeClass("inactive"); })
					.jcarouselControl({'target': '-=2','carousel': $carousel});

					$('.next',this)
					.on('jcarouselcontrol:inactive', function() { $(this).addClass("inactive"); })
					.on('jcarouselcontrol:active', function() { $(this).removeClass("inactive"); })
					.jcarouselControl({'target': '+=2','carousel': $carousel});

					$carousel.swipe({
						threshold: 50,
						excludedElements: null,
						triggerOnTouchEnd: false,
						preventDefaultEvents: false,
						swipeLeft: function(event, direction, distance, duration, fingerCount) {
							$carousel.jcarousel('scroll', '+=1');
						},
						swipeRight: function(event, direction, distance, duration, fingerCount) {
							$carousel.jcarousel('scroll', '-=1');
						}
					});
				}
			});
		}
		carousels();
		$(window).on('zg.getProductInfo.productCreated',function(){
			carousels();
		});

		// Media Filters
		$('[data-zg-role="media-filter"]').click(function(event){
			event.preventDefault();
			$('[data-zg-role="media-filter"]').removeClass('active');
			$(this).addClass('active');
			var clickType = $(this).data('type');
			var show = 12;
			$('[data-zg-role="media-entry"]').each(function(index){
				var type = $(this).data('type');
				if ( show > 0 && ( clickType == 'all' || clickType == type ) ){
					$(this).removeClass('hidden');
					show = show - 1;
				}
				else $(this).addClass('hidden');
			});
			if (show > 0) $('#media #load-more').addClass('hidden');
			else $('#media #load-more').removeClass('hidden');
		});

		// Persistent Menu
		$('#site_header_section').persistentMenu({
			flickerStopElement: '#persistent_menu',
			flickerStopCSS: {'padding-top': '71px'},
			flickerStopCSSMobile: {'padding-top': '61px'}
		});

		// Media slide next/prev
		$('#site_media').swipe({
			threshold: 50,
			excludedElements: null,
			triggerOnTouchEnd: false,
			preventDefaultEvents: false,
			swipeLeft: function(event, direction, distance, duration, fingerCount) {
				if ( $('#nav-next').length ) document.getElementById("nav-next").click();
			},
			swipeRight: function(event, direction, distance, duration, fingerCount) {
				if ( $('#nav-prev').length ) document.getElementById("nav-prev").click();
			}
		});

		// Athlete Read More
		$('#athlete .read-more').click(function(event){
			event.preventDefault();
			$('#athlete .more').removeClass('hidden');
			$('#athlete .dots').addClass('hidden');
			$(this).addClass('hidden');
		});

		// Instagram Feed
		var limit = 20;
		if ( isMobile ) limit = 6;

		function instafeedImageUpdate(){
			$('#instafeed article .image').imagesLoaded(function(){
				$('#instafeed article .image.landscape').each(function(){
					var width = $(this).width();
					$(this).css({'margin-left': -width/2, 'left': '50%'});
				});
				$('#instafeed article .image.portrait').each(function(){
					var height = $(this).height();
					$(this).css({'margin-top': -height/2, 'top': '50%'});
				});
			});
			$('#instafeed').closest('.carousel').jcarousel('reload');
		}

		var userId = $('#instafeed').data('instagramId');
		if ( userId ){
			//1745003923.cbd839e.32a1b67236bb4bc789f92b3487b158d3
			var feed = new Instafeed({
				get: 'user',
				userId: userId,
				accessToken: '1745003923.cbd839e.e5fe6c6968c24314bae79fb048458a82',
				sortBy: 'most-recent',
				resolution: 'standard_resolution',
				limit: limit,
				template: '<div class="col-xs-6 carousel-item"><article><a href="{{link}}" target="_blank"><img src="'+SGL_JS_WEBROOT+'/themes/'+SGL_JS_MERCHANT_ID+'/'+SGL_JS_THEME+'/images/custom/sentinel.png" alt="Sentinel" class="sentinel" /><img src="{{image}}" alt="Instagram Image" class="image {{orientation}}" /></a></article></div>',
				after: function(){
					instafeedImageUpdate();
				}
			});
			feed.run();

			$(window).resize(function(){
				instafeedImageUpdate();
			});
		}

		function toShow(toShow,elements,element){
			var ind = 0;
			var endInd = 0;
			$(elements).each(function(index){
				if ( $(this).hasClass('hidden') && toShow > 0 ){
					$(this).removeClass('hidden');
					toShow = toShow - 1;
					endInd = index;
				}
				ind = index;
			});
			if ( toShow != 0 || ind == endInd ) $(element).addClass('hidden');
		}

		// Athlete More Videos
		$('#more-videos').click(function(event){
			event.preventDefault();
			toShow(2, '.videos .vid', this);
		});

		// Athlete More Photos
		$('#more-photos').click(function(event){
			event.preventDefault();
			toShow(2, '.photos .photo', this);
		});

		// Media Load More
		$('#media #load-more').click(function(event){
			event.preventDefault();
			var type = $('#media-filters a.active').data('type');
			var width = $('body').width();
			var featured = ':not(.featured)';
			if (width < 768) featured = '';
			if ( type != 'all' ) toShow(6, '#media > article'+featured+'[data-type="'+type+'"]', this);
			else toShow(6, '#media > article'+featured, this);
		});

		// Events Load More
		$('#load-more-upcoming').click(function(event){
			event.preventDefault();
			toShow(2, '#events-upcoming > article', this);
		});

		// Events Load More
		$('#load-more-recent').click(function(event){
			event.preventDefault();
			toShow(2, '#events-recent > article', this);
		});

		// Events Load More Similar Events
		$('#load-more-similar').click(function(event){
			event.preventDefault();
			toShow(3, '#events-similar > article', this);
		});

		// Events Filters
		$('[data-zg-role="event-filter"]').click(function(event){
			event.preventDefault();
			$('#events-upcoming, #events-recent').addClass('hidden');
			$('#events-filters a').removeClass('active');
			$(this).addClass('active');
			var id = $(this).data('id');
			$(id).removeClass('hidden');
		});

		// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
		$('.dropdown').on('show.bs.dropdown', function(e){
			$(this).find('.dropdown-menu').first().stop(true, true).slideDown(200);
		});

		// ADD SLIDEUP ANIMATION TO DROPDOWN //
		$('.dropdown').on('hide.bs.dropdown', function(e){
			$(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
		});		

		// CUSTOM SELECT
		$('select').customSelect();

		// PRODUCT IMAGE MOBILE INDICATORS
		$(window).on('zg.gallery.rendered',function(){
			$('#image-indicators').html('');
			$('.thumbnails .thumbnail').each(function(index){
				var $div = $('<div></div>').appendTo('#image-indicators').click(function(){
					$('.thumbnails > div:nth-of-type('+(index+1)+') .thumbnail').click();
				});
				$(this).click(function(){
					$('#image-indicators div').removeClass('active');
					$div.addClass('active');
				});
			});
			$('#image-indicators div:first-child').addClass('active');
		});

		// PRODUCT IMAGE SWIPE
		$('#image-indicators, #zoomMain').swipe({
			threshold: 50,
			excludedElements: null,
			triggerOnTouchEnd: false,
			preventDefaultEvents: false,
			swipeLeft: function(event, direction, distance, duration, fingerCount) {
				var $next = $('.thumbnails .thumbnail.active').parent().next().find('.thumbnail');
				var $first = $('.thumbnails > div:first-child .thumbnail');
				if ( $next.length )	$next.click();
				else $first.click();
			},
			swipeRight: function(event, direction, distance, duration, fingerCount) {
				var $prev = $('.thumbnails .thumbnail.active').parent().prev().find('.thumbnail');
				var $last = $('.thumbnails > div:last .thumbnail');
				if ( $prev.length )	$prev.click();
				else $last.click();
			}
		});

		function counter(){
			var $count = $('[data-zg-role="cart-items-count"]');
			if ( $count.html() == "0" ) $count.addClass('hidden');
			else $count.removeClass('hidden');
		}
		counter();
		$(window).on('zg.getProductList.updated',function(){
			counter();
		});

		// PRODUCTS SHOP MENU
		/*function productsMenu(){
			var min = $('#site_footer_section').outerHeight() + $('#site_footer_sub_section').outerHeight() + 30;
			var bottom = $(document).height() - $(window).scrollTop() - 150 - $('#products-menu').outerHeight();
			var top = $(document).height() - min - $('#products-menu').outerHeight();
			if ( bottom <= min ) $('#products-menu').css({'position':'absolute', 'top': top});
			else $('#products-menu').removeAttr('style');
		}
		$(document).scroll(function(){
			productsMenu();
		});
		productsMenu();
		*/

		// ATHLETES PHOTOS
		var $img = $('#athletes_gallery img');
		var $text = $('#athletes_gallery .text');
		$('#athlete-photos a').click(function(event){
			$('#athlete-photos a').removeClass('active');
			$(this).addClass('active');
			$text.html( $(this).find(".bottom_modal").html());
			var large = $(this).attr('href');
			$img.attr('src', large);
		});

		$('#athletes_gallery .modal-prev').click(function(){
			var $last = $('#athlete-photos .photo a').last();
			var $first = $('#athlete-photos .photo a').first();
			var $prev = null;
			$('#athlete-photos .photo a').each(function(){
				if ( $(this).hasClass('active') ){
					$(this).removeClass('active');
					if ( $prev )
					{
						var image = $prev.addClass('active').attr('href');
						$text.html($prev.find(".bottom_modal").html());
					}
					else 
					{
						var image = $last.addClass('active').attr('href');
						$text.html($last.find(".bottom_modal").html());
					}
					$img.attr('src', image);
					return false;
				}
				$prev = $(this);
			});
		});

		$('#athletes_gallery .modal-next').click(function(){
			var $last = $('#athlete-photos .photo a').last();
			var $first = $('#athlete-photos .photo a').first();
			var next = false;
			$('#athlete-photos .photo a').each(function(){
				if ( next ){
					var image = $(this).addClass('active').attr('href');
					$img.attr('src', image);
					$text.html($img.find(".bottom_modal").html());
					return false;
				}
				if ( $(this).hasClass('active') ){
					$(this).removeClass('active');
					next = true;
					if ( $(this).attr('href') == $last.attr('href') ){
						var image = $first.addClass('active').attr('href');
						$img.attr('src', image);
						$text.html($first.find(".bottom_modal").html());
					}
				}
			});
		});

	});

})(jQuery);


$(function() {

	openTabNl();
	closeTabNl();

	closeSubMenu();
	openSubMenu();

	mobileOpenMenu();
});


// tab newsletter
var openTabNl = function () {
	$(".wbt_newsletter_home .title_open").click(function () {
		$(".wbt_newsletter_home .title_open").fadeOut("normal", function () {
			$(".wbt_newsletter_home .content").fadeIn();
		});		
	});
};


var closeTabNl = function () {
	$(".wbt_newsletter_home .close_nl").click(function () {

		$(".wbt_newsletter_home .content").fadeOut("normal", function () {
			$(".wbt_newsletter_home .title_open").fadeIn();
		});		
	});
}


// sub menu
var openSubMenu = function () {
	//$("a.act_sub_menu").click(function ( event ) {
	$("a.act_sub_menu").mouseover( function() {

		// controlla se è gia visibile
		$(".totalblack").fadeIn();
		$(".wasa_sub_menu").fadeIn();
		$(".sm_products").hide();

		// leggi id
		var idm = $(this).data('id');
		$("#" + idm).fadeIn();

		// REPLACING THE CURRENT URL. Ticket #5678
		/*
		var url = $(this).data('url') || "";
		var html =  $('html')[0].outerHTML;
		var title = document.title;
		window.history.replaceState(html, title, url);
		*/

	});
}

var closeSubMenu = function () {
	$(".wasa_sub_menu .close_menu").click(function () {
		opToCloseMenu();
	});

	$(".totalblack").mouseenter(function () {
		opToCloseMenu();
	});
}

var opToCloseMenu = function () {
	$(".wasa_sub_menu").fadeOut();
	$(".sm_products").fadeOut();
	$(".totalblack").fadeOut();
}

var mobileOpenMenu = function () {
	$(".nav_smartphone .bars").click(function () {
		$(".sub_menu_mobile").slideToggle();
	});
}


$( document ).on( "zg.getProductInfo.success", function( e, data ) {
	if ( typeof data === "undefined" ||  ( data.length == 0 || !data[0].type ))  
		return;
	if ( data[0].type === "quickbuy" ){
		$.getScript("http://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-570e63baf4feb377");

		putImgSize(data[0].id,data[0].categories);

	}
});
function putImgSize(id,cat)
{
	$img=$("#sizeChartModal").find("img");
	arrProd=window.arrProd;
	arrCat=window.arrCat;

	if(arrCat != "undefined" && arrCat != undefined )
	{
		for(var i=0;i<arrCat.length;i++)
		{

			if( arrCat[i] != "undefined" && arrCat[i] != undefined )
			{

				for(var j=0;j<arrCat[i].length;j++)
				{
					for (var key in cat) 
					{						
						if(arrCat[i][j]==key)
						{

							$img.attr("src",arrCat[i][arrCat[i].length-1]);
						}
					}
				}
			}
		}
	}
	if(arrProd != "undefined" && arrProd != undefined )
	{
		for(var i=0;i<arrProd.length;i++)
		{
			if( arrProd[i] != "undefined" && arrProd[i] != undefined )
			{

				for(var j=0;j<arrProd[i].length;j++)
				{
					if(arrProd[i][j]==id)
					{

						$img.attr("src",arrProd[i][arrProd[i].length-1]);
					}
				}
			}
		}
	}

}
/*
$('[id^="tabinfo_"]').on('show.bs.collapse', function (e) {
	//e.preventDefault();
	$(this).css("height","auto!important");
	$(this).show();
});
$('[id^="tabinfo_"]').on('hidden.bs.collapse', function (e) {
	//e.preventDefault();
	$(this).height("0px");
	$(this).hide();
});
*/
/*
if($(".thumb-append").length)
$(".thumb-append").append($(".thumb-order"));
*/
$('#help-area').on('shown.bs.collapse', function () {

	var panel = $(this).find('.in');

	$('html, body').animate({
		scrollTop: panel.offset().top-115
	}, 100, 'swing');

});
if($(document).width()<=600)
	{
		var to=$(".thumb-order .thumbnails");
		$(".thumb-append").append(to);

	}
window.onresize = function() {
	if($(document).width()<=600)
	{
		var to=$(".thumb-order .thumbnails");
		$(".thumb-append").append(to);

	}else
	{
		var to=$(".thumb-append .thumbnails");
		$(".thumb-order").append(to);
	}
} 
