$( function () {
    if( !$('[data-zg-role="load-ajax-cms"]').length )
        return false;

    function loadAjaxCMS ( containerId, contentId, showCookiesTable ){

        var url = window.makeUrl({module: 'eshop', action: 'getContentCMS'});
				var container = $('#' + containerId);
				var containerTitle = container.find('[data-zg-container="Title"]');
				var containerCookiesTable = container.find('[data-zg-container="cookies"]');
				var containerText1 = container.find('[data-zg-container="Text1"]');
				var containerText2 = container.find('[data-zg-container="Text2"]');

        $.ajax({
            url: url,
            cache: false,
						data: { 'contentId' : contentId },
						method: 'post',

            beforeSend: function ( xhr ) {
                containerTitle.empty().addClass('loading');
            },

            success: function ( response ) {
                containerTitle.empty().append( response[ contentId ].fields.Title );
							
								containerText1.empty().append( response[ contentId ].fields.Text1 );
							
								if ( showCookiesTable == true ){
									containerCookiesTable.removeClass('hidden');

									/* Adding the "hidden" class again when we close the modal*/
									$('#modal_privacies').on('hidden.bs.modal', function() {
										containerCookiesTable.addClass('hidden');
									});
								}
								
								containerText2.empty();
								if ( response[ contentId ].fields.Text2 )
									containerText2.append( response[ contentId ].fields.Text2 );
							
            },

            complete: function () {
                containerTitle.removeClass('loading');
            }

        });
			
    }

    $( document).on( 'click', '[data-zg-role="load-ajax-cms"]', function(e){
				var containerId = $(this).data('ajax-container-id');
        var contentId = $(this).data('ajax-content-id');
        var showCookiesTable = $(this).data('ajax-show-cookies');
        loadAjaxCMS(containerId, contentId, showCookiesTable);
    });
	
		
});