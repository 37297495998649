/* ============================================================
 * ADDRESS
 *
 * - Load states for a country
 * - Hide the state selector for an empty response
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $ ) { /* global _, DEBUG, JS_TRANSLATIONS, zgGet */
	"use strict";

	var countrySelector = '[data-zg-role="country-id"]',
		stateSelector   = '[data-zg-role="state-id"]';


	// LOAD STATES CLASS DEFINITION
	// ============================

	/**
	 *
	 * @param {HTMLElement} element
	 *
	 * @constructor
	 */
	var LoadStates = function ( element ) {
		this.$element = $( element );

		this.$form         = this.$element.closest( 'form' );
		this.$statesSelect = this.$form.find( stateSelector );
		this.$container    = this.$statesSelect.closest( '.form-group' );

		this.defaultState = this.$statesSelect.data( 'default' );

		this.__setEventHandlers();
	};


	// LOAD STATES PRIVATE METHODS
	// ===========================


	/**
	 *
	 * @param {string|number} value
	 * @param {string}        text
	 * @param {boolean}       selected
	 *
	 * @private
	 */
	LoadStates.prototype.__createOption = function ( value, text, selected ) {
		return $( '<option value="' + value + '"' + ( selected ? ' selected' : '' ) + '>' + text + '</option>' );
	};


	/**
	 *
	 * @private
	 */
	LoadStates.prototype.__fetch = function () {
		var countryId;

		// destroy current options
		this.$statesSelect.empty().val( '' );

		countryId = this.$element.val();

		if ( countryId ) {
			zgGet(
				'loadstates',
				{ 'cid': countryId },
				null,
				{ 'success': _.bind( this.__parseStates, this ) }
			);
		}
	};


	/**
	 *
	 * @param {Object} states
	 * @private
	 */
	LoadStates.prototype.__parseStates = function ( states ) {
		var that = this, list = [];

		if ( DEBUG ) {
			console.info( 'loadStates', states );
		}

		_.each( states, function ( value, key ) {
			if ( key ) { // ignore the empty option
				list.push( that.__createOption( key, value, (key == that.defaultState) ) );
			}
		} );

		this.$statesSelect.empty();

		if ( list.length ) {

			this.$statesSelect.append( this.__createOption( '', JS_TRANSLATIONS.please_select, false ) );

			// only one state
			if ( list.length === 1 ) {
				list[0].prop( 'selected', true );
			}

			this.$statesSelect
				.append( list.sort( zg_sortElements( {} ) ) )
				.prop( 'required', true );

			this.$container.removeClass( 'hidden' ).fadeIn();

		} else {

			// The only response is the "please select" option.
			// Hide the state selector and make it not required.
			// Let backend sort it out.
			this.$statesSelect
				.val( '' )
				.prop( 'required', false );

			this.$container.hide();

		}
	};


	/**
	 *
	 * @private
	 */
	LoadStates.prototype.__setEventHandlers = function () {
		var that = this;

		this.$element.on( 'change.zg.loadStates', function () {
			that.__fetch();
		} );
	};


	// LOAD STATES PLUGIN DEFINITION
	// =============================

	function Plugin () {
		return this.each( function () {
			var $this = $( this );
			var data  = $this.data( 'zg.loadStates' );

			if ( !data ) {
				$this.data( 'zg.loadStates', ( data = new LoadStates( this ) ) );
			}

			data.__fetch();
		} );
	}

	$.fn.zgLoadStates             = Plugin;
	$.fn.zgLoadStates.Constructor = LoadStates;


	// LOAD STATES DATA-API
	// ====================

	function initAllCountrySelectors () {
		$( countrySelector ).each( function () {
			Plugin.call( $( this ) );
		} );
	}

	$( function () {
		// address form added to the page
		$( document ).on( 'zg.addressForm.ready', initAllCountrySelectors );

		// start script on page load
		initAllCountrySelectors();
	} );

}( jQuery ));
