 /* ============================================================
 * Exit Intent Popup:
 * * Show a modal when the user moves his mouse outside the document
 * * modal's html is loaded asynchronously
 * * create a cookie to avoid to be annoying
 *
 * @author: Giuseppe Ciotola - gciotola<at>zerogrey.com
 *
 *  required:
 * 		- js functions window.readCookie() and windows.createCookie()  ( usually in /base/zg-utils.js )
 *		- /eshop/modal_exit_intent_popup.html   (bootstrap modal)
 *		- set ZG_CONFIG.showExitIntentPopup as 'true' in /global/_common_js_includes.html to show the modal
 *
 *  installation:
 *    - add a fake view in cmsList --> {elseif $result->type == 'exit_intent_popup' }	{include file="eshop/modal_exit_intent_popup.html"}	{/if}
*   	- set ZG_CONFIG.showExitIntentPopup as 'true' in /global/_common_js_includes.html to show the modal
 * ============================================================ */



(function ( $ ) {
	"use strict";

	// if showExitIntentPopup is not set, it dies here
	if ( typeof ZG_CONFIG.showExitIntentPopup === 'undefined')  {
		return; 
	}
	
	var toggle 									   = 'body';
	var modal_toggle 							 = '#exitpopup';
	var cookie_name 							 = 'ZG_exitpopupclosed';
	var cookie_expiration_days     = 0.0007;  // 0.0007 is 1 minutire, 0.0033 is 4~5 minutes.  1 is one day (24h)
	
	var request = $.ajax(); // needed to keep track of the pending ajax requests
	
	var ZG_exitIntentPopup = function ( element, options ) {
		var self = this;
		
		this.init = function ( element, options ) {
			var $element               = $(element);
			self.$element              = $element;
			self.$eventBus             = $(document);
			self.$modalLoaded          = false;
			self.$modalShown           = self.readCookie(cookie_name) || false;
		
			/*self.loadAjaxModal();
			
			self.$eventBus.on('mouseout',function(e){
				 // This should be enough to track if mouse cursor goes outside the viewport 
				e = e ? e : window.event;
				var from = e.relatedTarget || e.toElement;
				if(!from || from.nodeName === "HTML") 
					self.showModal();
			});
			*/
			
		};
		
		
		
		this.loadAjaxModal = function() {
			
			if ( self.$modalLoaded === true) 
				return;

			var url = window.makeUrl({
                module: 'eshop', manager: 'cms', action: 'list', type: 'Exitpopup',  master : 'master_blank'
            });
			request = $.ajax({
                url:      url,
                cache:    true,
                dataType: "html",

                beforeSend: function ( xhr ) {
                    xhr.setRequestHeader( 'X-Requested-With', 'empty' );
                },

                success: function ( responseText ) {
                    $(toggle).prepend(responseText);
                    self.$modalLoaded = true;
                    self.bindModalEvent();
                }
			});
			
		};
		

		
		this.showModal = function() {		
			if ( self.$modalLoaded === true && self.$modalShown === false) {
				$(modal_toggle).modal('show');
				self.$modalShown = true;  // to avoid to show the modal again
			}
		};

		
		
		this.bindModalEvent = function() {
			// Put here code to be executed after modal has been loaded
			$(modal_toggle).on('hide.bs.modal', function (e) {
				self.createCookie(cookie_name, 'true', cookie_expiration_days);
			});
		}
		
		
		
		this.createCookie = function(name, value, days) {
			var expires = "";
			if ( days ) {
				var date = new Date();
				date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );

				expires = "; expires=" + date.toGMTString();
			}
			document.cookie = name + "=" + value + expires + "; path=/";
		}
		
		
		this.readCookie = function(name) {
			var nameEQ = name + "=";
			var ca = document.cookie.split(';');
			for(var i=0;i < ca.length;i++) {
				var c = ca[i];
				while (c.charAt(0)==' ') c = c.substring(1,c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
			}
			return null;
		}
		
	

		this.init( element );
		
	};

	
	
	$.fn.zg_exit_intent_popup = function ( options ) {
		return this.each( function () {
			var $this = $(this);
			var data = $this.data('zgExitPopupInit');
			if (!data) {
					$this.data('zgExitPopupInit', (data = new ZG_exitIntentPopup( this )) );
			}
		});
	};
	

	$(function () {
		if (ZG_CONFIG.showExitIntentPopup === true) {
			$(toggle).zg_exit_intent_popup();		
		}
	});
	
	


})( jQuery );

