/* ============================================================
 * zg-utils.js
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */


(function ( $ ) { /* globals _, DEBUG, Handlebars, JS_TRANSLATIONS */
	"use strict";

	// Establish the root object (`window` in the browser)
	var root = this;


	// HANDLEBARS RENDERER
	// ===================

	root.handlebarsTemplates = {
		// used to cache the compiled templates
		compiled: $.extend( {}, Handlebars.templates || {} ),

		getTemplate: function ( id ) {
			return $.trim( $( '#template-' + id ).html() ) || "Missing Template: " + id;
		},

		registerPartial: function ( id ) {
			Handlebars.registerPartial( id, this.compiled[id] || this.getTemplate( id ) );
		},

		render: function ( type, data ) {
			var result = "<strong>HANDLEBARS Compilation Runtime Not Available:</strong><br>" +
					"Please provide a pre-compiled template for " + type;

			if ( !type ) {
				throw new Error( "handlebars-renderer.js - called with undefined template" );
			}

			if ( !this.compiled[type] && Handlebars.compile ) {
				this.compiled[type] = Handlebars.compile( this.getTemplate( type ) );
			}

			if ( _.isFunction( this.compiled[type] ) ) {
				result = this.compiled[type]( data );
			}

			return result;
		}
	};


	// HANDLEBARS HELPERS
	// ==================

	/**
	 *
	 */
	Handlebars.registerHelper( 'calculate', function ( lvalue, operator, rvalue, options ) {
		var result,
				operators = {
					'+': function ( l, r ) { return (+l) + (+r); },
					'-': function ( l, r ) { return (+l) - (+r); },
					'*': function ( l, r ) { return (+l) * (+r); },
					'/': function ( l, r ) { return (+l) / (+r); },
					'%': function ( l, r ) { return (+l) % (+r); }
				};

		if ( !operators[operator] ) {
			throw new Error( "Handlebars Helper 'calculate': undefined operator " + operator );
		}

		result = operators[operator]( lvalue, rvalue );

		if ( result !== null ) {
			return options.fn( result );
		}

		return options.inverse( this );
	} );
	Handlebars.registerHelper("calculateDiscount", function(dvalue, svalue, options) {
		dvalue = parseFloat(dvalue);
		svalue = parseFloat(svalue);

		var result="";
		var d=(dvalue-svalue)/dvalue*100;
		result=Math.round(d);
		console.log(d+"percent");
		return result;
	});
	Handlebars.registerHelper("math", function(lvalue, operator, rvalue, options) {
		lvalue = parseFloat(lvalue);
		rvalue = parseFloat(rvalue);

		return {
			"+": lvalue + rvalue,
			"-": lvalue - rvalue,
			"*": lvalue * rvalue,
			"/": lvalue / rvalue,
			"%": lvalue % rvalue
		}[operator];
	});

	/**
	 *
	 */
	Handlebars.registerHelper( 'compare', function ( lvalue, operator, rvalue, options ) {
		var result,
				operators = {
					'&&':     function ( l, r ) { return l && r; },
					'||':     function ( l, r ) { return l || r; },
					'==':     function ( l, r ) { return l == r; },
					'===':    function ( l, r ) { return l === r; },
					'!=':     function ( l, r ) { return l != r; },
					'!==':    function ( l, r ) { return l !== r; },
					'<':      function ( l, r ) { return l < r; },
					'>':      function ( l, r ) { return l > r; },
					'<=':     function ( l, r ) { return l <= r; },
					'>=':     function ( l, r ) { return l >= r; },
					'typeof': function ( l, r ) { return typeof l == r; }
				};

		if ( !operators[operator] ) {
			throw new Error( "Handlebars Helper 'compare': undefined operator " + operator );
		}

		result = operators[operator]( lvalue, rvalue );

		if ( options && options.fn ) {
			if ( result ) {
				result = options.fn( this );
			} else {
				result = options.inverse( this );
			}
		}

		return result;
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'count', function ( variable, options ) {
		var prop, result = 0;

		if ( _.isArray( variable ) ) {

			result = variable.length;

		} else if ( _.isObject( variable ) ) {

			for ( prop in variable ) {
				if ( variable.hasOwnProperty( prop ) ) {
					result++;
				}
			}

		} else if ( !_.isNull( variable ) && !_.isUndefined( variable ) ) {

			result = 1;
		}

		if ( options && options.fn ) {
			if ( result ) {
				result = options.fn( result );
			} else {
				result = options.inverse( this );
			}
		}

		return result;
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( "debug", function ( value, options ) {
		if ( root.DEBUG ) { // only print debug info if the page is in DEBUG mode
			console.log( "/ -------------" );
			console.log( "Context:", this );

			if ( value && options ) {
				console.log( "----" );
				console.log( "Value:", value );
			}
		}
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'encodeURIComponent', function ( component ) {
		return encodeURIComponent( component );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'getCurrentUrl', function () {
		return window.location.href;
	} );

	Handlebars.registerHelper( 'getCurrentUrlEncoded', function () {
		return encodeURIComponent( window.location.href );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'getElement', function ( context, item, options ) {
		var result;

		if ( context && context[item] ) {
			result = options.fn( context[item] );
		} else {
			result = options.inverse( this );
		}

		return result;
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'getJsVar', function ( variable, options ) {
		var i,
				prop = variable.split( "." ),
				obj  = root;

		for ( i = 0; i < prop.length && obj; i++ ) {
			if ( obj[prop[i]] ) {
				obj = obj[prop[i]];
			} else {
				obj = false;
			}
		}

		if ( options && options.fn ) {
			if ( obj ) {
				obj = options.fn( obj );
			} else {
				obj = options.inverse( this );
			}
		}

		return obj;
	} );

	/**
	 * get the number of elements in the product preview menu
	 */
	Handlebars.registerHelper( 'getProductPreviewClass', function () {
		var className = ["none", "full", "half", "third", "quarter", "fifth", "sixth"],
				index,
				items     = 0;

		// one item if is not a container (add to favorites)
		if ( this.attributes && !this.attributes.isContainer ) {
			items++;
		}

		// plus as many items as the options
		if ( this.options ) {
			for ( index in this.options ) {
				if ( this.options.hasOwnProperty( index ) ) {
					items++;
				}
			}
		}

		// plus the addtocart/quickbuy if is sellable
		items += (this.attributes && this.attributes.isSellable) ? 1 : 0;

		return className[items];
	} );

	/**
	 * Get all the categories
	 */
	Handlebars.registerHelper( 'getSeoCategories', function ( categories ) {
		var seoCat = [],
				first  = true,
				index;

		for ( index in categories ) {
			if ( categories.hasOwnProperty( index ) ) {
				seoCat.push( categories[index].name );

				first = false;
			}
		}

		return seoCat.join( '|' );
	} );
	
	/**
	 * In array function
	 */
	Handlebars.registerHelper( 'inArray', function ( array, string ) {
		return _.contains(array, string);
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'makeUrl', function ( action, manager, module ) {
		var components = {};

		if ( action ) {
			components.action = action;
		}

		if ( manager ) {
			components.manager = manager;
		}

		if ( module ) {
			components.module = module;
		}

		return root.makeUrl( components );
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'preloadImage', function ( img ) {
		if ( _.isString( img ) ) {
			(new Image()).src = img;
		}
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'sortByName', function ( values, options ) {
		var array, result;

		array = root.zgSortObjectByProp( values );

		if ( array.length ) {
			result = "";

			for ( var i = 0; i < array.length; i++ ) {
				result += options.fn( array[i], { data: { index: i } } );
			}
		} else {
			result = options.inverse( this );
		}

		return result;
	} );

	/**
	 *
	 */
	Handlebars.registerHelper( 'toFixed', function ( number, fixedTo ) {

		// Only allow integers as parameter
		var fixedDecimals = ( typeof fixedTo === "number" && isFinite( fixedTo ) && fixedTo % 1 === 0 ) ? fixedTo : 2;

		return Number( number ).toFixed( fixedDecimals );

	} );
	
	/**
	 *
	 */
	Handlebars.registerHelper( 'generateCmsStickers', function ( pid, categories,promotions, options ) {

		var categoryKeys  = _.keys( categories ),
			promotionKeys = ( promotions && promotions.applied ) ? _.keys( promotions.applied ) : [],
			isProduct = false,
			isAssignedToPromotion = false,
			isAssignedToCategories = false;

		if ( !window.CMS_STICKERS ) {
			return options.inverse();
		}
		return _.reduce( window.CMS_STICKERS, function ( buffer, element ) {
			// pid has to be a string
			isProduct =  _.contains( element.products, '' + pid );
			isAssignedToCategories = _.intersection( categoryKeys, element.categories ).length;
			isAssignedToPromotion = !!_.intersection( promotionKeys, element.promotions ).length;

			return ( isProduct || isAssignedToCategories || isAssignedToPromotion ) ?
			buffer + options.fn( element ) : buffer;

		}, '' );

	} );

	/**
	 * Get all the categories
	 */
	Handlebars.registerHelper( 'getSeoCategories', function ( categories ) {
		var seoCat = [],
				index;

		for ( index in categories ) {
			if ( categories.hasOwnProperty( index ) ) {
				seoCat.push( categories[index].name );
			}
		}

		return seoCat.join( '|' );
	} );



	/**
	 *
	 */
	Handlebars.registerHelper( 'translate', function ( translation ) {
		return JS_TRANSLATIONS[translation] || ">>- " + translation + " -<<";
	} );


	// HANDLEBARS PARTIALS
	// ===================

	//
	root.handlebarsTemplates.registerPartial( 'gallery-item' );

	//
	root.handlebarsTemplates.registerPartial( 'image' );

	//
	root.handlebarsTemplates.registerPartial( 'product-availability' );
	root.handlebarsTemplates.registerPartial( 'product-options' );
	root.handlebarsTemplates.registerPartial( 'product-price' );
	root.handlebarsTemplates.registerPartial( 'product-shopByLook' );

	//
	root.handlebarsTemplates.registerPartial( 'social-share' );


	// HANDLEBARS AUTOMATIC RENDERING
	// ==============================


	$( function () {
		$( '[data-zg-role="render-handlebars"]' ).each( function () {
			var $this = $( this ),
					data = $this.data();

			$this.append( root.handlebarsTemplates.render( data.template, data.content || {} ) );
		} );
	} );

}.call( this, jQuery ));
