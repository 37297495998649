/* ==================================================================================================================
 * exportMissingGeolocation
 *
 *    Create a table with all the stores with missing geolocation.
 *    Creates a csv file to be imported in the CMS.
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ================================================================================================================== */

(function ( $ ) { /* global _, handlebarsTemplates */
	'use strict';

	var root = this;


	// CLASS DEFINITION
	// ================

	var ExportMissingGeolocation = function ( options ) {
		this.options = _.extend( {}, ExportMissingGeolocation.DEFAULTS, window.ZG_CONFIG, typeof options === 'object' && options );

		this.missingGeolocationInfo = {};

		this.isFirst = true;

		this.$container = $( this.options.container );
	};

	ExportMissingGeolocation.DEFAULTS = {
		container:       '[data-zg-role="sl-location-info"]',
		templateError:   'storelocator-missing-geolocation-error',
		templateLoading: 'storelocator-missing-geolocation-loading',
		templateTable:   'storelocator-missing-geolocation-table'
	};


	/**
	 *
	 * @param {string}  storeId
	 * @param {Object=} info
	 */
	ExportMissingGeolocation.prototype.addStore = function ( storeId, info ) {
		this.missingGeolocationInfo[storeId] = info || null;

		this.__render();
	};


	ExportMissingGeolocation.prototype.__parseStores = function () {
		var blob, data, id,
			file  = ['"content_type","content_id","content_name","language","Latitude","Longitude"'],
			ready = true;

		// parse received geolocation to create the blob file
		for ( id in this.missingGeolocationInfo ) {
			if ( this.missingGeolocationInfo.hasOwnProperty( id ) && ready ) {
				if ( _.isEmpty( this.missingGeolocationInfo[id] ) ) {
					ready = false;
				} else {
					file.push(
						'"' + this.missingGeolocationInfo[id].type + '",' +
						'"' + id + '",' +
						'"' + this.missingGeolocationInfo[id].name + '",' +
						'"default",' + // default language (use for every language)
						'"' + this.missingGeolocationInfo[id].fields.Latitude + '",' +
						'"' + this.missingGeolocationInfo[id].fields.Longitude + '"'
					);
				}
			}
		}

		if ( ready ) {
			blob = new Blob( [( file.join( '\n' ) )], { type: 'text/csv' } );

			data = {
				stores: this.missingGeolocationInfo,
				file:   URL.createObjectURL( blob )
			};

			this.$container.html(
				handlebarsTemplates.render( this.options.templateTable, data )
			);
		}
	};


	ExportMissingGeolocation.prototype.__render = function () {
		if ( _.isEmpty( this.missingGeolocationInfo ) || this.isFirst ) {

			// create "loading" element
			this.$container.html(
				handlebarsTemplates.render( this.options.templateLoading )
			);

			this.isFirst = false;

		} else if ( ("Blob" in root) && ("URL" in root) ) {

			// create the table
			this.__parseStores();

		} else {

			// current browser is not able to create the file
			this.$container.html(
				handlebarsTemplates.render( this.options.templateError )
			);

		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgExportMissingGeolocation = ExportMissingGeolocation;

}.call( this, jQuery ));
