/* ============================================================
 * ADDRESS
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $ ) { /* global _, DEBUG, JS_TRANSLATIONS */
	"use strict";
		
	var SELECTOR      = '[data-zg-role="request-invoice"]',
		invoiceSelector      = '[data-zg-role="request-invoice"]',
		invoiceDataContainer = '[data-zg-role="request-invoice-container"]',
		invoiceDataSelector  = '[data-zg-role="request-invoice-data"]',
		invoiceConditionalFields  = '[data-conditional]',
		invoiceCountrySelector = '[data-zg-role="country-id"]',
		electronicInvoicing= '[data-invoicing="electronic-invoicing"]';

		/* ARRAY ID FOR NEW FIELD ELECTRONIC INVOICE */
		var ei_id=['106'];



	function initInvoiceStatus() {
      if(!window.ei_active){
          ei_id=[];
      }
			$( SELECTOR ).each( function () {
				changeInvoiceStatus( this );
							/* trigger  on country to check mandatory  fields*/
							$( invoiceCountrySelector ).trigger( 'change.zg.country');


			} );
		}



	function changeInvoiceStatus ( item ) {
		var $selector  = $( item ),
			$form      = $selector.closest( 'form' ),
			$container = $form.find( invoiceDataContainer ),
			$input     = $form.find( invoiceDataSelector );

		if ( $selector.is( ':checked' ) ) {
			$container.removeClass( 'hidden' ).fadeIn();
			$input.prop( 'required', true ).prop( 'disabled', false );

			//check italian mandatary field
			//check on conditional fields  if country is 106
			var $electronicInvoicing     = $form.find( electronicInvoicing );
			if(ei_id.includes($form.find(invoiceCountrySelector).val())){
				//add required prop
				$electronicInvoicing.each(function () {
						//$(this).parent('fieldset').removeClass('hidden');
						$(this).prop('disabled', false);
						$(this).prop('required', true);
						if ($(this).parent('fieldset').length !== 0) {
								$(this).parent('fieldset').removeClass('hidden');
						} else {
								$(this).parents('div.form-group').removeClass('hidden');
						}
				});
				//end conditional
			}else{
				$electronicInvoicing.prop('required',false);
				$electronicInvoicing.prop( 'disabled', true );
				//$electronicInvoicing.parent('fieldset').addClass('hidden');
				if ($electronicInvoicing.parent('fieldset').length !== 0) {
						$electronicInvoicing.parent('fieldset').addClass('hidden');
				} else {
						$electronicInvoicing.parents('div.form-group').addClass('hidden');
				}
			}

		} else {
			$container.hide();
			$input.prop( 'required', false ).prop( 'disabled', true );
		}
	}


	$( function () {
		/**
   * @method document
   * @listen change.zg.address On checkbox for request invoice change, call changeInvoiceStatus function
   */
  $( document ).on( 'change.zg.address', SELECTOR, function () {
     changeInvoiceStatus( this );
  } );

      /* on country change check on mandatory fields */

      $( document ).on( 'change.zg.country', invoiceCountrySelector, function (e) {
            if($(e.target).attr('data-zg-role')==='country-id') {
                e.preventDefault();
	var $formM  = $(invoiceCountrySelector).closest('form');
	var $checkBox = $formM.find(SELECTOR);
                //hide and show electronic invoicing field if country is 106
							
                if ($checkBox.is( ':checked' ) && ei_id.includes($(e.target).val())  && ei_id.length !== 0) {
                    $(SELECTOR).closest('form').find(electronicInvoicing).each(function () {
                        $(this).prop('required', true);
                        $(this).prop('disabled', false);

                        if ($(this).parent('fieldset').length !== 0) {
                            $(this).parent('fieldset').removeClass('hidden');
                        } else {
                            $(this).parents('div.form-group').removeClass('hidden');
                        }
                    });
                    var $condInvoicing=$(SELECTOR).closest('form').find(invoiceConditionalFields);
                    //condId=$condInvoicing.attr('id');
                    $condInvoicing.each(function(){
                        $(this).trigger('change.zg.addressInvoicing');
                    });
                } else {

                    $(SELECTOR).closest('form').find(electronicInvoicing).each(function () {

                        $(this).prop('required', false);
                        $(this).prop('disabled', true);

                        if ($(this).parent('fieldset').length !== 0) {
                            $(this).parent('fieldset').addClass('hidden');
                        } else {
                            $(this).parents('div.form-group').addClass('hidden');
                        }
                    });

                    $(SELECTOR).closest('form').find(electronicInvoicing).attr('value','');
                    $(SELECTOR).closest('form').find(electronicInvoicing).prop('disabled',false);
                }

            }
        } );



      /*  */

      $( document ).on( 'change.zg.addressInvoicing',invoiceConditionalFields  , function (event) {
          console.log('change cond');
          if($(event.target).attr('data-conditional')  ) {
              console.log($(event.target).val());
              if ($(event.target).prop('disabled') === false) {
                  if ($(event.target).val() !== '' || $('#' + $(event.target).attr('data-conditional')).val() !== '') {
                      $(event.target).prop('required', false);
                      $('#' + $(event.target).attr('data-conditional')).prop('required', false);
                  } else {
                      $('#' + $(event.target).attr('data-conditional')).prop('required', true);
                      $(event.target).prop('required', true);
                  }
              }
          }
      } );
     //if invoicing is selected in italian country and the mandatory fields is empty
      $('[data-ei-missing="true"]').each( function () {
          /*cookie for check electronic invoicing*/
              if(location.href.substr(location.href.lastIndexOf('?'))=== '?ei=empty'|| zgReadCookie('electronic_invoicing') !== 'true'){
              zgCreateCookie('electronic_invoicing','true');
              $(this).find('a').trigger('click');
              setTimeout(function(){
                  $('.modal.fade.in .modal-title').html(window.JS_TRANSLATIONS['ei.title.modal']);
                  $('.modal.fade.in .modal-body').find('.columns:first-child').addClass('hidden');
              },500);
          }
      });


		// address form added to the page
		$( document ).on( 'zg.addressForm.ready', initInvoiceStatus );

		// start script on page load
		initInvoiceStatus();
	} );

})( jQuery );
