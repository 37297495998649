/* ============================================================
 * create an object to be used by the filter plugin
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function () { /* global _, DEBUG, JS_TRANSLATIONS */
	"use strict";

	// Establish the root object (`window` in the browser)
	var root = this;


	/**
	 *
	 * @param {Object}  collection
	 * @param {Array=}  filters
	 * @param {string=} pattern
	 * @param {Object=} initial
	 *
	 * @returns {null|Object}
	 */
	function createFilterObject ( collection, filters, pattern, initial ) {
		var result = initial;

		result = __createFilters( collection, filters, result );
		result = __createFiltersByPattern( collection, pattern, result );

		return result;
	}

	/**
	 *
	 * @param {Object}  collection
	 * @param {Array}   filters
	 * @param {Object=} result
	 * @returns {*}
	 * @private
	 */
	function __createFilters ( collection, filters, result ) {
		var filter, i;

		_.each( filters, function ( item ) {
			filter = __createSingleFilter( collection, item );

			if ( filter ) {
				result                = result || {};
				result[( filter.id )] = filter;
			}
		} );

		return result;
	}


	/**
	 *
	 *
	 * @param {Object}  collection
	 * @param {String}  pattern
	 * @param {Object=} result
	 * @returns {*}
	 * @private
	 */
	function __createFiltersByPattern ( collection, pattern, result ) {
		var filter, properties, rx;

		if ( pattern && _.isString( pattern ) ) {
			properties = [];
			rx         = new RegExp( pattern );

			// We have to go through all the CMS contents in order to create the filters by pattern, because if a
			// content has no value for a field we won't get that field for that content.  (T-T) The double loop is
			// unavoidable, but we are doing some checks to stop unnecessary functions calls. This seems to be alright
			// for even for collections with a couple of hundreds of elements ...

			_.each( collection, function ( element ) {
				// if the element has fields (is a CMS content) iterate through them
				_.each( ( element.fields || element ), function ( item, prop ) {
					if ( item.hasOwnProperty( prop ) && !properties[prop] ) {
						// the only purpose of this array is to avoid checking each property several times
						properties.push( prop );

						if ( !result[prop] && rx.test( prop.toLowerCase() ) ) {
							filter = __createSingleFilter( collection, { property: prop } );

							if ( filter ) {
								result                = result || {};
								result[( filter.id )] = filter;
							}
						}
					}

				} );
			} );
		}

		return result;
	}


	/**
	 *
	 * @param {Array|Object} collection
	 * @param {Object}       options
	 * @returns {*}
	 */
	function __createSingleFilter ( collection, options ) {
		var namespace, response, temp, values;

		if ( _.isString( options.property ) ) {

			values = _getValues( collection, options );

		} else if ( _.isArray( options.property ) ) {
			//we are dealing with an array of properties ( a filter composed of several properties )

			temp = _.extend( {}, options );

			// get the values for each property. assign all of them to the same value
			_.each( options.property, function ( prop ) {
				temp.property = prop;
				namespace     = root.zgParseString( prop, false, '_' );

				values = _getValues( collection, temp, namespace, values );
			} );

		} else if ( DEBUG ) {

			console.log( 'createFilterObject - ERROR: invalid property' );

		}

		if ( values && !_.isEmpty( values ) ) {
			response        = _createResponse( options );
			response.values = values;
		}

		return response;
	}


	/**
	 *
	 * @param {Object=} options
	 * @returns {{id: string, code: string, name: string, type: string, has_image: boolean, values: Object}}
	 * @private
	 */
	function _createResponse ( options ) {
		var name = options.name || options.id || options.property || '';
		
		return {
			id: root.zgParseString( (options.id || options.property), false, '_' ),

			code: options.code || options.id || options.property || '',
			name: JS_TRANSLATIONS[name] || name,

			type: options.type || 'custom',

			has_image: options.has_image || false,

			values: {}
		};
	}


	/**
	 *
	 * @param {Array|Object} collection
	 * @param {Object}       options
	 * @param {string}       namespace
	 * @param {Object=}      initial
	 * @returns {{}}
	 * @private
	 */
	function _getValues ( collection, options, namespace, initial ) {
		var values = initial || {};

		_.each( collection, function ( item, key ) {
			var i, escaped, itemId, items, name, val, value;

			itemId = item.id || key;

			// Properties in the CMS content object are inside the 'fields' property
			value = item[options.property] || ( item.fields && item.fields[options.property] ) || null;

			if ( value ) {
				if ( _.isNumber( value ) ) {
					value = '' + value;
				}

				if ( _.isString( value ) ) {
					// each property could have several values divided by semicolon.
					value = value.split( ';' );
				}

				if ( _.isArray( value ) ) {
					for ( i = 0; i < value.length; i++ ) {
						val = value[i];

						escaped = root.zgParseString( value[i], false, '_' );

						if ( namespace && ( val === '1' || namespace === escaped ) ) {
							// special case.
							name    = escaped;
							escaped = escaped.toLowerCase();
						} else {
							name    = (namespace || '') + value[i];
							escaped = (namespace || '') + escaped.toLowerCase();
						}

						if ( options.useTranslations ) {
							name = JS_TRANSLATIONS[name] || name;
						}

						if ( !values[escaped] ) {
							values[escaped] = {
								name:     name,
								products: [] // The filter plugin was designed to be used for products in the category
											 // page  :(
							};
						}

						items = values[escaped].products || [];

						items.push( itemId );

						// we need the items ids to be unique and sorted
						values[escaped].products = _.uniq( items.sort(), true );
					}
				}
			}
		} );

		return values;
	}


	// -----------------------------------------------------------------------------------------------------------------


	root.zgCreateFilterObject = createFilterObject;

}.call( this ));
