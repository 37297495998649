/* ============================================================
 * zg-newsletter-preferences.js
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 *
 * ============================================================ */

(function ( $ ) {

	"use strict";

	var toggle             = '[data-zg-role="newsletter-preferences"]';
	var accept_toggle      = '[data-zg-role="newsletter-accept"]';
	var user_id_toggle     = '[data-zg-role="newsletter-user-id"]';
	var merchant_id_toggle = '[data-zg-role="newsletter-merchant-id"]';

	var ZG_NewsletterPreferences = function ( element, config ) {
		var self = this;
		this.init = function ( element, config ) {
			var $element     = $(element);
			self.$eventBus   = $(document);
			self.$element    = $element;
			self.$accept     = self.$element.find( accept_toggle );
			self.$userId     = self.$element.find( user_id_toggle );
			self.$merchantId = self.$element.find( merchant_id_toggle );

			self.$element.on('submit', self.savePreferences );
		};
		this.savePreferences = function ( event ) {
			event.preventDefault();
			event.stopPropagation();
			if ( self.$element.data('zgValidator') ) {
				if ( !self.$element.data('zgValidator').validate() ) {
					return false;
				}
			}
			var request = {
				"accept_newsletter": self.$accept.is(':checked') ? self.$accept.val() : 0,
				"userId"           : self.$userId.val(),
				"merchantId"       : self.$merchantId.val()
			};
			var url = window.makeUrl({module: 'eshop', manager: 'eshop', action: 'updateNewsletterPreferences'});
			$.post( url, request, function ( response ) {
				if ( response.status == "success" ) {
					self.$eventBus.trigger('zg-notification', [{
						eventType: 'newsletter-preferences-saved',
						message: response.message || 'Newsletter have been saved'
					}]);
				} else {
					self.$eventBus.trigger('zg-error', [{
						eventType: 'newsletter-preferences-saved',
						message: response.message || 'Newsletter could not be saved'
					}]);
				}
			}, 'json');
			return false;
		};
		this.init( element, config );
	};

	$.fn.zg_newsletter_preferences = function ( config ) {
		return this.each( function () {
			var $this = $(this);
			var data  = $this.data('zgNewsletterPreferences');
			if (!data) {
				$this.data('zgNewsletterPreferences', (data = new ZG_NewsletterPreferences(this, config)) );
			}
		} );
	};

	$(function () {
		$('body').find( toggle ).zg_newsletter_preferences( window.ZG_CONFIG );
	});

})( jQuery );