(function($) { /* global ZG_ALIAS_CORE */
    "use strict";

    // ZG_ALIAS_CORE should be defined in __common_js
    // if( ZG_ALIAS_CORE && ZG_ALIAS_CORE === 'country' ){
    //     $(function() {
    var changeLanguage = '[data-zg-role="zg-language"]',
        languageLinks  = '[data-zg-language]';

    $( changeLanguage ).on( "click.zg.changeLanguage", languageLinks, function ( e ) {
        var lang = $(this).data('lang'),
            $formDOM = null;

        if ( lang ) {
            e.preventDefault();

            $formDOM = $('<form></form>')
                            .attr('method', 'POST')
                            .append(
                                $('<input />')
                                .attr('name', 'lang')
                                .val( lang )
                            );

            $formDOM.appendTo( $('body') ).submit();
        }
    });
    //     });
    // }
})(jQuery);
