/* ============================================================
 * zg-ticket.js
 *
 * @author: Botond Szasz <bszasz@zerogrey.com>
 *
 * ============================================================ */

(function ( $ ) {
	"use strict";

	// TODO: Refactor!!!!
	//       This code is crazy

	var toggle = '[data-zg-role="ticket"]';
	var ticket_status = '[data-zg-role="ticket-status"]';
	var ticket_form_toggle = '[data-zg-role="ticket-reply-form"]';
	var ticket_message_toggle = '[data-zg-role="ticket-message"]';
	var ticket_parent_id_toggle = '[data-zg-role="ticket-parent-id"]';
	var ticket_reply_collection_toggle = '[data-zg-role="ticket-reply-collection"]';
	var ticket_reply_toggle = '[data-zg-role="ticket-reply"]';
	var ticket_reply_user_toggle = '[data-zg-role="ticket-reply-user"]';
	var ticket_reply_date_toggle = '[data-zg-role="ticket-reply-date"]';
	var ticket_reply_message_toggle = '[data-zg-role="ticket-reply-message"]';

	var ZG_Ticket = function ( element ) {
		var self = this;
		this.init = function ( element ) {
			self.$element = $( element );
			self.$eventBus = $( document );

			self.$status = self.$element.find( ticket_status );
			self.$form = self.$element.find( ticket_form_toggle );
			self.$message = self.$form.find( ticket_message_toggle );
			self.$parent_id = self.$form.find( ticket_parent_id_toggle );
			self.$ticketReplyClone = self.$element.find( ticket_reply_collection_toggle ).find( 'li:eq(0)' ).clone();
			self.$ticketReplyClone.data( 'zgRole', 'ticket-reply' );

			self.$form.on( 'submit', function ( event ) {
				var messageCheck = self.$message.val();

				event.preventDefault();

				if ( messageCheck ) {
					self.saveReply();
				}
			} );
		};

		this.saveReply = function () {
			var request = {
				"parent_ticket_id": self.$parent_id.val(),
				"message": self.$message.val()
			};

			var url = window.makeUrl( { module: 'eshop', manager: 'eshop', action: 'insertTicket' } );

			$.getJSON( url, request, function ( response ) {
				// This AJAX request already uses the status variable for something else ;_;
				if ( response.error === "" ) {
					self.$eventBus.trigger( 'zg-notification', [{
						eventType: 'ticket-save',
						message: window.JS_TRANSLATIONS.ticket_have_been_saved || 'Ticket have been saved'
					}] );
					self.$ticketReplyClone.find( ticket_reply_user_toggle ).text( response.user_email );
					self.$ticketReplyClone.find( ticket_reply_date_toggle ).text( response.date );
					self.$ticketReplyClone.find( ticket_reply_message_toggle ).text( response.ticket_text );
					self.$ticketReplyClone.clone().hide().insertAfter( self.$element.find( ticket_reply_toggle ).last() ).fadeIn();
					self.$message.val( '' );
					self.$status.removeClass( 'label-info' ).addClass( 'label-warning' ).text( response.status );
				} else {
					self.$eventBus.trigger( 'zg-error', [{
						eventType: 'ticket-save',
						message: window.JS_TRANSLATIONS.ticket_could_not_be_saved || 'Ticket could not be saved'
					}] );
				}
			} );
		};
		this.init( element );
	};

	$.fn.zg_ticket = function () {
		return this.each( function () {
			var $this = $( this );
			var data = $this.data( 'zgTicket' );
			if ( !data ) {
				$this.data( 'zgTicket', (data = new ZG_Ticket( this )) );
			}
		} );
	};

	$( function () {
		$( toggle ).zg_ticket();
	} );

})( jQuery );


/* ============================================================
 * Ticket Atachment
 *
 *
 * ============================================================ */


/**
 * Add more attachments on demand.
 *
 */
( function ( $ ) { /* global jQuery, handlebarsTemplates */
	"use strict";

	var selector = '[data-zg-role="ticket-attachment-add"]',
		container = '[data-zg-role="ticket-attachment-container"]',
		template = 'ticket-attachment';

	$( document ).on( 'click', selector, function ( e ) {
		var $this = $( this ),
			cont = $this.data( 'container' ) || container,
			templ = $this.data( 'template' ) || template;

		e.preventDefault();

		$( cont ).append( handlebarsTemplates.render( templ, {} ) );
	} );

}( jQuery ) );
