/* ==================================================================================================================
 * exportMissingGeolocation
 *
 *    Create a table with all the stores with missing geolocation.
 *    Creates a csv file to be imported in the CMS.
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ================================================================================================================== */

(function () { /* global _, DEBUG, isGoogleMapsAvailable, google */
	'use strict';

	var root = this;


	// CLASS DEFINITION
	// ================

	var CenterMap = function ( storeLocator ) {
		this.storeLocator = storeLocator;
	};


	/**
	 *
	 * @param {string}  address
	 * @param {number=} zoomIn
	 */
	CenterMap.prototype.byAddress = function ( address, zoomIn ) {
		var that;

		if ( _.isString( address ) && this.storeLocator.map && isGoogleMapsAvailable() ) {
			that = this;

			if ( !this.storeLocator.geocoder ) {
				this.storeLocator.geocoder = new google.maps.Geocoder();
			}

			this.storeLocator.geocoder.geocode( { 'address': address }, function ( results, status ) {
				if ( status === google.maps.GeocoderStatus.OK ) {
					that.byLocationObject( results[0].geometry.location );

					if ( _.isNumber( zoomIn ) && that.map.getZoom() < zoomIn ) {
						that.map.setZoom( zoomIn );
					}
				}
			} );

		} else if ( DEBUG ) {

			console.log( "StoreLocator.centerMap.byAddress - FAILED" );

		}
	};


	/**
	 *
	 * @param {string|number} lat
	 * @param {string|number} lng
	 * @param {number=}       zoomIn
	 */
	CenterMap.prototype.byCoordinates = function ( lat, lng, zoomIn ) {
		var location;

		if ( this.storeLocator.map && isGoogleMapsAvailable() ) {

			location = new google.maps.LatLng( lat || 0, lng || 0 );

			// set the coordinates
			if ( location ) {
				this.storeLocator.map.setCenter( location );
			}

			if ( _.isNumber( zoomIn ) && this.storeLocator.map.getZoom() < zoomIn ) {
				this.storeLocator.map.setZoom( zoomIn );
			}

		} else if ( DEBUG ) {

			console.log( "StoreLocator.centerMap.byCoordinates - FAILED" );

		}
	};


	/**
	 *
	 * @param {Object}  location
	 * @param {number=} zoomIn
	 */
	CenterMap.prototype.byLocationObject = function ( location, zoomIn ) {
		if ( _.isObject( location ) && this.storeLocator.map && isGoogleMapsAvailable() ) {

			this.storeLocator.map.setCenter( location );

			if ( _.isNumber( zoomIn ) && this.storeLocator.map.getZoom() < zoomIn ) {
				this.storeLocator.map.setZoom( zoomIn );
			}

		} else if ( DEBUG ) {

			console.log( "StoreLocator.centerMap.byLocationObject - FAILED" );

		}
	};

	/**
	 *
	 *
	 */
	CenterMap.prototype.onDefaultLocation = function () {
		if ( this.storeLocator.map && isGoogleMapsAvailable() ) {

			if (
				this.storeLocator.options.defaultStore &&
				this.storeLocator.options.stores &&
				this.storeLocator.options.stores[this.storeLocator.options.defaultStore]
			) {

				// center the map in a specific store.
				// useful for the single store pages.
				this.onStore( this.storeLocator.options.defaultStore );

			} else if ( this.storeLocator.options.centerOnUser ) {

				// Try to center the map in the user location
				this.onUser( this.storeLocator.options.defaultStore );

			} else if ( this.storeLocator.options.defaultCountry ) {

				// Try to center the map default (current?) country
				this.byAddress( this.storeLocator.options.defaultCountry );

			}

		} else if ( DEBUG ) {

			console.log( 'StoreLocator.centerMap.onDefaultLocation - FAILED' );

		}
	};


	/**
	 *
	 * @param {string} storeId
	 */
	CenterMap.prototype.onStore = function ( storeId ) {
		if ( storeId && this.storeLocator.options.stores && this.storeLocator.options.stores[storeId] ) {
			if ( this.storeLocator.mapMarkers[storeId] ) {

				// Get position form the marker if it exists
				this.byLocationObject( this.storeLocator.mapMarkers[storeId].getPosition(), this.storeLocator.options.storeZoomLevel );

			} else if (
				!isNaN( this.storeLocator.options.stores[storeId].fields.Latitude ) &&
				!isNaN( this.storeLocator.options.stores[storeId].fields.Longitude )
			) {

				// Center the map in the store location.
				this.byCoordinates(
					+this.storeLocator.options.stores[storeId].fields.Latitude,
					+this.storeLocator.options.stores[storeId].fields.Longitude,
					this.storeLocator.options.storeZoomLevel // zoomIn
				);

			} else {

				this.storeLocator.requestStoreLocation( storeId, 0, _.bind( this.onStore, this, storeId ) );

			}
		}
	};


	/**
	 * Use W3C Geolocation API to center position on the user
	 *
	 */
	CenterMap.prototype.onUser = function ( fallback ) {
		var isCentered, that;

		// W3C Geolocation
		if ( 'geolocation' in navigator ) {
			isCentered = false;
			that       = this;

			navigator.geolocation.getCurrentPosition(
				// success
				function ( position ) {
					if ( position && position.coords ) {
						that.byCoordinates( position.coords.latitude, position.coords.longitude, that.storeLocator.options.storeZoomLevel );
						isCentered = true;
					}
				},
				// error
				function ( error ) {
					// set the center in the default (current?) country.
					// That way if the user does not allow geolocation -or is not enabled on page load- at least we
					// are in the right part of the world.
					if ( fallback ) {
						that.byAddress( fallback );
						isCentered = true;
					}

					if ( DEBUG ) {
						console.log( 'CenterMap.onUser - FAILED', error );
					}
				},
				// options
				{}
			);

			// center the map in the default country if the user does not accepted to share location (or ignores the
			// prompt)
			if ( fallback ) {
				setTimeout( function () {
					if ( !isCentered ) {
						that.byAddress( fallback );
					}
				}, 500 );
			}
		} else if ( fallback ) {
			this.byAddress( fallback );
		}
	};


	// -----------------------------------------------------------------------------------------------------------------

	root.ZgStoreLocatorCenterMap = CenterMap;

}.call( this ));
