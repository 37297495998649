/* ============================================================
 * zg-adyen.js
 *
 * @author: P V<pvignola[at]zerogrey.com>
 *
 * ============================================================ */

(function ($) { "use strict";

    var toggle               = '[data-zg-role="zg-adyen"]',
        hasAdyenDetails      = '[data-zg-role="hasAdyenDetails"]',
        noAdyenDetails       = '[data-zg-role="noAdyenDetails"]',
        tplAdyenTable        = '[data-zg-role="tplAdyenTable"]',
        removeAdyen          = '[data-zg-role="removeAdyen"]',

        /**
         * Main function
         *
         * @param {Element}  element
         */
        ZG_Adyen = function (element) {
            var self = this;

            /**
             * Initialise the module
             *
             * @param {Element} element
             * @param {Object}  config
             */
            this.init = function (element) {
                var $element          = $(element);
                self.$element         = $element;
                self.$eventBus        = $(document);
                self.$hasAdyenDetails = $element.find( hasAdyenDetails );
                self.$noAdyenDetails  = $element.find( noAdyenDetails );
                self.$tplAdyenTable   = $element.find( tplAdyenTable );

                self.printTable();
            };

            /**
             * Print table in order details
             */
            this.printTable = function () {
                var url = window.makeUrl({module: 'eshop', manager: 'eshop', action: 'getAdyenCreditCardDetails'});
                var listCards = {
                    diners:          "Diners Club",
                    discover:        "Discover",
                    amex:            "American Express",
                    mc:              "MasterCard",
                    visa:            "Visa",
                    directEbanking:  "Sofortüberweisung",
                    bcmc:            "Bancontact/Mister Cash",
                    maestro:         "Maestro",
                    ideal:           "Ideal",
                    dotpay:          "Dotpay",
                    ebanking_FI:     "Finnish E-Banking",
                    giropay:         "GiroPay",
                    trustly:         "Trustly"
                };
                $.ajax({
                    type : 'post',
                    url : url,
                    dataType : 'json',
                    success : function (response) {
                        if (response && response.response.recurringDetailsResult_details_0_recurringDetailReference) {
                            self.$hasAdyenDetails.removeClass('hidden');
                            var length = -1;
                            $.each(response.response, function(index, value) {
                                if (index.indexOf("recurringDetailsResult_details_") >= 0){
                                    length = +(index.replace('recurringDetailsResult_details_','')[0]);
                                }
                            });
                            for (var i = 0; i < length+1; i++){
                                var recurringDetailReference = 'recurringDetailsResult_details_'+i+'_recurringDetailReference';
                                var variant                  = 'recurringDetailsResult_details_'+i+'_variant';
                                var card_number              = 'recurringDetailsResult_details_'+i+'_card_number';
                                var card_expiryMonth         = 'recurringDetailsResult_details_'+i+'_card_expiryMonth';
                                var card_expiryYear          = 'recurringDetailsResult_details_'+i+'_card_expiryYear';
                                var card_holderName          = 'recurringDetailsResult_details_'+i+'_card_holderName';
                                var identifier               = response.response[ recurringDetailReference ];
                                var card_number              = '************'+response.response[ card_number ]; //Last four digits
                                var variant                  = listCards[ response.response[ variant ] ]; //From listCards
                                var card_expire              = response.response[ card_expiryMonth ]+ '/' +response.response[ card_expiryYear ];
                                var card_holderName          = response.response[ card_holderName ];
                                self.$hasAdyenDetails.find('tbody').append( self.$tplAdyenTable.clone().removeAttr("data-zg-role").removeClass('hidden').attr( 'data-role', identifier ) );
                                $("[data-role='" + identifier + "']")
                                    .find('[data-role="variant"]').html( variant ).end()
                                    .find('[data-role="card_number"]').html( card_number ).end()
                                    .find('[data-role="card_expire"]').html( card_expire ).end()
                                    .find('[data-role="card_holderName"]').html( card_holderName ).end();
                            }
                            self.$tplAdyenTable.first().addClass('hidden');
                            self.$hasAdyenDetails.removeClass('loading');

                            self.$eventBus.on('click',removeAdyen,function(e){
                                self.removeCard( $(this) );
                            });
                        }
                        else{
                            self.$noAdyenDetails.removeClass('hidden');
                        }
                    },
                    error : function () {
                        self.$noAdyenDetails.removeClass('hidden');
                        self.$eventBus.trigger('zg-error', [{
                            eventType: 'zg-adyen',
                            message: ( window["JS_TRANSLATIONS"]["error.getAdyenCreditCardDetails"] )
                        }]);
                    }
                });
            };

            /**
             * Remove Adyen card details
             */
            this.removeCard = function ( $button ) {
                var url = window.makeUrl({module: 'eshop', manager: 'eshop', action: 'disableAdyenCreditCard'});
                var $elementTr = $button.closest('tr'),
                    idRequest  = $button.closest('tr').data('role'),
                    request    = {
                        "recurring_ref" : idRequest
                    };
                $.ajax({
                    type : 'post',
                    url : url,
                    dataType : 'json',
                    data : request,
                    success : function (response) {
                        if (response && response.success === "true") {
                            $elementTr.remove();
                            self.$eventBus.trigger('zg-notification', [{
                                eventType: 'zg-adyen',
                                message: ( window["JS_TRANSLATIONS"]["success.remove.getAdyenCreditCardDetails"] )
                            }]);
                        }
                        else{
                            self.$eventBus.trigger('zg-error', [{
                                eventType: 'zg-adyen',
                                message: ( window["JS_TRANSLATIONS"]["error.remove.getAdyenCreditCardDetails"] )
                            }]);
                        }
                    },
                    error : function () {
                        self.$eventBus.trigger('zg-error', [{
                            eventType: 'zg-adyen',
                            message: ( window["JS_TRANSLATIONS"]["error.remove.getAdyenCreditCardDetails"] )
                        }]);
                    }
                });
            };


            this.init(element);
        };


    $.fn.zg_adyen = function () {
        return this.each(function () {
            var $this = $(this),
                data = $this.data('zgAdyen');
            if (!data) {
                $this.data('zgAdyen', (data = new ZG_Adyen(this) ));
            }
        });
    };

    $(function () {
        $(toggle).zg_adyen();
    });

})(jQuery);