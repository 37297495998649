$( function () {
    if( !$('[data-zg-role="load-ajax-file"]').length )
        return false;

    function loadAjaxFile ( fileName, container ){

        var url = window.makeUrl({module: 'eshop', manager: 'info', on: fileName, master : 'master_blank', skip_redir: '1'});
        var containerSelector = $('#' + container);

        $.ajax({
            url: url,
            cache: false,
            dataType: "html",

            beforeSend: function ( xhr ) {
                xhr.setRequestHeader('X-Requested-With', 'empty');
                containerSelector.empty().addClass('loading');
            },

            success: function ( responseText ) {
                containerSelector.empty().append( responseText );
            },

            complete: function () {
                containerSelector.removeClass('loading');
            }

        });
    }

    $( document).on( 'click', '[data-zg-role="load-ajax-file"]', function(e){
        var fileName = $(this).data('ajax-file-name');
        var container = $(this).data('ajax-container');
        loadAjaxFile(fileName, container);
    });
});
