/* ============================================================
 * ADDRESS LIST
 *
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $ ) { /* global _, handlebarsTemplates, DEBUG, JS_TRANSLATIONS, zg_sortElements, zgGet, zgPost, ZG_CONFIG */
	'use strict';

	var selector = '[data-zg-role="address-list"]';


	// ADDRESS LIST CLASS DEFINITION
	// =============================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var AddressList = function ( element, options ) {
		this.$element = $( element );

		this.addressList   = null;
		this.countriesList = null;

		this.$addressContainer     = null;
		this.$editAddressContainer = null;
		this.$newAddressContainer  = null;

		this.options = _.clone( AddressList.DEFAULTS );
		this.__setOptions( options );

		this.__setEventHandlers();
	};

	AddressList.DEFAULTS = {
		// Handlebars template for the address list
		addressTemplate: 'address',

		// Container where the list should be inserted.
		// By default is the same element that we use to initialize the script.
		addressContainer: selector,

		// Selector for the elements that will trigger the different actions
		addressActionTrigger: '[data-zg-role="address-action"]',

		// selector for the address EDIT/ADD form
		addressForm: '[data-zg-role="address-form"]',

		// By default (false) it will just fetch the list of addresses again
		// Set as true to reload the page when saving an address.
		// Set as a URL to redirect to that page on save. - CAUTION! The URL will not be validated!
		addressReloadOnSave: false,

		// Added possibility to have 2 different templates and containers for EDIT and ADD addresses with the same
		// values as default.
		// That way we could have different behaviors and/or looks for the 2 actions (e.g. ADD embedded in the page
		// and EDIT in a modal window)
		editAddressTemplate:  'address-form',
		editAddressContainer: '[data-zg-role="address-form-container"]',
		newAddressTemplate:   'address-form',
		newAddressContainer:  '[data-zg-role="address-form-container"]',

		// If true automatically create the ADD address form on page load
		newAddressAutoCreate: false,

		// Initial data for the ADD address form
		newAddressInitialData: null,

		// List of countries
		// This information is only available as a smarty value so it will be set in ZG_CONFIG
		countriesList: null,

		// hide the address container if there are no addresses
		//hideIfEmpty: false,

		// shippingAddressId is the id of the address configured as shipping for the current session (might be
		// different from the default shipping).
		// This information is only available as a smarty value so it will be set in ZG_CONFIG
		shippingAddressId: null,

		// sort addresses based on address type
		addressesSorting: ['billing', 'default_shipping', 'other']
	};


	/**
	 * Get Address information from the list by Id
	 *
	 * @param {string|number} addressId
	 *
	 * @returns {Object|undefined}
	 */
	AddressList.prototype.getAddressById = function ( addressId ) {
		return ( addressId && this.addressList ) && _.findWhere( this.addressList, { address_id: '' + addressId } );
	};


	/**
	 *
	 * @returns {Array|undefined}
	 */
	AddressList.prototype.getAddressList = function () {
		return this.addressList;
	};


	// ADDRESS LIST PRIVATE METHODS
	// ============================

	/**
	 * remove Address by Id
	 *
	 * @param {string|number} addressId
	 * @private
	 */
	AddressList.prototype.__actionDelete = function ( addressId ) {
		if ( addressId ) {
			zgPost(
				'deleteAddress',
				{ address_id: addressId },
				{ error: JS_TRANSLATIONS.address_could_not_be_deleted },
				{ complete: _.bind( this.__fetch, this ) }
			);
		} else if ( DEBUG ) {
			console.error( 'AddressList.__actionDelete FAILED - please specify an addressId' );
		}
	};


	/**
	 * Edit address by Id
	 *
	 * @param {string|number} addressId
	 * @private
	 */
	AddressList.prototype.__actionEdit = function ( addressId ) {
		var data;

		if ( addressId && this.addressList ) {
			data = this.getAddressById( addressId );

			if ( data ) {
				data.action        = 'updateAddress';
				data.countriesList = this.countriesList;

				this.$editAddressContainer.html( handlebarsTemplates.render( this.options.editAddressTemplate, data ) );
				$( document ).trigger( 'zg.addressForm.ready', [data] );
			}
		}
	};


	/**
	 * set Address as the default shipping
	 *
	 * @param {string|number} addressId
	 * @private
	 */
	AddressList.prototype.__actionMakeDefault = function ( addressId ) {
		if ( addressId ) {
			zgPost(
				'makeDefaultAddress',
				{ address_id: addressId },
				null,
				{ complete: _.bind( this.__fetch, this ) }
			);
		} else if ( DEBUG ) {
			console.error( 'AddressList.__actionMakeDefault FAILED - please specify an addressId' );
		}
	};


	/**
	 * create new shipping address
	 *
	 * @param {Object=} initial
	 * @private
	 */
	AddressList.prototype.__actionNew = function ( initial ) {
		var data = _.extend(
			{},
			_.isObject( this.options.newAddressInitialData ) ? this.options.newAddressInitialData : {},
			_.isObject( initial ) ? initial : {}
		);

		data.action              = 'addNewAddress';
		data.address_id          = 'new_address';
		data.countriesList       = this.countriesList;
		data.enableInvoiceFields = !(this.addressList && this.addressList.length > 1);

		this.$newAddressContainer.html( handlebarsTemplates.render( this.options.newAddressTemplate, data ) );
		$( document ).trigger( 'zg.addressForm.ready', [data] );
	};


	/**
	 *
	 * @param {Array} formData
	 * @private
	 */
	AddressList.prototype.__actionSave = function ( formData ) {
		var request = {};

		_.each( formData, function ( item ) {
			request[item.name] = item.value;
		} );

		if ( request.action ) {
			zgPost(
				request.action,
				request,
				{
					error:   JS_TRANSLATIONS.address_could_not_be_saved,
					success: JS_TRANSLATIONS.address_have_been_saved
				},
				{ complete: _.bind( this.__onSavedAddress, this ) }
			);
		} else if ( DEBUG ) {
			console.error( 'AddressList.__actionSave FAILED - missing action' );
		}
	};


	/**
	 * Get the list of addresses
	 *
	 * @private
	 */
	AddressList.prototype.__fetch = function () {
		this.$addressContainer
			.addClass( 'loading' )
			.empty();

		this.addressList = null;

		zgGet(
			'fetchAddresses',
			null,
			null,
			{
				complete: _.bind( function () {
					this.$addressContainer.removeClass( 'loading' );
				}, this ),
				success:  _.bind( this.__parse, this )
			}
		);
	};


	AddressList.prototype.__init = function () {
		this.__fetch();
		this.__parseCountries();
	};


	/**
	 * check if the Default shipping address is the same as the billing address
	 *
	 * @returns {boolean}
	 * @private
	 */
	AddressList.prototype.__isSameAddressDefaultShipping = function () {
		return this.addressList && _.findWhere( this.addressList, { type: 'default_shipping', same_address: 1 } );
	};


	/**
	 * handle the post-commit action
	 *
	 * @private
	 */
	AddressList.prototype.__onSavedAddress = function () {
		if ( this.options.addressReloadOnSave === true ) {
			location.reload();
		} else if ( _.isString( this.options.addressReloadOnSave ) ) {
			location.assign( this.options.addressReloadOnSave );
		} else {
			this.__fetch();

			// recreate the new address form if is set as automatic
			if ( this.options.newAddressAutoCreate ) {
				this.__actionNew();
			}
		}
	};


	/**
	 *
	 * @param {Array} response
	 * @private
	 */
	AddressList.prototype.__parse = function ( response ) {
		var that;

		if ( response && _.isArray( response.data ) ) {
			that = this;

			// sort the addresses
			this.addressList = response.data.sort( zg_sortElements( {
				attr:    'type',
				pattern: this.options.addressesSorting
			} ) );

			// parse the addresses to add the correct actions
			_.each( this.addressList, function ( address ) {
				if ( address.type === 'billing' ) {

					address.actionEdit        = true;
					address.actionMakeDefault = !that.__isSameAddressDefaultShipping();
					address.actionDelete      = false;
					// enable invoice fields for the edit action
					address.enableInvoiceFields = true;
					address.isCurrentShipping   = false;

				} else if ( address.type === 'default_shipping' ) {

					address.actionEdit          = !address.same_address;
					address.actionMakeDefault   = false;
					address.actionDelete        = false;
					address.enableInvoiceFields = false;
					// shippingAddressId is not set. We use 'default_shipping' as the 'isCurrentShipping' address
					address.isCurrentShipping = !(that.options.shippingAddressId);

				} else if ( address.type === 'other' ) {

					address.actionEdit          = true;
					address.actionMakeDefault   = true;
					address.actionDelete        = true;
					address.enableInvoiceFields = false;
					address.isCurrentShipping   = false;
				}

				// set up which is the current shipping address
				if (
					that.options.shippingAddressId &&
					address.address_id == that.options.shippingAddressId
				) {
					address.isCurrentShipping = true;
				}
			} );

			this.__render();
		}
	};


	AddressList.prototype.__parseCountries = function () {
		var id, list = [];

		this.countriesList = [];

		for ( id in this.options.countriesList ) {
			if ( this.options.countriesList.hasOwnProperty( id ) ) {
				list.push( {
					key:   id,
					value: this.options.countriesList[id]
				} );
			}
		}

		this.countriesList = list.sort( zg_sortElements( { attr: 'value' } ) );
	};


	/**
	 * Render the list of addresses
	 *
	 * @param {Array=} addressList
	 * @private
	 */
	AddressList.prototype.__render = function ( addressList ) {
		var data = addressList || this.addressList;

		this.$addressContainer.html( handlebarsTemplates.render( this.options.addressTemplate, data ) );
	};


	/**
	 *
	 * @private
	 */
	AddressList.prototype.__setEventHandlers = function () {
		var that = this;

		$( document ).on( 'click.zg.addressList', this.options.addressActionTrigger, function () {
			var data = $( this ).data();

			switch ( data.action ) {
				case 'delete' :
					that.__actionDelete( data.addressId );
					break;

				case 'edit' :
					that.__actionEdit( data.addressId );
					break;

				case 'make-default' :
					that.__actionMakeDefault( data.addressId );
					break;

				case 'new' :
					that.__actionNew( data.initial );
					break;

				default :
					$( document ).trigger( 'zg-error', [{
						eventType: 'AddressList - missing action',
						message:   JS_TRANSLATIONS.genericErrorMsg
					}] );
			}
		} );

		// -------------------------------------------------------------------------------------------------------------

		// Prepare addresss form for validation
		$( document ).on( 'zg.addressForm.ready', function () {
			$( that.options.addressForm ).zg_validate();
		} );

		// Save address form
		$( document ).on( 'submit.zg.addressList', this.options.addressForm, function ( e ) {
			var $modal, $this = $( this );

			e.preventDefault();

			if ( !$this.data( 'zgValidator' ) || $this.data( 'zgValidator' ).validate() ) {
				that.__actionSave( $this.serializeArray() );

				// auto close container modal on submit
				$modal = $this.closest( '.modal' );
				if ( $modal.length ) {
					$modal.modal( 'hide' );
				}
			}
		} );
	};


	/**
	 *
	 * @param {Object} options
	 * @private
	 */
	AddressList.prototype.__setOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );

		this.$addressContainer     = $( this.options.addressContainer );
		this.$editAddressContainer = $( this.options.editAddressContainer );
		this.$newAddressContainer  = $( this.options.newAddressContainer );

		if ( this.options.newAddressAutoCreate ) {
			this.__actionNew();
		}
	};


	// ADDRESS LIST PLUGIN DEFINITION
	// ==============================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.addressList' );
			var options = _.extend( {}, ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.addressList', ( data = new AddressList( this, options ) ) );
			} else if ( option ) {
				data.__setOptions( options );
			}

			data.__init();
		} );
	}

	$.fn.zgAddressList             = Plugin;
	$.fn.zgAddressList.Constructor = AddressList;


	// ADDRESS LIST DATA-API
	// ======================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery ));
