var FB_READY = new jQuery.Deferred();

(function ( $ ) { /* globals FB, SGL_JS_LANG, ZG_CONFIG */
	"use strict";

	// FACEBOOK SDK
	if ( window.ZG_CONFIG && ZG_CONFIG.fbAppId ) {

		window.fbAsyncInit = function () {
			FB.init( {
				appId: ZG_CONFIG.fbAppId, // App ID
				version: 'v2.0',
				//channelUrl : '//WWW.YOUR_DOMAIN.COM/channel.html', // Channel File
				status: true, // check login status
				cookie: true, // enable cookies to allow the server to access the session
				xfbml: true  // parse XFBML
			} );

			FB_READY.resolve();
		};

		// Load the SDK Asynchronously
		(function ( d ) {
			var js,
				id = 'facebook-jssdk',
				ref = d.getElementsByTagName( 'script' )[0];

			if ( !d.getElementById( id ) ) {
				js = d.createElement( 'script' );
				js.id = id;
				js.async = true;
				js.src = "//connect.facebook.net/en_US/sdk.js";
				ref.parentNode.insertBefore( js, ref );
			}
		}( document ));
	}


	// JQUERY VALIDATE TRANSLATED MESSAGES
	if ( window.SGL_JS_LANG && SGL_JS_LANG !== "en" ) {
		var lang = SGL_JS_LANG === 'pt' ? 'ptpt' : SGL_JS_LANG;

		$.getScript( "//ajax.aspnetcdn.com/ajax/jquery.validate/1.9/localization/messages_" + lang + ".js", function ( data, textStatus, jqxhr ) {} );
	}

})( jQuery );
