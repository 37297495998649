/* ============================================================================
 *
 * Add multiple products to the cart in one action.
 *
 * TODO: Improve description.
 * TODO: Add examples.
 *
 * @author: Miguel Sanchez <msanchez[at]topondemand[dot]com>
 *
 * ========================================================================= */

( function ( $ ) {
	'use strict';

	var selector = '[data-zg-role="sbl-buy-all"]';

	// SHOPBYLOOK CLASS DEFINITION
	// ===========================

	var ShopByLook = function ( element, options ) {
		this.$element = $( element );
		this.options = options;
	};

	ShopByLook.DEFAULTS = {
		target:  '[data-zg-role="sbl-items"]',
		product: '[data-zg-role="product"]'
	};

	ShopByLook.prototype.add = function () {
		var $items = $( this.options.target ).find( this.options.product );

		$.each( $items, function () {
			$( this ).data( 'zg.product' ).addToCart();
		} );
	};


	// SHOPBYLOOK PLUGIN DEFINITION
	// ============================

	var Plugin = function ( option ) {
		return this.each( function () {

			var $this  = $( this );
			var data   = $this.data( 'zg.shopByLook' );
			var options = $.extend( {}, ShopByLook.DEFAULTS, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.shopByLook', ( data = new ShopByLook( this, options ) ) );
			}

			data.add();

		});
	};

	$.fn.zgShopByLook = Plugin;
	$.fn.zgShopByLook.Constructor = ShopByLook;


	// SHOPBYLOOK DATA-API
	// ===================

	$( function () {
		$( document ).on( 'click.zg.shopByLook', selector, function () {
			Plugin.call( $( this ) );
		} );
	} );

} ( jQuery ) );
