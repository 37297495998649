/* ============================================================
 * STORE LOCATOR
 *
 *
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $ ) {
	/* global _, DEBUG, google, handlebarsTemplates, isGoogleMapsAvailable, JS_TRANSLATIONS, ZgExportMissingGeolocation,
	 zgStoreLocatorCalculateDistance, ZgStoreLocatorCenterMap, ZgStoreLocatorGetDirections, ZgStoreLocatorSetMapMarker,
	 ZgStoreLocatorSetUserMarker, ZG_SL_STORES */

	'use strict';

	var selector = '[data-zg-role="store-locator"]';


	// STORE LOCATOR CLASS DEFINITION
	// ==============================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var StoreLocator = function ( element, options ) {
		this.$element = $( element );

		this.map = null;

		/** @type {null|google.maps.Geocoder} */
		this.geocoder = null;

		// external functions
		this.centerMap                = {};
		this.getDirections            = null;
		this.setMapMarker             = {};
		this.setUserMarker            = {};
		this.exportMissingGeolocation = {};

		this.distance = {
			// position to calculate stores distance from
			origin: null
		};

		/** @type {null|Object} */
		this.geolocationTimers = null;

		/** @type {null|Object} */
		this.filters = null;

		this.mapMarkers   = {};
		this.validMarkers = null;

		this.infoWindow = null;

		/** @type {null|jQuery} */
		this.$mapContainer     = null;
		/** @type {null|jQuery} */
		this.$filtersContainer = null;
		/** @type {null|jQuery} */
		this.$storesContainer  = null;

		this.options = _.clone( StoreLocator.DEFAULTS );
		this.__setOptions( options );

		this.__setEventHandlers();
	};

	StoreLocator.DEFAULTS = {
		// Create interactive map
		createMap: true,

		// Create static map for printing
		createPrintMap: false,

		// Create a dialog to allow creating a user marker based on the current location, user address or a searched
		// string.
		createUserMarkerDialog: true,

		// -------------------------

		// export missing geolocation info. Only enable this for testing.
		exportMissingGeolocation: false,

		// -------------------------

		/* {boolean} */
		centerOnUser:   false,
		/* {boolean|string} */
		defaultCountry: null, // 'Italy',
		/* {boolean|string|number} */
		defaultStore:   null, // {string|number} StoreId

		// -------------------------

		storeZoomLevel: 15,

		// -------------------------

		elementFiltersContainer:     '[data-zg-role="filters"]',
		elementGetDirections:        '[data-zg-role="sl-get-directions"]',
		elementMapContainer:         '[data-zg-role="sl-map-container"]',
		elementPrintMap:             '[data-zg-role="sl-map-image"]',
		elementRequestStoreLocation: '[data-zg-role="sl-request-store-location"]',
		elementStoresContainer:      '[data-zg-role="pagination"]',
		elementViewOnMap:            '[data-zg-role="sl-view-on-map"]',

		// -------------------------

		markerTemplate: 'storelocator-marker-info-window',
		storeTemplate:  'storelocator-store',

		// -------------------------

		// Set up map options
		// https://developers.google.com/maps/documentation/javascript/reference#MapOptions
		mapOptions: null, // {Object}
		// https://developers.google.com/maps/documentation/javascript/reference#MapTypeStyle
		mapStyles:  null, // {Array}

		// -------------------------

		stores: null,

		// -------------------------

		// ms between geolocation requests if the shop coordinates are not available
		timer:          200,
		// The more requests we do the longer we have to wait to request more info, or google will just stop answering.
		// add 'timer' + ( 'timerIncrement' * currentRequestNumber ) to the previous request delay
		timerIncrement: 10

	};


	/**
	 *
	 * @param distanceOrigin
	 */
	StoreLocator.prototype.calculateStoresDistance = function ( distanceOrigin ) {
		var storeId;

		this.distanceOrigin = distanceOrigin;

		for ( storeId in this.options.stores ) {
			if ( this.options.stores.hasOwnProperty( storeId ) ) {
				this.__setStoreDistanceFromOrigin( storeId );
			}
		}

		// update or destroy the directions
		if ( distanceOrigin ) {
			$( this.options.elementGetDirections ).removeClass( 'hidden' ).show();

			// TODO: update current directions, but only if the origin changed ...

			storeId = this.requestedDirectionsToStore || this.options.defaultStore || null;

			if ( storeId  ) {
				this.getDirectionsToStore( storeId );
			}
		} else {
			$( this.options.elementGetDirections ).removeClass( 'hidden' ).hide();

			if ( this.getDirections ) {
				this.getDirections.clear();
			}
		}
	};


	/**
	 *
	 * @param appliedFilters
	 * @returns {*}
	 */
	StoreLocator.prototype.getAddressFromFilters = function ( appliedFilters ) {
		var address = '';

		_.each( ['Country', 'State', 'Province', 'City'], function ( filter ) {
			if ( appliedFilters[filter] ) {
				if ( address ) {
					address += ', ' + appliedFilters[filter];
				} else {
					address = appliedFilters[filter];
				}
			}
		} );

		return address;
	};


	/**
	 *
	 * @param {string} storeId
	 *
	 * @returns {null|string}
	 */
	StoreLocator.prototype.getAddressFromStore = function ( storeId ) {
		var address = null;

		if ( this.options.stores && this.options.stores[storeId] && this.options.stores[storeId].fields ) {
			address = "" +
				this.options.stores[storeId].fields.Country + ", " +
				this.options.stores[storeId].fields.Province + ", " +
				this.options.stores[storeId].fields.City + ", " +
				this.options.stores[storeId].fields.Street;
		}

		return address;
	};


	/**
	 *
	 * @param {string} storeId
	 */
	StoreLocator.prototype.getDirectionsToStore = function ( storeId ) {
		var origin, destination;

		if ( this.distanceOrigin ) {
			if ( isGoogleMapsAvailable() && this.options.stores && this.options.stores[storeId] ) {
				if ( !this.getDirections ) {
					this.getDirections = new ZgStoreLocatorGetDirections( this.options );
				}

				if (
					isNaN( this.options.stores[storeId].fields.Latitude ) ||
					isNaN( this.options.stores[storeId].fields.Longitude )
				) {
					this.requestStoreLocation( storeId, 0, _.partial( this.getDirectionsToStore, storeId ) );
				} else {
					origin = new google.maps.LatLng( +this.distanceOrigin.lat, +this.distanceOrigin.lng );

					destination = new google.maps.LatLng(
						+this.options.stores[storeId].fields.Latitude,
						+this.options.stores[storeId].fields.Longitude
					);

					this.getDirections.calculate( origin, destination, this.map, _.bind(
							function ( destination ) {
								this.centerMap.byLocationObject( destination, this.options.markerClustererMaxZoom + 1 );
							},
							this,
							destination
						)
					);

					this.requestedDirectionsToStore = storeId;
				}
			} else {
				this.requestedDirectionsToStore = null;

				$( document ).trigger( 'zg-error', [{
					eventType: 'zg.StoreLocator.getDirectionsToStore - Failed',
					message:   window.JS_TRANSLATIONS.genericErrorMsg
				}] );
			}
		} else {
			this.requestedDirectionsToStore = null;

			if ( this.getDirections ) {
				// There is no distance origin. Destroy current distances
				this.getDirections.clear();
			}
		}
	};


	/**
	 *
	 * @param {!string} storeId
	 */
	StoreLocator.prototype.openInfoWindow = function ( storeId ) {
		var content,
			position,
			zoom = this.options.markerClustererMaxZoom + 1;

		if ( this.map && storeId ) {
			if ( this.mapMarkers[storeId] ) {

				// initialize the infoWindow just once
				if ( !this.infoWindow ) {
					this.infoWindow = new google.maps.InfoWindow();
				}

				this.infoWindow.close();

				// zoom in on the marker ( and explode the clusters )
				if ( this.map.getZoom() < zoom ) {
					this.map.setZoom( zoom );
				}

				// create infoWindow content using a template
				content = handlebarsTemplates.render(
					this.options.markerTemplate,
					this.options.stores[storeId]
				);
				this.infoWindow.setContent( content );

				// center the map in the marker
				position = this.mapMarkers[storeId].getPosition();
				this.centerMap.byLocationObject( position );

				// Use the marker position to set the infoWindow position.
				// This will be use as default position if for any reason the marker is not assigned to the map.
				this.infoWindow.setPosition( position );

				// force to redraw the (exploded) cluster.
				// this will assign the markers directly to the map.
				this.setMapMarker.redraw();

				// open the info window
				if ( this.mapMarkers[storeId].getMap() ) {
					// if the marker is assigned to the map the window will be linked to the map
					this.infoWindow.open( this.map, this.mapMarkers[storeId] );
				} else {
					// otherwise just open the window in the map
					this.infoWindow.open( this.map );
				}

			} else {

				// request the store location, create the marker and open the info window
				this.requestStoreLocation( storeId, 0, _.partial( this.openInfoWindow, storeId ) );

			}
		} else {
			$( document ).trigger(
				'zg-warning',
				{
					eventType: 'StoreLocator.openInfoWindow',
					message:   JS_TRANSLATIONS['storeLocator.noStoreLocation']
				}
			);
		}
	};


	/**
	 * Make sure the stores information is valid
	 *
	 */
	StoreLocator.prototype.parseStores = function () {
		var temp;

		if ( !this.options.stores && window.ZG_SL_STORES ) {
			this.options.stores = ZG_SL_STORES;
		}

		if ( _.isString( this.options.stores ) ) {
			this.options.stores = JSON.parse( this.options.stores );
		}

		if ( _.isObject( this.options.stores ) && this.options.stores.fields ) {
			// view store page ( single shop ). rebuild the object in the same structure as the list stores page
			// this shouldn't be necessary if the object is configured properly in the HTML
			temp = {};

			temp[this.options.stores.id] = this.options.stores;

			this.options.stores = temp;
		}

		if ( _.isObject( this.options.stores ) && !_.isEmpty( this.options.stores ) ) {

			if ( DEBUG ) {
				console.log( "StoreLocator - STORES:", this.options.stores );
			}

		} else {
			this.options.stores = null;

			if ( DEBUG ) {
				console.log( "StoreLocator - No Store information provided" );
			}
		}
	};


	/**
	 * The store location is not stored in the CMS.
	 * We request the location based on the address and create the marker based on that position
	 *
	 * @param {!string}   storeId
	 * @param {?number=}  timer
	 * @param {Function=} callback
	 */
	StoreLocator.prototype.requestStoreLocation = function ( storeId, timer, callback ) {
		var delay, that;

		if ( storeId && isGoogleMapsAvailable() ) {
			delay = ( isNaN( timer ) ? 0 : timer );
			that  = this;

			if ( !this.geolocationTimers ) {
				this.geolocationTimers = {};
			}

			// if the the geolocation had already been requested
			if ( this.geolocationTimers[storeId] ) {
				clearTimeout( this.geolocationTimers[storeId] );
			}

			this.geolocationTimers[storeId] = setTimeout(
				function () {
					that.updateStoreLocation( storeId, callback );
				},
				delay
			);
		}
	};


	/**
	 *
	 * @param {!string}   storeId
	 * @param {Function=} callback
	 */
	StoreLocator.prototype.updateStoreLocation = function ( storeId, callback ) {
		var address, that;

		if ( this.options.stores && this.options.stores[storeId] && isGoogleMapsAvailable() ) {
			address = this.getAddressFromStore( storeId );
			that    = this;

			if ( !this.geocoder ) {
				this.geocoder = new google.maps.Geocoder();
			}

			this.geocoder.geocode(
				{ 'address': address },

				function ( results, status ) {
					var info, lat, lng;

					if ( status === google.maps.GeocoderStatus.OK ) {
						lat = results[0].geometry.location.lat();
						lng = results[0].geometry.location.lng();

						that.options.stores[storeId].fields.Latitude  = lat;
						that.options.stores[storeId].fields.Longitude = lng;

						// create the marker based in the new location
						that.setMapMarker.createNew( storeId );

						// calculate new distance
						that.__setStoreDistanceFromOrigin( storeId );

						if ( callback ) {
							callback();
						}

						if ( DEBUG ) {
							console.log( "StoreLocator - updateStoreLocation", storeId, status, results[0].geometry.location );
						}

					} else if ( DEBUG ) {

						console.log( "StoreLocator - updateStoreLocation", storeId, status );

					}


					// Export geolocation info
					info = _.extend(
						{
							status: status,
							class:  (status === google.maps.GeocoderStatus.OK ? 'success' : 'danger')
						},
						that.options.stores[storeId]
					);

					if ( that.options.exportMissingGeolocation ) {
						that.exportMissingGeolocation.addStore( storeId, info );
					}
				}
			);
		}
	};


	// STORE LOCATOR PRIVATE METHODS
	// =============================


	/**
	 *
	 * @private
	 */
	StoreLocator.prototype.__init = function () {
		this.centerMap                = new ZgStoreLocatorCenterMap( this );
		this.setMapMarker             = new ZgStoreLocatorSetMapMarker( this );
		this.exportMissingGeolocation = new ZgExportMissingGeolocation( this.options );

		this.parseStores();

		this.__initMap();
		this.__initMarkers();
		this.__initUserMarker();

		// Render the filters and the stores
		this.$filtersContainer.zg_filters( {}, {}, this.options.stores );
	};


	/**
	 *
	 * @private
	 */
	StoreLocator.prototype.__initMap = function () {
		var mapOptions;

		if ( this.map ) {

			if ( DEBUG ) {
				console.log( "StoreLocator.createMap - Map was already created" );
			}

		} else if ( this.options.createMap && isGoogleMapsAvailable() && this.$mapContainer.length ) {

			// Map options defaults
			// just some sensible defaults
			mapOptions = {
				zoom:      6,
				center:    new google.maps.LatLng( 0, 0 ),
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				minZoom:   2,
				maxZoom:   25
			};

			// set map options
			if ( this.options.mapOptions && _.isObject( this.options.mapOptions ) ) {
				_.extendOwn( mapOptions, this.options.mapOptions );
			}

			// set map styles
			if ( this.options.mapStyles && _.isArray( this.options.mapStyles ) ) {
				mapOptions.styles = this.options.mapStyles;
			}

			// create the map
			this.map = new google.maps.Map( this.$mapContainer[0], mapOptions );

			// center the map
			this.centerMap.onDefaultLocation();

			this.$mapContainer.removeClass( 'loading' );

			if ( DEBUG ) {
				console.log( "StoreLocator.createMap - DONE" );
			}

		} else {

			this.$mapContainer.hide();

			if ( DEBUG ) {
				console.log( "StoreLocator.createMap - FAILED" );
			}

		}
	};


	/**
	 *
	 * @private
	 */
	StoreLocator.prototype.__initMarkers = function () {
		var requests, storeId, timer;

		// wait for a while before start spamming google
		timer    = +(this.options.timer);
		requests = 0;

		for ( storeId in this.options.stores ) {
			if ( this.options.stores.hasOwnProperty( storeId ) ) {
				if (
					!isNaN( +this.options.stores[storeId].fields.Latitude ) &&
					!isNaN( +this.options.stores[storeId].fields.Longitude )
				) {

					this.setMapMarker.createNew( storeId );

				} else {
					//
					if ( this.options.exportMissingGeolocation ) {
						this.exportMissingGeolocation.addStore( storeId, null );
					}

					//
					this.requestStoreLocation( storeId, timer );

					// The timer function is kinda weird, but there is a method to our madness.
					// Basically the more requests we do the longer we have to wait between requests.
					// Otherwise google will stop serving us geolocation data.
					timer += ( this.options.timer + ( requests * this.options.timerIncrement ));
					requests++;
				}
			}
		}
	};


	/**
	 *
	 * @private
	 */
	StoreLocator.prototype.__initUserMarker = function () {
		if ( this.map && this.options.createUserMarkerDialog && isGoogleMapsAvailable() ) {
			// init scripts
			this.setUserMarker = new ZgStoreLocatorSetUserMarker( this );
		}
	};


	/**
	 *
	 */
	StoreLocator.prototype.__setEventHandlers = function () {
		var that = this;

		// open the info window from the store list
		$( document ).on( 'click.zg.storeLocator', this.options.elementViewOnMap, function () {
			var storeId;

			storeId = '' + $( this ).data( 'store-id' );

			that.centerMap.onStore( storeId );
			that.openInfoWindow( storeId );
		} );

		// -------------------------------------------------------------------------------------------------------------

		// request a store location
		$( document ).on( 'click.zg.storeLocator', this.options.elementRequestStoreLocation, function () {
			var storeId;

			storeId = '' + $( this ).data( 'store-id' );

			that.requestStoreLocation( storeId );
		} );

		// -------------------------------------------------------------------------------------------------------------

		// get directions to a store
		$( document ).on( 'click.zg.storeLocator', this.options.elementGetDirections, function () {
			var storeId;

			storeId = '' + $( this ).data( 'store-id' );

			that.getDirectionsToStore( storeId );
		} );

		// -------------------------------------------------------------------------------------------------------------

		this.$filtersContainer.on( 'applyFilters', function ( e, appliedFilters, filteredItems, filteredKeys ) {
			that.setMapMarker.setValidMarkers( filteredKeys || [] );
			that.centerMap.byAddress( that.getAddressFromFilters( appliedFilters ) );
		} );
	};


	/**
	 *
	 * @param {Object} options
	 */
	StoreLocator.prototype.__setOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );

		this.$mapContainer     = $( this.options.elementMapContainer );
		this.$filtersContainer = $( this.options.elementFiltersContainer );
		this.$storesContainer  = $( this.options.elementStoresContainer );
	};


	/**
	 *
	 * @param {string} storeId
	 * @private
	 */
	StoreLocator.prototype.__setStoreDistanceFromOrigin = function ( storeId ) {
		var distance = zgStoreLocatorCalculateDistance(
			this.distanceOrigin,
			{
				lat: this.options.stores[storeId].fields.Latitude,
				lng: this.options.stores[storeId].fields.Longitude
			}
		);

		this.options.stores[storeId].distanceFromOrigin = distance;

		if ( _.isNumber( distance ) ) {
			if ( distance < 1 ) {
				distance = '' + ( distance * 1000 ) + ' meters';
			} else {
				distance = '' + distance + ' Km';
			}
		}

		this.options.stores[storeId].KmFromOrigin = distance;
	};


	// STORE LOCATOR PLUGIN DEFINITION
	// ===============================

	function Plugin ( option ) {
		return this.each( function () {
			var $this   = $( this );
			var data    = $this.data( 'zg.storeLocator' );
			var options = $.extend( {}, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			if ( !data ) {
				$this.data( 'zg.storeLocator', ( data = new StoreLocator( this, options ) ) );
			} else if ( option ) {
				data.__setOptions( options );
			}

			data.__init();
		} );
	}

	$.fn.StoreLocator             = Plugin;
	$.fn.StoreLocator.Constructor = StoreLocator;


	// STORE LOCATOR DATA-API
	// ======================

	$( function () {
		$( selector ).each( function () {
			Plugin.call( $( this ) );
		} );
	} );

}( jQuery ));
