/* ============================================================
 * zg-filters.js
 *
 * @author: David Pocina <dpocina[at]zerogrey[dot]com>
 *
 * ============================================================ */

(function ( $ ) {
	/* global _, DEBUG, handlebarsTemplates, JS_TRANSLATIONS, zg_sortElements, zgCreateFilterObject, zgParseString,
		zgSearchString */

	"use strict";


	// FILTERS CLASS DEFINITION
	// ========================

	/**
	 *
	 * @param {HTMLElement} element
	 * @param {!Object}     options
	 *
	 * @constructor
	 */
	var Filters = function ( element, options ) {
		this.$element = $( element );

		this.options = _.clone( Filters.DEFAULTS );
		this.updateOptions( options );

		this.filters = {};

		this.originalItems = [];
		this.items         = [];

		this.filteredItems = [];
		this.filteredKeys  = [];

		this.appliedFilters   = {};
		this.availableFilters = [];

		this.$filtersContainer = this.options.filtersContainer ? $( this.options.filtersContainer ) : this.$element;
		this.$itemsContainer   = $( this.options.itemsContainer );

		this.setEventHandlers();
	};


	Filters.DEFAULTS = {
		// create filters Object based on a list of field names from the CMS
		// The properties are case sensitive.
		createFilters: null,

		// Any property name that matches the pattern ( regular expresion ) will be used to create filters.
		// If no field name includes the pattern or the field is already set in the normal filters or in 'createFilters' this won't do anything.
		createFiltersByPattern: null,

		filtersContainer: null,

		applyFilters:   '[data-zg-role="apply-filters"]', // re-apply all filters.
		itemsContainer: '[data-zg-role="pagination"]',
		filterElement:  '[data-zg-action]',
		resetFilters:   '[data-zg-role="reset-filters"]', // remove all applied filters.

		// Show the items count for each of the filter values
		// (How many items would be available for that filter if it was selected?)
		// set as false to avoid executing the code (better performance)
		filterValueItemsCount: '[data-zg-role="items-count"]', // false,

		// Exclusive filter means that you can only select one option for that filter (selecting an option removes
		// the previous ones).
		// Can be set it as true (all filters exclusive) or pass an array with the id for the filters you want to be
		// exclusive
		exclusiveFilters: false,

		initial: {},

		// render the items using pagination after filtering.
		// Set as false if the rendering will be done by another script.
		renderItems: true,

		templateFilterList:   'filter-list',
		templateFilterReset:  'filter-reset',
		templateFilterSearch: 'filter-search',
		templateFilterSlider: 'filter-slider',

		// Class for the 'empty' filter values.
		// Set as false if you don't want the 'empty' filters to have a specific class.
		filterValueClassEmpty:  'hidden', // 'disabled', // false
		// Class for the 'active' (selected) filter values
		filterValueClassActive: 'active',

		// set to false if you don't want to create the reset element
		enableReset: true,

		enableSearch:   false,
		searchField:    '[data-zg-role="filter-search"]',
		searchInFields: null,

		sliderMinDiff: 50, // minimum difference between the min and max values to create a slider
		sliderStep:    10,

		sortFilters:   true, // true will sort alphabetically. you can set an array with the specific order instead
		sortFiltersBy: 'id', // property used to sort the filters

		splitProductsByOption: false,

		updateUri:   false,
		isFirstLoad: true // don't update url at first load
	};


	/**
	 *
	 * @param {string}    action
	 * @param {string}    filter
	 * @param {string}    value
	 * @param {?boolean=} overwrite
	 */
	Filters.prototype.addFilter = function ( action, filter, value, overwrite ) {
		var index;

		if ( action && filter ) {

			this.options.isFirstLoad = false;

			if ( action === 'filter' && value ) {
				if ( !this.appliedFilters[filter] || _.isEmpty( this.appliedFilters[filter] ) || overwrite ) {

					// there is no option selected for that filter yet
					this.appliedFilters[filter] = [value];

				} else {

					index = _.indexOf( this.appliedFilters[filter], value );

					if ( index === -1 ) { // the value is not selected
						if ( this.isExclusiveFilter( filter ) ) {
							// The filter is exclusive: Selecting an option removes the previous ones
							this.appliedFilters[filter] = [value];
						} else {
							// normal filter: add the value
							this.appliedFilters[filter].push( value );
						}
					} else {
						// the value is currently selected. Remove it
						this.appliedFilters[filter].splice( index, 1 );
					}
				}

				if ( _.isEmpty( this.appliedFilters[filter] ) ) {
					// remove the filter if it has no values.
					delete this.appliedFilters[filter];
				} else {
					// make sure the filters values are consistent
					this.appliedFilters[filter] = _.uniq( this.appliedFilters[filter].sort(), true );
				}

			} else if ( action === 'reset' ) {

				// reset that filter
				delete this.appliedFilters[filter];

			}

			// do the actual filtering
			this.applyFilters();
		}
	};


	/**
	 *
	 * @param {boolean=} stopUriUpdate - true if the url shouldn't be updated regardless of the script options
	 */
	Filters.prototype.applyFilters = function ( stopUriUpdate ) {
		var filteredItems = this.processFilters() || {};

		this.filteredItems = filteredItems.items || [];
		this.filteredKeys  = filteredItems.keys || [];

		// update the filters interface
		this.updateInterface();

		// update the url
		if ( !stopUriUpdate ) {
			this.__updateURL();
		}

		// render the products
		this.renderItems();

		this.$element.trigger( 'applyFilters', [this.appliedFilters, this.filteredItems, this.filteredKeys] );
		$( document ).trigger( 'applyFilters', [this.$element, this.appliedFilters, this.filteredItems, this.filteredKeys] );
	};


	/**
	 * check if the item price is between the min and max values
	 *
	 * @param {Object}  item
	 * @param {Object=} appliedFilters
	 *
	 * @returns {boolean}
	 */
	Filters.prototype.checkItemsPrice = function ( item, appliedFilters ) {
		var filters = appliedFilters || this.appliedFilters,
			valid   = true;

		if (
			filters['price-max'] &&
			_.isNumber( filters['price-max'][0] ) &&
			item.price.sell > filters['price-max'][0]
		) { // product price is higher than the maximum
			valid = false;
		}

		if (
			valid &&
			filters['price-min'] &&
			_.isNumber( filters['price-min'][0] ) &&
			item.price.sell < filters['price-min'][0]
		) { // product price is lower than the minimum
			valid = false;
		}

		return valid;
	};


	/**
	 *
	 * @param {Object}  item
	 * @param {Object=} appliedFilters
	 *
	 * @returns {boolean}
	 */
	Filters.prototype.checkSearchString = function ( item, appliedFilters ) {
		var filters = appliedFilters || this.appliedFilters,
			valid   = true;

		if ( filters.search ) {
			valid = zgSearchString( filters.search, item, this.options.searchInFields );
		}

		return valid;
	};


	/**
	 *
	 * @param {string}        filter
	 * @param {string|number} value
	 *
	 * @returns {number}
	 */
	Filters.prototype.countFilterValueItems = function ( filter, value ) {
		// TODO: refactor this as soon as the backend version of split products is ready

		var count, filteredProducts, i, productIds;

		count = 0;

		// what would be the result if the filter value was to be applied
		filteredProducts = this.getItemsForFilterValue( filter, value );

		productIds = this.filters[filter].values[value].products || [];

		for ( i = 0; i < filteredProducts.items.length; i++ ) {
			if ( _.contains( productIds, filteredProducts.items[i].id ) ) {
				count++;
			}
		}

		return count;
	};


	/**
	 *
	 * @param {string}  filter
	 * @param {Object=} data
	 *
	 * @returns {*}
	 */
	Filters.prototype.createFilter = function ( filter, data ) {
		var $filter;

		switch ( filter ) {
			case 'price':
				if ( (data.max - data.min) >= this.options.sliderMinDiff ) {
					$filter = this.createSlider( filter, handlebarsTemplates.render( this.options.templateFilterSlider, data ), data );
				} else if ( DEBUG ) {
					console.info( 'price filter not created: Price difference under minimum limit' );
				}
				break;

			case 'reset':
				$filter = $( handlebarsTemplates.render( this.options.templateFilterReset ) );
				break;

			case 'search':
				$filter = $( handlebarsTemplates.render( this.options.templateFilterSearch, data ) );
				break;

			default:
				$filter = $( handlebarsTemplates.render( this.options.templateFilterList, data ) );
		}

		if ( $filter ) {
			$filter.data( this.options.sortFiltersBy, filter );
		}

		return $filter;
	};


	/**
	 *
	 * @param {string}             filter
	 * @param {string|HTMLElement} item - html String created by handlebars
	 * @param {Object}             data - filter information
	 *
	 * @returns {*|HTMLElement}
	 */
	Filters.prototype.createSlider = function ( filter, item, data ) {
		var that        = this,
			minVal      = Math.floor( data.min / 10 ) * 10,
			maxVal      = Math.ceil( data.max / 10 ) * 10,
			initial     = [
				that.appliedFilters[filter + "-min"] && _.isNumber( that.appliedFilters[filter + "-min"][0] ) ?
					that.appliedFilters[filter + "-min"][0] : minVal,
				that.appliedFilters[filter + "-max"] && _.isNumber( that.appliedFilters[filter + "-max"][0] ) ?
					that.appliedFilters[filter + "-max"][0] : maxVal
			],
			$item       = $( item ),
			$filterInfo = $( ".slider-value", $item ),
			$slider     = $( ".price-slider", $item );

		function sliderHandler ( values ) {
			that.appliedFilters[filter + "-min"] = ( values[0] > minVal ? [+values[0]] : null );
			that.appliedFilters[filter + "-max"] = ( values[1] < maxVal ? [+values[1]] : null );

			that.applyFilters();
		}

		function sliderText ( values ) {
			$filterInfo.html(
				JS_TRANSLATIONS.currency + " " + values[0] +
				" &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp; " +
				JS_TRANSLATIONS.currency + " " + values[1]
			);
		}

		$slider.slider( {
			"range":  true,
			"min":    minVal,
			"max":    maxVal,
			"step":   that.options.sliderStep,
			"values": initial,

			"slide": function ( event, ui ) {
				sliderText( ui.values );
				// slider_handler( ui.values );
			},

			"change": function ( event, ui ) {
				sliderText( ui.values );
				sliderHandler( ui.values );
			}
		} );

		sliderText( initial );

		return $item;
	};


	/**
	 *
	 * @param {string}          filter
	 * @param {?string|number=} value
	 *
	 * @returns {{items: Array, keys: Array}}
	 */
	Filters.prototype.getItemsForFilterValue = function ( filter, value ) {
		var appliedFilters = _.clone( this.appliedFilters );

		if ( value ) {
			if ( !appliedFilters[filter] || this.isExclusiveFilter( filter ) ) {
				appliedFilters[filter] = [value];
			} else {
				appliedFilters[filter] = _.union( appliedFilters[filter], [value] );
				_.uniq( appliedFilters[filter].sort(), true );
			}
		} else {
			delete( appliedFilters[filter] );
		}

		// what would be the result if the filter was to be applied
		return this.processFilters( appliedFilters );
	};


	/**
	 *
	 * @param {Array}  ids
	 * @param {string} value
	 *
	 * @returns {Array}
	 */
	Filters.prototype.getSplitProductIds = function ( ids, value ) {
		// TODO: remove this as soon as the backend version of split products is ready
		return _.map(
				ids,
				function ( item ) {
					return '' + item + '-' + value;
				}
			) || [];
	};


	/**
	 *
	 * @param {string}        filter
	 * @param {string|number} value
	 *
	 * @returns {boolean}
	 */
	Filters.prototype.isEmptyFilterValue = function ( filter, value ) {
		var isEmpty, filteredProducts, productIds, i;

		if ( this.options.filterValueClassEmpty ) {
			// TODO: refactor this as soon as the backend version of split products is ready

			isEmpty = true;

			filteredProducts = this.getItemsForFilterValue( filter, value );

			productIds = this.filters[filter].values[value].products || [];

			for ( i = 0; i < filteredProducts.items.length && isEmpty; i++ ) {
				isEmpty = !_.contains( productIds, filteredProducts.items[i].id );
			}

		} else {
			isEmpty = false;
		}

		return isEmpty;
	};


	/**
	 * Returns true is the current filter is exclusive (selecting one option un-selects the others)
	 *
	 * @param {string} filter
	 *
	 * @returns {boolean}
	 */
	Filters.prototype.isExclusiveFilter = function ( filter ) {
		var exclusive = false;

		if (
			this.options.exclusiveFilters === true ||
			( _.isArray( this.options.exclusiveFilters ) && _.contains( this.options.exclusiveFilters, filter ) )
		) {
			// The current filter is exclusive (or all of them are).
			exclusive = true;
		}

		return exclusive;
	};


	/**
	 *
	 * @param {string} filter
	 *
	 * @returns {boolean}
	 */
	Filters.prototype.isValidFilter = function ( filter ) {
		var valid = false;

		if ( filter ) {
			if ( this.filters.hasOwnProperty( filter ) || filter === "price-min" || filter === "price-max" ) {
				valid = true;
			}
		}

		return valid;
	};


	/**
	 *
	 * @param {Array=} appliedFilters
	 *
	 * @returns {{items: Array, keys: Array}}
	 */
	Filters.prototype.processFilters = function ( appliedFilters ) {
		var filter,
			filtered,
			hasSplitFilters = false,
			i,
			isFilterApplied = false,
			productIds      = null,
			splitProductIds = [],
			temp,
			that            = this,
			value;

		var result = {
			items: [],
			keys:  []
		};

		if ( !appliedFilters ) {
			appliedFilters = this.appliedFilters;
		}

		// filters
		for ( filter in appliedFilters ) {
			if ( appliedFilters.hasOwnProperty( filter ) && this.filters.hasOwnProperty( filter ) ) {
				filtered = false; // should the current filter be applied?
				temp = [];

				if ( _.isArray( appliedFilters[filter] ) && appliedFilters[filter].length ) {

					for ( i = 0; i < appliedFilters[filter].length; i++ ) {
						value = appliedFilters[filter][i];

						if ( this.filters[filter].values[value] ) {
							temp     = temp.concat( this.filters[filter].values[value].products || [] );
							filtered = true; // yes it should  :)
						}

						// TODO: remove this as soon as the backend version of split products is ready
						if (
							this.options.splitProductsByOption &&
							filter === "opt_" + this.options.splitProductsByOption
						) {
							hasSplitFilters = true;
							splitProductIds = splitProductIds.concat( this.getSplitProductIds( this.filters[filter].values[value].products ||
								[], value ) );
						}
					}

					if ( filtered ) {
						isFilterApplied = true; // The collection has normal filters applied

						if ( productIds === null ) {
							productIds = _.uniq( temp.sort(), true );
						} else {
							productIds = _.uniq( _.intersection( productIds, temp ).sort(), true );
						}
					}

				} else {

					// remove unnecessary properties from the appliedFilters object.
					delete( appliedFilters[filter] );

				}
			}
		}

		_.each( this.items, function ( item, key ) {
			var id = item.id || key;

			if (
				( !isFilterApplied || _.contains( productIds, id ) ) &&
				(
					// TODO: remove this as soon as the backend version of split products is ready
					!item.isSplit || !hasSplitFilters || _.intersection( item.splitIds, splitProductIds ).length > 0
				) &&
				that.checkSearchString( item, appliedFilters ) &&
				that.checkItemsPrice( item, appliedFilters )
			) {
				// No filters (Get all the items) or the items have normal filters applied (Get only the selected items)
				// and the item has not been split or is split and selected
				// and the item is between the minimum and maximum price

				result.items.push( item );
				result.keys.push( item.splitId || id );
			}
		} );

		return result;
	};


	/**
	 *
	 */
	Filters.prototype.renderFilters = function () {
		var $item,
			filters = [],
			filter;


		for ( filter in this.filters ) {
			if ( this.filters.hasOwnProperty( filter ) ) {

				this.filters[filter].id = filter;

				$item = this.createFilter( filter, this.filters[filter] );
				if ( $item ) {
					$.merge( filters, $item );
				} else {
					console.warn( 'Invalid filter', filter );
				}

			}
		}

		// sort options
		if ( this.options.sortFilters ) {
			filters.sort( zg_sortElements( {
				attr:              this.options.sortFiltersBy,
				pattern:           _.isArray( this.options.sortFilters ) ? this.options.sortFilters : null,
				avoidNumbersOnTop: true
			} ) );
		}

		// search on top
		if ( this.options.enableSearch ) {
			filters = $.merge( this.createFilter( 'search', this.appliedFilters.search ), filters );
		}

		// reset on bottom
		if ( this.options.enableReset ) {
			$.merge( filters, this.createFilter( 'reset' ) );
		}

		this.$filtersContainer.html( filters );
	};


	/**
	 *
	 * @param {Array=} items
	 */
	Filters.prototype.renderItems = function ( items ) {
		var collection;

		if ( this.options.renderItems ) {
			collection = items || this.filteredItems;

			this.$itemsContainer.zg_pagination( this.options, collection );
		}
	};


	/**
	 * This one should be kinda self explanatory...  ;)
	 */
	Filters.prototype.resetFilters = function () {
		this.appliedFilters = {};

		// empty search field
		$( this.options.searchField ).val("");

		this.applyFilters();
	};


	/**
	 *
	 * @param {Object} filters
	 */
	Filters.prototype.setAppliedFilters = function ( filters ) {
		var filter,
			values;

		function mapValues ( value ) {
			if ( !isNaN( value ) ) {
				value = +(value);
			}

			return value;
		}

		this.appliedFilters = {};

		for ( filter in filters ) {
			if (
				filters.hasOwnProperty( filter ) &&
				_.contains( this.availableFilters, filter )
			) {
				values = [];

				if ( _.isArray( filters[filter] ) ) {
					values = filters[filter];
				} else if ( _.isString( filters[filter] ) ) {
					values = filters[filter].split( ',' );
				} else if ( _.isNumber( filters[filter] ) ) {
					values = [filters[filter]];
				}

				// store numbers as numbers
				values = _.map( values, mapValues );

				this.appliedFilters[filter] = values;
			}
		}
	};


	/**
	 *
	 */
	Filters.prototype.setAvailableFilters = function () {
		var filter;

		this.availableFilters = [];

		for ( filter in this.filters ) {
			if ( this.filters.hasOwnProperty( filter ) ) {
				if ( filter === 'price' ) {
					this.availableFilters.push( 'price-max' );
					this.availableFilters.push( 'price-min' );
				} else {
					this.availableFilters.push( filter );
				}
			}
		}

		this.availableFilters.push( 'search' );

		// this will reset the pagination when a new filter is selected.
		this.availableFilters.push( 'page' );

		this.availableFilters.sort();
	};


	/**
	 *
	 */
	Filters.prototype.setEventHandlers = function () {
		var that = this;

		// -------------------------------------------------------------------------

		this.$filtersContainer.on( 'click.zg.filters.applyFilter', this.options.filterElement, function ( e ) {
			var $this, data;

			$this = $( this );

			if ( !$this.is( 'select' ) && !$this.is( 'option' ) && !$this.is( 'input' ) ) {
				e.preventDefault();

				data = $this.data();

				that.addFilter( data.zgAction, data.filter, data.value );
			}
		} );

		this.$filtersContainer.on( 'change.zg.filters.applyFilter', this.options.filterElement, function ( e ) {
			var $this, action, filter, value;

			e.preventDefault();

			$this  = $( this );
			value  = $this.val();
			action = value ? $this.data( 'zg-action' ) : 'reset';
			filter = $this.data( 'filter' );

			that.addFilter( action, filter, value, true );
		} );

		// -------------------------------------------------------------------------

		$( document ).on( 'change.zg.filters.applyFilter', this.options.searchField, function ( e ) {
			var value;

			e.preventDefault();
			clearTimeout( that.searchTimer );

			value = zgParseString( $( this ).val(), true );

			if ( value !== (that.appliedFilters.search || [] )[0] ) {
				if ( value ) {
					that.appliedFilters.search = [value];
				} else {
					delete that.appliedFilters.search;
				}

				that.applyFilters();
			}

		} );

		// limit the number of times per second the search filter is triggered
		$( document ).on( 'input.zg.filters keydown.zg.filters', this.options.searchField, function () {
			var $this        = $( this );

			clearTimeout( that.searchTimer );
			that.searchTimer = setTimeout(
				function () {
					$this.change();
				},
				200
			);
		} );

		// -------------------------------------------------------------------------

		$( document ).on( 'click.zg.filters.applyFilter', this.options.applyFilters, function ( e ) {
			e.preventDefault();
			that.applyFilters();
		} );

		$( document ).on( 'click.zg.filters.resetFilters', this.options.resetFilters, function ( e ) {
			e.preventDefault();
			that.resetFilters();
		} );

		// -------------------------------------------------------------------------

		$( document ).on( "zg.urimgr.updatedUri", function ( e, info ) {
			var applyFilters = false,
				components,
				i,
				filter;

			that.options.isFirstLoad = false;

			components = info.components || {};

			for ( i = 0; i < that.availableFilters.length && !applyFilters; i++ ) {
				filter = that.availableFilters[i];

				if ( that.isValidFilter( filter ) && !_.isEqual( components[filter], that.appliedFilters[filter] ) ) {
					applyFilters = true;
				}
			}

			if ( applyFilters ) {
				that.setAppliedFilters( components );
				that.applyFilters( true );
			}
		} );
	};


	/**
	 *
	 */
	Filters.prototype.setInitialFilters = function () {
		var appliedFilters = $.extend(
			{},
			this.options.initial || {},
			this.appliedFilters || {}
		);

		this.setAppliedFilters( appliedFilters );

		// reset initial filters
		this.options.initial = {};
	};


	/**
	 *
	 */
	Filters.prototype.setItems = function () {
		var i, item, j, productIds, value;

		this.items = [];

		if ( this.originalItems ) {

			// This code is even more painful than it looks.
			// It was requested by havaianas and backend didn't have time to do it  :(
			// Should be eventually removed and everything will be right with the world again ;)
			// TODO: remove this as soon as the backend version of split products is ready
			if ( this.options.splitProductsByOption ) {
				// split the products by an option id.

				productIds = [];

				for ( i = 0; i < this.originalItems.length; i++ ) {

					productIds.push( "" + this.originalItems[i].id );

					if (
						this.originalItems[i].options &&
						this.originalItems[i].options[this.options.splitProductsByOption] &&
						this.originalItems[i].options[this.options.splitProductsByOption].values
					) {

						for ( value in this.originalItems[i].options[this.options.splitProductsByOption].values ) {
							if ( this.originalItems[i].options[this.options.splitProductsByOption].values.hasOwnProperty( value ) ) {
								item = $.extend( {}, this.originalItems[i] );

								item.isSplit        = true;
								item.splitOption    = this.options.splitProductsByOption;
								item.splitName      = item.options[item.splitOption].values[value].name;
								item.splitValue     = value;
								item.splitValueMain = item.options[item.splitOption].values[value].main_options;

								// Add a property to sort the items by
								item.sortBy = item.splitValueMain && item.splitValueMain[0] ?
									+(item.splitValueMain[0]) :
									+(item.splitValue);

								// set split id(s) for the product
								item.splitId = '' + item.id + '-' + value;

								item.splitIds = [item.splitId];
								if ( item.splitValueMain ) {
									for ( j = 0; j < item.splitValueMain.length; j++ ) {
										item.splitIds.push( '' + item.id + '-' + item.splitValueMain[j] );
									}
								}


								// set the current option as selected
								item.options[item.splitOption].selectedValue = value;
								if ( !item.default_options ) {
									item.default_options = [];
								}
								item.default_options.push( value );

								// if its an color option update the main product images
								if ( item.options[item.splitOption].has_image ) {
									if ( item.options[item.splitOption].values[value].images ) {
										item.images = item.options[item.splitOption].values[value].images;
									}
									if ( item.options[item.splitOption].values[value].processedImages ) {
										item.processedImages = item.options[item.splitOption].values[value].processedImages;
									}
								}

								// set options selected by default
								if ( !item.selectedOptions ) {
									item.selectedOptions = [];
								}
								item.selectedOptions.push( value );

								// modify the product url to land in the page with the options already selected
								item.url = $.uriMgr( {
									action:    'getUrl',
									url:       item.url,
									applied:   { 'options': item.selectedOptions },
									available: ['options']
								} );

								this.items.push( item );
							}
						}

					} else {

						this.items.push( this.originalItems[i] );

					}
				}

				// reorder the items so they have the default order from backend
				this.items.sort( zg_sortElements(
					{ attr: 'id', pattern: productIds },
					{ attr: 'splitValue' }
				) );

			} else {

				this.items = this.originalItems;

			}
		}
	};


	/**
	 *
	 * @param {Object=} filters
	 * @param {Array=}  items
	 * @param {string=} url
	 */
	Filters.prototype.updateFilters = function ( filters, items, url ) {
		if ( items ) {
			this.originalItems = items;

			this.setItems();
		}

		if ( filters ) {
			this.filters = filters;

			this.__updateFilterObject();

			this.setAvailableFilters();
			this.setInitialFilters();
			this.renderFilters();
		}

		this.applyFilters( true );

		if ( url ) {
			this.__updateURL( url, true );
		}
	};


	/**
	 *
	 * @param {string} filter
	 */
	Filters.prototype.updateFilterResetInterface = function ( filter ) {
		var $filter, count;

		// reset filter element ( 'all' )
		$filter = this.$filtersContainer.find(
			'[data-filter="' + filter + '"]' +
			'[data-zg-action="reset"]'
		);

		// no values are selected for the filter
		if ( !this.filters[filter].isActive ) {
			$filter.addClass( this.options.filterValueClassActive );
		}

		// Add the count for the 'reset' element
		if ( this.options.filterValueItemsCount ) {
			count = this.getItemsForFilterValue( filter, null ).keys.length;
			$filter.find( this.options.filterValueItemsCount ).text( '(' + count + ')' );
		}

	};


	/**
	 *
	 * @param {string} filter
	 */
	Filters.prototype.updateFilterValueInterface = function ( filter ) {
		var $filter, $filterContainer, count, value;

		this.filters[filter].isActive  = false;
		this.filters[filter].isVisible = false;

		$filterContainer = $( '#filter_' + filter );

		// set values for the selectBox version
		this.$filtersContainer
			.find( 'select[data-filter="' + filter + '"]' )
			.val( this.appliedFilters[filter] );

		// set values for the link version
		for ( value in this.filters[filter].values ) {
			if ( this.filters[filter].values.hasOwnProperty( value ) ) {
				if ( !isNaN( value ) ) {
					value = +value;
				}

				$filter = this.$filtersContainer.find(
					// 'click' version
					'[data-filter="' + filter + '"]' +
					'[data-value="' + value + '"]' +
					',' +
						// selectBox version
					'[data-filter="' + filter + '"] ' +
					'option[value="' + value + '"]'
				);

				this.filters[filter].values[value].isEmpty = false;

				if ( this.options.filterValueItemsCount ) {

					count = this.countFilterValueItems( filter, value );
					$filter.find( this.options.filterValueItemsCount ).text( '(' + count + ')' );

					// is the filter value empty ( it wouldn't select any item ) ?
					this.filters[filter].values[value].isEmpty = ( count === 0 );

				} else if ( this.options.filterValueClassEmpty ) {

					// We still have to check if the value is empty, but we don't need to show the numbers.
					// We use a better performing function
					this.filters[filter].values[value].isEmpty = this.isEmptyFilterValue( filter, value );

				}

				if ( this.appliedFilters[filter] && _.contains( this.appliedFilters[filter], value ) ) {

					// one of the values is selected for the filter
					this.filters[filter].isActive = true;
					// and obviously it means that there is a visible element
					this.filters[filter].isVisible = true;

					// set the value element as active
					$filter.addClass( this.options.filterValueClassActive );

				} else if ( this.options.filterValueClassEmpty && this.filters[filter].values[value].isEmpty ) {

					// set the empty class in the 'else' block, so we don't hide/disable selected filter values
					$filter
						.addClass( this.options.filterValueClassEmpty )
						.prop( 'disabled', true )
						.filter( 'option' )
						.hide();

				} else {

					this.filters[filter].isVisible = true;

				}
			}
		}

		// if no values are visible for the filter, hide the whole group
		if ( this.filters[filter].isVisible ) {
			$filterContainer.show();
		} else {
			$filterContainer.hide();
		}
	};


	/**
	 * Update the filters interface, setting the current filters as active
	 *
	 */
	Filters.prototype.updateInterface = function () {
		var $filters, filter;

		$filters = this.$filtersContainer.find( this.options.filterElement );

		$filters
			.removeClass( this.options.filterValueClassEmpty || '' )
			.removeClass( this.options.filterValueClassActive || '' )
			.prop('disabled', false)
			.filter('select') // selectBox version
				.val( '' )
				.find( 'option' )
					.removeClass( this.options.filterValueClassEmpty || '' )
					.removeClass( this.options.filterValueClassActive || '' )
					.prop('disabled', false)
					.show();

		for ( filter in this.filters ) {
			if ( this.filters.hasOwnProperty( filter ) && filter !== 'price' ) {
				this.updateFilterValueInterface( filter );
				this.updateFilterResetInterface( filter );
			}
		}
	};


	/**
	 * Overwrite the current options with new values
	 *
	 * @param {Object} options
	 */
	Filters.prototype.updateOptions = function ( options ) {
		_.extendOwn( this.options, options || {} );

		if ( _.isString( this.options.sortFilters ) ) {
			this.options.sortFilters = this.options.sortFilters.split( ',' );
		}

		if ( _.isString( this.options.searchInFields ) ) {
			this.options.searchInFields = this.options.searchInFields.split( ',' );
		}
	};


	/**
	 * Extend the original filters
	 *
	 * @private
	 */
	Filters.prototype.__updateFilterObject = function () {
		this.filters = zgCreateFilterObject( this.items, this.options.createFilters, this.options.createFiltersByPattern, this.filters );
	};


	/**
	 *
	 * @param {string=}  url
	 * @param {boolean=} replace
	 * @private
	 */
	Filters.prototype.__updateURL = function ( url, replace ) {
		var request;

		if ( this.options.updateUri && !this.options.isFirstLoad ) {
			request = {
				applied:   this.appliedFilters,
				available: this.availableFilters,
				data:      { categoryId: +(this.options.categoryId) }
			};

			if ( url ) {
				request.url = url;
			}

			if ( replace ) {
				request.action = 'replace';
			}

			$.uriMgr( request );
		}

		// Not the first load anymore
		this.options.isFirstLoad = false;
	};


	// FILTERS PLUGIN DEFINITION
	// =========================

	/**
	 *
	 * @param option
	 * @param filters
	 * @param items
	 * @param url
	 *
	 * @returns {*}
	 */
	function Plugin ( option, filters, items, url ) {
		return this.each( function () {
			var $this   = $( this ),
				data    = $this.data( 'zg.filters' ),
				options = _.extend( {}, window.ZG_CONFIG || {}, $this.data(), typeof option === 'object' && option );

			filters = filters || options.filters || null;
			items   = items || options.items || null;

			if ( !data ) {
				$this.data( 'zg.filters', (data = new Filters( this, options )) );
			} else if ( typeof option === 'object' ) {
				data.updateOptions( options );
			}

			data.updateFilters( filters, items, url );
		} );
	}

	$.fn.zg_filters             = Plugin;
	$.fn.zg_filters.Constructor = Filters;

	// FILTERS DATA-API
	// ================

	// $(function () {
	// 	$('[data-zg-role="filters"]').each( function () {
	// 		Plugin.call( $(this) );
	// 	});
	// });

}( jQuery ));
